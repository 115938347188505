import { memo, useEffect, useState, CSSProperties } from 'react';
import Select from 'react-select';
import type {
  StylesConfig,
  GroupBase,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  ValueContainerProps,
} from 'react-select';
import styled from 'styled-components';
import { css } from '@styled-system/css';
import { colors } from 'theme/colors';
import { Label } from './form/Label';
import { useTranslation } from 'react-i18next';
import Tooltip from './tooltip/Tooltip';

export interface OptionType {
  value: string;
  label: string;
}
interface CustomStylesSelectProps extends CSSObjectWithLabel {
  multiValueWidth?: string;
  backgroundColorMultiValue?: string;
}

interface SelectorProps {
  options: OptionType[];
  onChange: (value: string[]) => void;
  isMulti?: boolean;
  label?: string;
  initialValue?: string[];
  style?: CSSProperties;
  customStylesSelect?: (
    base: CSSObjectWithLabel,
    props:
      | DropdownIndicatorProps<OptionType, boolean, GroupBase<OptionType>>
      | ValueContainerProps<OptionType, boolean, GroupBase<OptionType>>,
  ) => CustomStylesSelectProps;
  selectedValue?: OptionType[] | null;
  setSelectedValue?: (value: OptionType[] | null) => void;
}

export const SelectorStyledCustom = {
  marginTop: '0',
  padding: '0.5rem',
  alignItems: 'center',
  svg: {
    height: '1.3rem',
    width: '1.3rem',
  },
};

const SSelector = styled.div.attrs<SelectorProps>(({ style }) => ({
  style: style || {},
}))`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 25rem;
  position: relative;
  ${css({
    color: 'base.600',
    borderRight: '1px solid lightGrey',
    borderColor: 'base.400',
  })}
  @media (max-width: 600px) {
    padding: 0.7rem 1.5rem 0 0;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
  }
`;

const SLabel = styled(Label)`
  @media (min-width: 601px) {
    top: 0.25rem;
    left: 1.5rem;
    position: absolute;
  }
  @media (max-width: 600px) {
    margin-right: auto;
    padding-left: 1.5rem;
    align-self: center;
  }
  ${css({
    fontSize: '0.75rem',
    color: 'base.600',
    fontWeight: 600,
    textTransform: 'uppercase',
  })}
`;

const customStyles: StylesConfig<OptionType> = {
  control: (provided) => ({
    ...provided,
    borderRadius: 0,
    fontSize: '1.125rem',
    fontWeight: 400,
    background: 'transparent',
    border: 'none',
    color: colors.base[900],
    height: '100%',
    cursor: 'pointer',
  }),
  container: (provided) => ({
    ...provided,
    height: '100%',
    width: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    width: '11.25rem',
    borderRadius: 0,
    padding: 0,
    marginTop: '1px',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? colors.base[400] : '',
    color: colors.base[900],
    borderBottom: '1px solid',
    borderColor: colors.base[400],
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.base[200],
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    display: 'block',
    backgroundColor: 'transparent',
    color: 'white',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'white',
    },
  }),
};

const Selector = ({
  options,
  onChange,
  isMulti = false,
  label,
  initialValue,
  style,
  customStylesSelect,
  selectedValue: externalSelectedValue,
  setSelectedValue: externalSetSelectedValue,
}: SelectorProps): JSX.Element => {
  const { t } = useTranslation();

  const [internalSelectedValue, setInternalSelectedValue] = useState<OptionType[] | null>(null);
  const selectedValue = externalSelectedValue !== undefined ? externalSelectedValue : internalSelectedValue;
  const setSelectedValue = externalSetSelectedValue !== undefined ? externalSetSelectedValue : setInternalSelectedValue;

  useEffect(() => {
    if (!externalSetSelectedValue) {
      if (initialValue && !selectedValue) {
        const initialOptions = options.filter((option) => initialValue.includes(option.value));
        setSelectedValue(initialOptions);
      } else {
        const validPrevOptions = selectedValue?.filter((option) => options.some((o) => o.value === option.value)) || [];
        setSelectedValue(validPrevOptions.length > 0 ? validPrevOptions : null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, options]);

  const handleChange = (selectedOptions: unknown) => {
    const options = selectedOptions as OptionType[] | null;

    if (isMulti && options && options.length === 0 && selectedValue && selectedValue.length === 1) {
      return;
    }

    setSelectedValue(options);
    onChange(options ? options.map((option) => option.value) : []);
  };

  const mergedStyles: StylesConfig<OptionType> = {
    ...customStyles,
    valueContainer: (provided, state) => ({
      ...provided,
      display: 'flex',
      color: colors.base[900],
      flexWrap: customStylesSelect?.(provided, state).flexWrap ?? 'nowrap',
      overflow: 'auto',
      padding: customStylesSelect?.(provided, state).padding ?? '0 0 0.25rem 1.5rem',
      height: '100%',
      alignItems: customStylesSelect?.(provided, state).alignItems ?? 'end',
      caretColor: 'transparent',
      rowGap: customStylesSelect?.(provided, state).rowGap ?? '4px',
      columnGap: customStylesSelect?.(provided, state).columnGap ?? '4px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 20,
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      width: customStylesSelect?.(provided, state).multiValueWidth ?? '4rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      svg: customStylesSelect?.(provided, state).svg ?? {
        height: '0.9rem',
        width: '0.9rem',
      },
      '&:hover': {
        color: colors.base[600],
      },
      marginRight: '0.3rem',
      marginTop: customStylesSelect?.(provided, state).marginTop ?? 'auto',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
      color: colors.base[600],
    }),
    multiValue: (provided, state) => ({
      ...provided,
      height: '22px',
      margin: 0,
      fontSize: '0.82rem',
      justifyContent: 'center',
      borderRadius: 0,
      backgroundColor: customStylesSelect?.(provided, state).backgroundColorMultiValue ?? colors.brand,
      color: 'white',
      '& > div:first-of-type': {
        color: 'white',
        padding: '3px',
        paddingRight: '4px',
        paddingLeft: '6px',
      },
    }),
  };

  return (
    <SSelector style={style}>
      <SLabel>{label}</SLabel>
      <Select
        options={options}
        formatOptionLabel={({ value, label }) => (
          <div data-tooltip-id={`option-${value}`}>
            {label}
            <Tooltip id={`option-${value}`} place="bottom" content={label} />
          </div>
        )}
        noOptionsMessage={() => t('No options')}
        styles={mergedStyles}
        value={selectedValue}
        onChange={handleChange}
        isMulti={isMulti}
        isClearable={false}
        closeMenuOnSelect={false}
      />
    </SSelector>
  );
};

export default memo(Selector);
