import { View } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_VIEW_NAME_KEY = 'editViewName';

const EDIT_VIEW_NAME = `
  mutation ($viewId: String!, $name: String!) {
    ${EDIT_VIEW_NAME_KEY}(uuid: $viewId, data: { item: $name }) {
      id
      name
      updatedAt
    }
  }
`;

type MutVarEditViewName = {
  viewId: string;
  name: string;
};

type MutResEditViewName = Pick<View, 'id' | 'name' | 'updatedAt'>;

export const useEditViewName = () => {
  return useMutation<DefaultQueryResponse<typeof EDIT_VIEW_NAME_KEY, MutResEditViewName>, MutVarEditViewName>(
    EDIT_VIEW_NAME,
  );
};
