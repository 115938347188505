import { MdClose } from 'react-icons/md';
import { Flex } from 'components/layout/Flex';
import { Label } from './Label';
import { useTranslation } from 'react-i18next';
import { ProjectSensor, Sensor, ProjectSensorWithRule } from 'graphql/generated';
import { SharedSelect, SharedSelectProps } from './SharedSelect';
import { colors } from 'theme/colors';
import { components } from 'react-select';

import type { OptionProps } from 'react-select';

type MeasurementSelectProps<T> = Omit<SharedSelectProps<T>, 'Option'> & {
  label?: string;
  selected?: T[];
  onRemoveItem?: (item: T) => void;
};

export const MeasurementSelect = <T extends ProjectSensor | Sensor | ProjectSensorWithRule>({
  onChange,
  label,
  placeholder,
  loadOptions,
  selected = [],
  onRemoveItem,
  onInputChange,
  loading,
  isMulti = true,
  onMenuClose,
  inputValue,
  ...props
}: MeasurementSelectProps<T>) => {
  const { t } = useTranslation();

  const Option = (props: OptionProps) => {
    const option = props.data as ProjectSensor;
    return (
      option && (
        <components.Option {...props}>
          <span style={{ fontSize: '1.25rem' }}>{option.name}</span>
          <div>
            <span style={{ color: colors.base[600], marginRight: '0.25rem' }}>{t('Location')}: </span>
            <span>{option.location ? option.location.name : t('No location')}</span>
            <span style={{ color: colors.base[600], marginLeft: '1rem', marginRight: '0.25rem' }}>{t('Type')}: </span>
            <span>{option.measurement}</span>
          </div>
        </components.Option>
      )
    );
  };

  return (
    <div>
      {label && <Label mb="0.25rem">{label}</Label>}
      <SharedSelect
        onChange={onChange}
        loadOptions={loadOptions}
        selected={selected}
        onInputChange={onInputChange}
        loading={loading}
        isMulti={isMulti}
        onMenuClose={onMenuClose}
        inputValue={inputValue}
        Option={Option}
        placeholder={placeholder}
        {...props}
      />
      <Flex flexDirection="column" alignItems="flex-start">
        {selected.map(
          (sensor, i) =>
            sensor && (
              <div
                key={i}
                style={{
                  display: 'flex',
                  backgroundColor: colors.brand,
                  justifyContent: 'space-between',
                  color: 'white',
                  padding: '0 0.5rem',
                  marginTop: '0.5rem',
                  fontSize: '1.25rem',
                  width: '100%',
                }}
              >
                {'location' in sensor ? (
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >{`${sensor.name} (${t('Location')}: ${
                    sensor.location ? sensor.location.name : t('No location')
                  }; ${t('Type')}: ${t(sensor.measurement)})`}</span>
                ) : (
                  <span>{`${sensor.name} (${t('Type')}: ${t(sensor.measurement)})`}</span>
                )}
                {onRemoveItem && (
                  <button type="button" onClick={() => onRemoveItem(sensor)}>
                    <MdClose size="1rem" style={{ marginLeft: '0.625rem' }} />
                  </button>
                )}
              </div>
            ),
        )}
      </Flex>
    </div>
  );
};
