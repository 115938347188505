import { Project } from 'types/project';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const ADD_PROJECT_KEY = 'addProject';

const ADD_PROJECT = `
  mutation ($name: String!, $description: String) {
    ${ADD_PROJECT_KEY}(data: { name: $name, description: $description }) {
      id
      name
      description
      updatedAt
    }
  }
`;

type MutVarAddProject = {
  name: string;
  description?: string;
};

type MutResAddProject = Pick<Project, 'id' | 'name' | 'description' | 'updatedAt' | 'maintenance'>;

export const useAddProject = () => {
  return useMutation<DefaultQueryResponse<typeof ADD_PROJECT_KEY, MutResAddProject>, MutVarAddProject>(ADD_PROJECT);
};
