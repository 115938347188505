import { useCallback, useEffect, useMemo, useState } from 'react';
import { Location } from 'types/location';
import { PeriodArray } from 'components/form/RangeDatePicker';
import { Measurement, SampleTime } from '@charphq/types';
import { DomainView } from 'types/data';
import { OptionType } from 'components/Selector';
import { parseISO } from 'date-fns';
import {
  MonitorView,
  Project,
  ProjectSensor,
  ProjectSensorWithRule,
  View,
  useQryGetLocationsByProjectSensors,
  useQryGetProjectSensorsByLocations,
  useQryGetProjectSensorsByProjectId,
  useQryLocations,
} from 'graphql/generated';
import { ViewTypes } from './useProjectSensorLocation';
import { ViewType } from 'types';
import { useFilterStore } from 'state/filterStore';
import shallow from 'zustand/shallow';

type ProjectSensorHookProps = {
  view?: ViewTypes;
  selectedRuleSensors?: ProjectSensorWithRule[];
  viewType?: ViewType;
  projectId: Project['id'];
};

export const useProjectSensorLocationState = ({
  view,
  selectedRuleSensors,
  viewType,
  projectId,
}: ProjectSensorHookProps) => {
  const isAlertView = (view: ViewTypes): view is View => view?.type === ViewType.ALERT;
  const isMonitorView = (view: ViewTypes): view is MonitorView => view?.type === ViewType.MONITOR;
  const isTempHumidityView = useCallback(
    () => viewType && [ViewType.ASHRAE, ViewType.DEW_POINT, ViewType.ABSOLUTE_HUMIDITY].includes(viewType),
    [viewType],
  );
  const isLightView = useCallback(
    () => viewType && [ViewType.CUMULATIVE_SUM_LIGHT_HOURS, ViewType.LIGHT_BUDGET].includes(viewType),
    [viewType],
  );
  const [name, setName] = useState<string>('');
  const [period, setPeriod] = useState<PeriodArray>([undefined, undefined]);
  const [sampleTime, setSampleTime] = useState<SampleTime>();
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [autoAddNewDevices, setAutoAddNewDevices] = useState(false);

  const [availableYDomainMeasurements, setAvailableYDomainMeasurements] = useState<string[]>([]);
  const [initialYDomain, setInitialYDomain] = useState<string[]>([]);
  const [yDomain, setYDomain] = useState<OptionType[] | null | undefined>([]);
  const [selectedYDomain, setSelectedYDomain] = useState<DomainView<number | null | undefined>[]>([]);

  const [availableMeasurementTypes, setAvailableMeasurementTypes] = useState<string[]>([]);
  const [initialMeasurementTypes, setInitialMeasurementTypes] = useState<string[]>([]);
  const [measurementTypes, setMeasurementTypes] = useState<OptionType[] | null | undefined>([]);

  const processMeasurements = (sensors: ProjectSensor[] | ProjectSensorWithRule[] | undefined) => {
    const measurements = sensors?.map((sensor) => sensor.measurement) ?? [];
    const uniqueMeasurements = [...new Set(measurements)];

    setAvailableMeasurementTypes(uniqueMeasurements);
    setInitialMeasurementTypes(uniqueMeasurements);
    setMeasurementTypes(uniqueMeasurements.map((measurement) => ({ value: measurement, label: measurement })));
  };

  const handleMonitorView = (view: MonitorView) => {
    view.period && setPeriod([parseISO(view.period.start), view.period.end ? parseISO(view.period.end) : undefined]);
    setSampleTime(view.sampleTime as SampleTime);
    const usedYDomain = view.yDomain?.filter((domain) => domain.measurement) ?? [];
    const domainMap = new Map(usedYDomain.map((domain) => [domain.measurement, domain]));
    const usedYDomainMeasurements = usedYDomain.map((domain) => domain.measurement) as string[];
    const updatedYDomain = usedYDomainMeasurements.map(
      (measurement) => domainMap.get(measurement) || { min: null, max: null, measurement },
    );
    availableYDomainMeasurements.length === 0 && setAvailableYDomainMeasurements(usedYDomainMeasurements);
    setYDomain(usedYDomainMeasurements.map((measurement) => ({ value: measurement, label: measurement })));
    setInitialYDomain(usedYDomainMeasurements);
    setSelectedYDomain(updatedYDomain);
  };

  const filterLocationsBySensors = (locationId: string): boolean => {
    const sensors = selectedRuleSensors ? selectedRuleSensors : view?.sensors;
    return sensors?.some((sensor) => sensor.location?.id === locationId) ?? false;
  };

  const measurementTypesFilter = useMemo(
    () =>
      isTempHumidityView() ? [Measurement.HUMIDITY, Measurement.TEMPERATURE] : isLightView() && [Measurement.LIGHT],
    [isLightView, isTempHumidityView],
  );

  const [{ data: projectSensorsByProjectId }] = useQryGetProjectSensorsByProjectId(
    {
      id: true,
      sensor: {
        id: true,
        measurement: true,
        device: {
          id: true,
          name: true,
          locations: {
            id: true,
            location: {
              id: true,
              name: true,
            },
          },
        },
      },
    },
    { projectId },
    {},
  );

  const projectSensors = useMemo(
    () =>
      measurementTypesFilter
        ? projectSensorsByProjectId?.filter((sensor) =>
            measurementTypesFilter.includes((sensor.sensor?.measurement as Measurement) ?? ''),
          )
        : projectSensorsByProjectId,
    [measurementTypesFilter, projectSensorsByProjectId],
  );

  const [filter] = useFilterStore(
    (state) => [state.projectSensorsForm.values, state.projectSensorsForm.filter],
    shallow,
  );

  const [{ data: dynamicLocationsByProjectSensors }] = useQryGetLocationsByProjectSensors(
    {
      id: true,
      name: true,
      organization: {
        id: true,
        name: true,
        createdAt: true,
        email: true,
        timezone: true,
        users: {
          id: true,
          email: true,
          firstName: true,
          lastName: true,
          createdAt: true,
        },
      },
      createdAt: true,
      updatedAt: true,
    },
    {
      projectSensorIds: projectSensors?.map((s) => s.id) ?? [],
      projectId,
      viewType: viewType ?? ViewType.MONITOR,
      filter: filter?.name ?? '',
    },
    {
      pause: projectSensors?.length === 0,
    },
  );

  const initialLocationsByProjectSensors = useMemo(
    () => dynamicLocationsByProjectSensors?.filter((location) => filterLocationsBySensors(location.id)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dynamicLocationsByProjectSensors, view],
  );

  useEffect(() => {
    if (!view) return;
    setName(view.name);
    setAutoAddNewDevices(view.autoAddNewDevices ?? false);
    isAlertView(view)
      ? selectedRuleSensors && processMeasurements(selectedRuleSensors)
      : view.sensors && processMeasurements(view.sensors);
    if (initialLocationsByProjectSensors && selectedLocations.length === 0) {
      setSelectedLocations(initialLocationsByProjectSensors);
    }
    isMonitorView(view) && handleMonitorView(view);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRuleSensors, view, initialLocationsByProjectSensors, selectedLocations.length]);

  const locationsContext = useMemo(() => ({ additionalTypenames: ['Location'] }), []);
  const [{ data: locations }] = useQryLocations(
    {
      id: true,
      name: true,
    },
    {
      context: locationsContext,
    },
  );

  const [{ data: projectSensorsByLocations }] = useQryGetProjectSensorsByLocations(
    {
      id: true,
      name: true,
      sensorId: true,
      sensor: {
        id: true,
        measurement: true,
        device: {
          id: true,
          name: true,
          locations: {
            id: true,
            location: {
              id: true,
              name: true,
            },
          },
        },
      },
    },
    {
      locationIds: locations?.map((location) => location.id) ?? [],
      projectId,
    },
    {},
  );

  const filteredProjectSensorsByLocations = useMemo(
    () =>
      measurementTypesFilter
        ? projectSensorsByLocations?.filter((sensor) =>
            measurementTypesFilter.includes((sensor.sensor?.measurement as Measurement) ?? ''),
          )
        : projectSensorsByLocations,
    [measurementTypesFilter, projectSensorsByLocations],
  );

  const projectSensorsBySelectedLocations = useMemo(
    () =>
      filteredProjectSensorsByLocations?.filter((sensor) =>
        sensor.sensor?.device?.locations.some((deviceLocation) =>
          selectedLocations.some((location) => location.id === deviceLocation.location.id),
        ),
      ),
    [filteredProjectSensorsByLocations, selectedLocations],
  );

  useEffect(() => {
    if (selectedLocations.length === 0) {
      setMeasurementTypes([]);
      setAvailableMeasurementTypes([]);
      setSelectedYDomain([]);
      setAvailableYDomainMeasurements([]);
      setYDomain([]);
      return;
    }
    const measurements = projectSensorsBySelectedLocations?.map((sensor) => sensor.sensor?.measurement);
    const uniqueMeasurements = [...new Set(measurements)] as string[];
    setAvailableMeasurementTypes(uniqueMeasurements);
  }, [projectSensorsBySelectedLocations, selectedLocations]);

  useEffect(() => {
    const newMeasurementTypes = measurementTypes?.map((measurement) => measurement.value) ?? [];
    setAvailableYDomainMeasurements(newMeasurementTypes);
    setYDomain((prevYDomain) => prevYDomain?.filter((domain) => newMeasurementTypes.includes(domain.value)) || []);
    setSelectedYDomain((prevSelectedYDomain) => {
      const filteredSelectedYDomain = prevSelectedYDomain.filter((domain) =>
        newMeasurementTypes.includes(domain.measurement ?? ''),
      );
      return filteredSelectedYDomain;
    });
  }, [measurementTypes]);

  useEffect(() => {
    if (
      measurementTypes?.length &&
      measurementTypes.length > availableMeasurementTypes.length &&
      availableMeasurementTypes.length > 0
    ) {
      setMeasurementTypes(availableMeasurementTypes.map((measurement) => ({ value: measurement, label: measurement })));
    }
    if (
      yDomain?.length &&
      yDomain.length > availableYDomainMeasurements.length &&
      availableYDomainMeasurements.length > 0
    ) {
      setYDomain(availableYDomainMeasurements.map((measurement) => ({ value: measurement, label: measurement })));
      setSelectedYDomain((prev) => {
        const domainMap = new Map(prev.map((domain) => [domain.measurement, domain]));
        const newDomain = availableYDomainMeasurements.map(
          (measurement) => domainMap.get(measurement) || { min: null, max: null, measurement },
        );
        return newDomain;
      });
    }
  }, [availableMeasurementTypes, availableYDomainMeasurements, measurementTypes, yDomain]);

  return {
    name,
    setName,
    period,
    setPeriod,
    sampleTime,
    setSampleTime,
    selectedLocations,
    setSelectedLocations,
    autoAddNewDevices,
    setAutoAddNewDevices,
    availableYDomainMeasurements,
    setAvailableYDomainMeasurements,
    initialYDomain,
    yDomain,
    setYDomain,
    selectedYDomain,
    setSelectedYDomain,
    availableMeasurementTypes,
    setAvailableMeasurementTypes,
    measurementTypes,
    setMeasurementTypes,
    initialMeasurementTypes,
    processMeasurements,
    handleMonitorView,
    projectSensorsBySelectedLocations,
    dynamicLocationsByProjectSensors,
  };
};
