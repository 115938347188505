import { ProjectNotificationConfiguration } from 'types/projectNotificationConfiguration';
import { ProjectNotificationTime } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE_KEY = 'editProjectNotificationConfigurationValue';

const EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE = `
  mutation ($projectNotificationConfigurationId: String!, $value: ProjectNotificationTime!) {
    ${EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE_KEY}(uuid: $projectNotificationConfigurationId, data: { value: $value }) {
      id
      value
    }
  }
`;

type MutVarEditProjectNotificationConfigurationValue = {
  projectNotificationConfigurationId: string;
  value: ProjectNotificationTime;
};

type MutResEditProjectNotificationConfigurationValue = DefaultQueryResponse<
  typeof EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE_KEY,
  Pick<ProjectNotificationConfiguration, 'id' | 'value'>
>;

export const useEditProjectNotificationConfigurationValue = () => {
  return useMutation<MutResEditProjectNotificationConfigurationValue, MutVarEditProjectNotificationConfigurationValue>(
    EDIT_PROJECT_NOTIFICATION_CONFIGURATION_VALUE,
  );
};
