import { ProjectUser } from 'types/projectUser';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const ADD_PROJECT_USERS_KEY = 'addProjectUsers';

const ADD_PROJECT_USERS = `
  mutation ($projectId: String!, $metadata: [AddProjectUserInput!]!) {
    ${ADD_PROJECT_USERS_KEY}(uuid: $projectId, data: { values: $metadata }) {
      id
      role
      email
      userId
      communicationChannel
      firstName
      lastName
      createdAt
    }
  }
`;

type AddUserProjectInput = {
  id: string;
  role: string;
};

type MutVarAddProjectUsers = {
  projectId: string;
  metadata: AddUserProjectInput[];
};

type MutProjectUser = Pick<
  ProjectUser,
  'id' | 'role' | 'email' | 'userId' | 'communicationChannel' | 'firstName' | 'lastName' | 'createdAt'
>;

type MutResAddProjectUsers = MutProjectUser[];

export const useAddProjectUsers = () => {
  return useMutation<DefaultQueryResponse<typeof ADD_PROJECT_USERS_KEY, MutResAddProjectUsers>, MutVarAddProjectUsers>(
    ADD_PROJECT_USERS,
  );
};
