import React, { Dispatch, SetStateAction } from 'react';
import { Location } from 'types/location';
import { Flex } from 'components/layout/Flex';
import { useQryLocations } from 'graphql/generated';
import Selector, { SelectorStyledCustom } from 'components/Selector';
import { colors } from 'theme/colors';

type NoteLocationsProps = {
  setSelectedLocations: Dispatch<SetStateAction<Location[]>>;
  selectedLocations: Location[];
};

const locationsQuery = {
  id: true,
  createdAt: true,
  name: true,
  organization: {
    language: true,
    id: true,
    name: true,
    createdAt: true,
    email: true,
    timezone: true,
    users: {
      id: true,
      email: true,
      firstName: true,
      lastName: true,
      createdAt: true,
    },
  },
  updatedAt: true,
} as const;

const NoteLocations: React.FC<NoteLocationsProps> = ({ setSelectedLocations, selectedLocations }) => {
  const [{ data: locations }] = useQryLocations(locationsQuery, {});

  return (
    <Flex
      flexDirection="column"
      style={{ padding: '1rem', zIndex: 100, borderBottom: `1px solid ${colors.secondary[700]}` }}
    >
      <Selector
        options={
          locations?.map((location) => ({
            value: location.name,
            label: location.name,
          })) ?? []
        }
        selectedValue={selectedLocations.map((location) => ({
          value: location.name,
          label: location.name,
        }))}
        setSelectedValue={(selectedValue) => {
          selectedValue &&
            setSelectedLocations(
              selectedValue.map((value) => locations?.find((location) => location.name === value.value) as Location),
            );
        }}
        style={{ width: '100%', border: 'solid 1px' }}
        onChange={(newValue) => {
          setSelectedLocations(
            newValue.map((value) => locations?.find((location) => location.name === value) as Location),
          );
        }}
        initialValue={selectedLocations.map((location) => location.name)}
        isMulti
        customStylesSelect={(provided) => ({
          ...provided,
          ...SelectorStyledCustom,
          flexWrap: 'wrap',
          columnGap: '0.5rem',
          rowGap: '0.25rem',
          multiValueWidth: '3rem',
          backgroundColorMultiValue: colors.secondary[900],
        })}
      />
    </Flex>
  );
};

export default NoteLocations;
