import { Button } from 'components/buttons';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useLocale } from 'context/LocaleContext';
import { useQryDevice } from 'graphql/generated';
import { useDownloadDeviceData } from 'graphql/mutation/useDownloadDeviceData';
import { FC, FormEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Severity } from 'state/snackbarStore';
import { createDeviceDataCSV } from 'utils/data';
import { downloadFile } from 'utils/download';
import { dispatchErrors } from 'utils/util';
import { Form } from './form';
import { Checkbox } from './form/Checkbox';
import { Label } from './form/Label';
import { Flex } from './layout/Flex';
import { useSnackbar } from './Snackbar';
import { Table, Tbody, Td, Th, Thead, Tr } from './table/Table';
import { SubtleMessage } from './typography';
import { ErrorMessage } from './typography/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { RangeDatePicker } from './form/RangeDatePicker';
import type { PeriodArray } from './form/RangeDatePicker';

type Props = DrawerComponentProps & {
  deviceId: string;
};

export const DownloadDeviceDataDrawer: FC<Props> = ({ requestClose, deviceId }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const authContext = useContext(AuthContext);
  const { locale } = useLocale();
  const [genCSV, setGenCSV] = useState<boolean>(false);
  const [completePeriod, setCompletePeriod] = useState<boolean>(false);
  const [selectAllSensors, setSelectAllSensors] = useState<boolean>(false);
  const [selectedSensorIds, setSelectedSensorIds] = useState<string[]>([]);
  const [period, setPeriod] = useState<PeriodArray>([undefined, undefined]);
  const [errors, setErrors] = useState<Array<'date' | 'sensors'>>([]);

  const [{ data: device, fetching: loading }] = useQryDevice(
    {
      id: true,
      name: true,
      type: true,
      archivedAt: true,
      sensors: {
        id: true,
        measurement: true,
        unit: true,
      },
      locations: {
        createdAt: true,
        location: {
          name: true,
        },
      },
    },
    {
      uuid: deviceId,
    },
    {},
  );

  const [{ fetching: downloadingData }, downloadDeviceData] = useDownloadDeviceData();
  const downloading = useMemo(() => downloadingData || genCSV, [downloadingData, genCSV]);

  const onCompletePeriod = useCallback((checked: boolean) => {
    if (checked) {
      setPeriod([undefined, undefined]);
    }
    setCompletePeriod(checked);
  }, []);

  const onSelectSensor = useCallback(
    (selected: boolean, sensorId: string) => {
      if (selected) {
        setSelectedSensorIds((prev) => prev.concat([sensorId]));
      } else {
        if (selectAllSensors) {
          setSelectAllSensors(false);
        }
        setSelectedSensorIds((prev) => prev.filter((id) => id !== sensorId));
      }
    },
    [selectAllSensors],
  );

  const onSelectAllSensors = useCallback(
    (checked) => {
      setSelectAllSensors(checked);
      if (checked) {
        setSelectedSensorIds(device?.sensors?.map((sensor) => sensor.id) ?? []);
      } else {
        setSelectedSensorIds([]);
      }
    },
    [device],
  );

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (device && locale) {
        if (period[0] === undefined && !completePeriod) {
          setErrors((prev) => prev.concat(['date']));
          return;
        } else {
          setErrors((prev) => prev.filter((err) => err !== 'date'));
        }
        if (selectedSensorIds.length === 0 && !selectAllSensors) {
          setErrors((prev) => prev.concat(['sensors']));
          return;
        } else {
          setErrors((prev) => prev.filter((err) => err !== 'sensors'));
        }
        const startDate: Date = period[0] !== undefined ? period[0] : new Date(0);
        const endDate =
          device.archivedAt && period[1] && new Date(device.archivedAt) < new Date(period[1])
            ? new Date(device.archivedAt)
            : new Date(period[1] || device.archivedAt || new Date());

        const sensorData = await downloadDeviceData({
          data: {
            start: startDate,
            end: endDate,
            sensorIds: selectedSensorIds,
          },
        });
        if (sensorData.data) {
          setGenCSV(true);
          const dataBlob = createDeviceDataCSV(device, sensorData.data.downloadDeviceData, locale?.number, t);
          downloadFile(dataBlob, `${device.name}.csv`);
          snackbar.addAlert(t('The data has been downloaded'), Severity.SUCCESS);
          setGenCSV(false);
          requestClose();
        }
        if (sensorData.error) {
          dispatchErrors(snackbar, sensorData.error, authContext, t);
        }
      }
    },
    [
      authContext,
      completePeriod,
      device,
      downloadDeviceData,
      locale,
      requestClose,
      selectAllSensors,
      selectedSensorIds,
      snackbar,
      period,
      t,
    ],
  );

  return (
    <DrawerLayout title={t('Download data from device')} onClose={requestClose}>
      <Form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Body>
          <Label>{t('Device')}</Label>
          <Flex flexDirection="row" gap="3.2rem" mb="1.5rem">
            <span>{device?.name}</span>
            <span>{device?.type}</span>
          </Flex>
          <Flex gap="1.5rem" alignItems="center">
            <Label style={{ width: '16.5rem' }}>{t('Define the period')}</Label>
            <Checkbox
              label={t('Download all data')}
              checked={completePeriod}
              onChange={(e) => onCompletePeriod(e.target.checked)}
            />
          </Flex>
          <Flex mb="1.5rem" flexDirection="column">
            <RangeDatePicker period={period} onChange={setPeriod} />
            <Flex height={4} mt="0">
              {errors.includes('date') && <ErrorMessage>{t('Set start date or download all')}</ErrorMessage>}
            </Flex>
          </Flex>

          <Flex gap="1.5rem" alignItems="center">
            <Label style={{ width: '16.5rem' }}>{t('Select the measurements')}</Label>
            <Flex flexDirection="row" alignItems="center" gap="0.5rem">
              <Checkbox
                label={t('actions.Select all measurements')}
                checked={selectAllSensors}
                onChange={(e) => onSelectAllSensors(e.target.checked)}
              />
            </Flex>
          </Flex>
          <Table>
            <Thead>
              <Tr>
                <Th col={1} />
                <Th>{t('Type')}</Th>
                <Th>{t('Unit')}</Th>
              </Tr>
            </Thead>
            <Tbody $loading={loading}>
              {device && device.sensors?.length > 0 ? (
                device.sensors.map((sensor) => {
                  const isSelected = selectedSensorIds.includes(sensor.id);
                  return (
                    <Tr
                      key={sensor.id}
                      bg={isSelected ? 'lightBlue.200' : undefined}
                      hoverBg={isSelected ? 'lightBlue.200' : undefined}
                    >
                      <Td>
                        <Checkbox
                          checked={isSelected}
                          onChange={(e) => onSelectSensor(e.target.checked, sensor.id)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Td>
                      <Td>{t(sensor.measurement, { ns: 'db-values' })}</Td>
                      <Td>{t(sensor.unit, { ns: 'db-values' })}</Td>
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td />
                  <Td>
                    <SubtleMessage>{t('No measurements found')}</SubtleMessage>
                  </Td>
                  <Td />
                </Tr>
              )}
            </Tbody>
          </Table>
        </Body>
        <Flex height={4} mt={2}>
          {errors.includes('sensors') && <ErrorMessage>{t('You should select at least one measurement')}</ErrorMessage>}
        </Flex>
        <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
          <Button smallPad type="button" onClick={() => requestClose()} variant="plain">
            {t('actions.Cancel')}
          </Button>
          <Button smallPad type="submit" disabled={downloadingData} loading={downloading}>
            {t('Download data')}
          </Button>
        </DrawerFooter>
      </Form>
    </DrawerLayout>
  );
};
