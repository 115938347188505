import { Avatar } from 'components/Avatar';
import { Table, Tbody, Td, TdOverflow, Th, Thead, Tr } from 'components/table/Table';
import { TableMoreMenu } from 'components/table/TableMoreMenu';
import { UserContext } from 'context/UserContext';
import { format } from 'date-fns';
import { Note, User } from 'graphql/generated';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDeleteOutline, MdEdit } from 'react-icons/md';
import { Location } from 'types/location';

type MutUser = Pick<User, 'id' | 'email' | 'firstName' | 'lastName' | 'createdAt'>;
export type BasicNote = Pick<Note, 'id' | 'title' | 'description' | 'period' | 'updatedAt' | 'createdAt'> & {
  views?: Array<{ name: string; id: string; type: string }>;
  owner: MutUser;
  locations?: Location[];
};

type Props = {
  notes: BasicNote[];
  selectedNoteId?: Note['id'];
  onSelectNote: (note: BasicNote) => void;
  onDeleteNote: (note: BasicNote) => void;
};

export const NotesTable: FC<Props> = ({ notes, selectedNoteId, onSelectNote, onDeleteNote }) => {
  const [me] = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <Table fixed>
      <Thead>
        <Tr>
          <Th width="10rem" backgroundColor="note.100" accentColor="note.400">
            {t('Title')}
          </Th>
          <Th width="6rem" backgroundColor="note.100" accentColor="note.400">
            {t('From')}
          </Th>
          <Th width="6rem" backgroundColor="note.100" accentColor="note.400">
            {t('To')}
          </Th>
          <Th width="6rem" backgroundColor="note.100" accentColor="note.400">
            {t('Created')}
          </Th>
          <Th width="5rem" backgroundColor="note.100" accentColor="note.400">
            {t('Author')}
          </Th>
          <Th width="5rem" backgroundColor="note.100" accentColor="note.400" />
        </Tr>
      </Thead>
      <Tbody>
        {notes.map((note) => {
          const isSelected = note.id === selectedNoteId;
          return (
            <Tr
              key={note.id}
              bg={isSelected ? 'note.200' : undefined}
              hoverBg={isSelected ? 'note.200' : 'note.150'}
              onClick={() => note.owner.id && onSelectNote(note)}
              style={{ cursor: note.owner.id ? 'pointer' : 'default' }}
            >
              <Td>{note.title}</Td>
              <TdOverflow title={note.period?.start && format(new Date(note.period?.start), 'yyyy-MM-dd HH:mm')}>
                {note.period?.start ? format(new Date(note.period?.start), 'yyyy-MM-dd HH:mm') : '-'}
              </TdOverflow>
              <TdOverflow title={(note.period?.end && format(new Date(note.period?.end), 'yyyy-MM-dd HH:mm')) ?? ''}>
                {note.period?.end ? format(new Date(note.period?.end), 'yyyy-MM-dd HH:mm') : '-'}
              </TdOverflow>
              <TdOverflow title={format(new Date(note.createdAt), 'yyyy-MM-dd HH:mm')}>
                {format(new Date(note.createdAt), 'yyyy-MM-dd HH:mm')}
              </TdOverflow>
              <Td>
                <Avatar size="1.75rem" user={note.owner} />
              </Td>
              <Td>
                <TableMoreMenu
                  stopPropagation
                  items={[
                    {
                      text: t('actions.Edit note'),
                      onClick: () => onSelectNote(note),
                      icon: MdEdit,
                      disabled: note.owner.id !== me?.id,
                    },
                    {
                      text: t('actions.Delete note'),
                      onClick: () => onDeleteNote(note),
                      icon: MdDeleteOutline,
                      disabled: note.owner.id !== me?.id,
                    },
                  ]}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};
