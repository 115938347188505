import { isEqual } from 'lodash-es';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { Location } from 'types/location';
import { PartialNote } from 'types/note';

type NoteContextType = {
  initialNote?: PartialNote;
  setInitialNote: React.Dispatch<React.SetStateAction<PartialNote>>;
  editedNote: PartialNote;
  setEditedNote: React.Dispatch<React.SetStateAction<PartialNote>>;
  selectedNoteLocations?: Location[];
  setSelectedNoteLocations: React.Dispatch<React.SetStateAction<Location[] | undefined>>;
  resetNoteState: (noteState: PartialNote, locations?: Location[]) => void;
};

const NoteContext = createContext<NoteContextType | undefined>(undefined);

export const NoteProvider = ({ children }: { children: ReactNode }) => {
  const [initialNote, setInitialNote] = useState<PartialNote>({});
  const [editedNote, setEditedNote] = useState<PartialNote>({});
  const [selectedNoteLocations, setSelectedNoteLocations] = useState<Location[] | undefined>([]);

  const resetNoteState = (noteState: PartialNote, locations?: Location[]) => {
    setEditedNote(noteState);
    setInitialNote(noteState);
    setSelectedNoteLocations(locations ?? []);
  };

  useEffect(() => {
    const storedState = localStorage.getItem('noteState');
    const parsedState = storedState ? JSON.parse(storedState) : {};
    if (!parsedState?.noteState || (!isEqual(parsedState.noteState, initialNote) && initialNote.title))
      localStorage.setItem('noteState', JSON.stringify({ noteState: initialNote }));
  }, [initialNote, selectedNoteLocations]);

  useEffect(() => {
    const storedState = localStorage.getItem('noteState');
    localStorage.removeItem('drawer-pinned');
    if (storedState && !initialNote.title) {
      const parsedState = JSON.parse(storedState);
      resetNoteState(parsedState.noteState, parsedState.noteState?.locations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NoteContext.Provider
      value={{
        initialNote,
        setInitialNote,
        editedNote,
        setEditedNote,
        selectedNoteLocations,
        setSelectedNoteLocations,
        resetNoteState,
      }}
    >
      {children}
    </NoteContext.Provider>
  );
};

export const useNoteContext = (): NoteContextType => {
  const context = useContext(NoteContext);
  if (!context) {
    throw new Error('useNoteContext must be used within a NoteProvider');
  }
  return context;
};
