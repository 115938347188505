import { FC, useCallback } from 'react';
import { View, ProjectSensor, useQryAshrae2019 } from 'graphql/generated';
import { Project } from 'types/project';
import { Ashrae2019Average, Ashrae2019Control, Ashrae2019View } from 'types/view/ashrae2019View';
import { AshraeViewForm } from './AshraeViewForm';
import { useTranslation } from 'react-i18next';
import { useEditAshrae2019View } from 'server/mutations/view/useEditAshrae2019View';
import { useSnackbar } from 'components/Snackbar';
import { ViewType, viewConfigurations } from 'types';
import { Severity } from 'state/snackbarStore';
import { useHistory } from 'hooks/useHistoryEnhanced';

type Props = {
  projectId: Project['id'];
  view: Pick<View, 'id' | 'name' | 'type'>;
  onClose: () => void;
};

export const EditAshraeView: FC<Props> = ({ projectId, onClose, view }) => {
  const { t } = useTranslation();
  const [{ fetching: loading }, edit] = useEditAshrae2019View();
  const snackbar = useSnackbar();
  const history = useHistory();

  const [{ data: ashraeView }] = useQryAshrae2019(
    {
      id: true,
      name: true,
      type: true,
      version: true,
      control: true,
      average: {
        temp: true,
        hum: true,
      },
      sensors: {
        id: true,
        name: true,
        unit: true,
        location: {
          id: true,
          name: true,
        },
        sensorId: true,
        measurement: true,
        updatedAt: true,
      },
      updatedAt: true,
      autoAddNewDevices: true,
    },
    { uuid: view.id },
    {},
  );

  const editAshraeView = useCallback(
    async (
      name: string,
      control: Ashrae2019Control,
      average: Ashrae2019Average,
      projectSensorIds: ProjectSensor['id'][],
      autoAddNewDevices?: boolean,
    ) => {
      await edit({
        viewId: view.id,
        data: {
          name,
          control,
          average,
          projectSensorIds,
          autoAddNewDevices,
        },
      });
      snackbar.addAlert(
        t('{{view}} view saved', { view: t(viewConfigurations[view.type as ViewType].displayName) }),
        Severity.SUCCESS,
      );
      window.location.href = history.location.pathname;
      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [view],
  );

  return (
    <AshraeViewForm
      projectId={projectId}
      saveLabel={t('Edit view')}
      onSave={editAshraeView}
      onClose={onClose}
      loading={loading}
      view={{ projectId, ...ashraeView } as Ashrae2019View}
    />
  );
};
