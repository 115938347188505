import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const DELETE_ORGANIZATION_USER_KEY = 'deleteOrganizationUser';

const DELETE_ORGANIZATION_USER = `
  mutation ($organizationId: String!, $userId: String!) {
    ${DELETE_ORGANIZATION_USER_KEY}(organizationId: $organizationId, userId: $userId)
  }
`;

type MutVarDeleteOrganizationUser = {
  organizationId: string;
  userId: string;
};

type MutResDeleteOrganizationUser = DefaultQueryResponse<typeof DELETE_ORGANIZATION_USER_KEY, boolean>;

export const useDeleteOrganizationUser = () => {
  return useMutation<MutResDeleteOrganizationUser, MutVarDeleteOrganizationUser>(DELETE_ORGANIZATION_USER);
};
