import { Organization } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_ORGANIZATION_KEY = 'editOrganization';

const EDIT_ORGANIZATION = `
  mutation ($data: EditOrganizationInput!) {
    ${EDIT_ORGANIZATION_KEY}(data: $data) {
      id,
      timezone,
      name,
      language,
      users {
        id,
        firstName,
        lastName,
        email,
      },
    }
  }
`;

type MutVareditOrganization = {
  data: {
    timezone?: string;
    language?: string;
    name?: string;
  };
};

type MutResEditOrganization = DefaultQueryResponse<typeof EDIT_ORGANIZATION_KEY, Organization>;

export const useEditOrganization = () => {
  return useMutation<MutResEditOrganization, MutVareditOrganization>(EDIT_ORGANIZATION);
};
