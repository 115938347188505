import { useMutation } from 'urql';
import { DefaultQueryResponse } from 'graphql/query/default';
import { Addon, AddonData, AddonName, AddonType } from 'types/addon';

const ADD_ADDON_KEY = 'addAddon';

const ADD_ADDON = `
  mutation ($name: String!, $type: String!, $data: AddonDataInput!) {
    ${ADD_ADDON_KEY}(data: { name: $name, type: $type, data: $data }) {
      id
      name
      type
      data {
        customerId
      }
      createdAt
    }
  }
`;

type MutVarAddAddon = {
  name: AddonName;
  type: AddonType;
  data: {
    customerId: string;
  };
};

type MutAddonData = Pick<AddonData, 'customerId'>;

type MutResAddAddon = DefaultQueryResponse<
  typeof ADD_ADDON_KEY,
  Pick<Addon, 'id' | 'name' | 'type' | 'createdAt'> & { data: MutAddonData }
>;

export const useAddAddon = () => {
  return useMutation<MutResAddAddon, MutVarAddAddon>(ADD_ADDON);
};
