import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useHistory } from 'hooks/useHistoryEnhanced';
import { useCallback, useContext, useState, VFC } from 'react';
import { View } from 'graphql/generated';
import { viewConfigurations, ViewType } from 'types/view';
import { AddAshraeView } from './AddAshraeView';
import { AddCumulativeLightHoursView } from './AddCumulativeLightView';
import { AddLightBudgetView } from './AddLightBudgetView';
import { AddMonitorView } from './AddMonitorView';
import { AddTemperatureHumidityView } from './AddTemperatureHumidityView';
import { ViewSelection } from './ViewSelection';
import { useTranslation } from 'react-i18next';
import { ProjectContext } from 'context/ProjectContext';
import useIsMobile from 'hooks/useIsMobile';

type Props = DrawerComponentProps & {
  projectId: string;
};

export const ViewCreationWizardDrawer: VFC<Props> = ({ projectId, requestClose }) => {
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<ViewType>();
  const { refetchProjects } = useContext(ProjectContext);

  const history = useHistory();
  const isMobile = useIsMobile();

  const routeToView = useCallback(
    (view: View) => {
      requestClose();
      refetchProjects();
      history.push(`/projects/${projectId}/views/${view.id}`);
    },
    [history, projectId, refetchProjects, requestClose],
  );

  return (
    <DrawerLayout
      title={
        isMobile
          ? t('actions.Add view')
          : t('Add {{type}} view', { type: viewType && t(viewConfigurations[viewType].displayName) })
      }
      onClose={requestClose}
      onBack={
        viewType
          ? () => {
              setViewType(undefined);
            }
          : undefined
      }
    >
      {!viewType && <ViewSelection onSelect={(type) => setViewType(type)} onClose={requestClose} />}
      {viewType === ViewType.MONITOR && (
        <AddMonitorView projectId={projectId} afterCreate={routeToView} flexGrow={1} onClose={requestClose} />
      )}
      {viewType === ViewType.CUMULATIVE_SUM_LIGHT_HOURS && (
        <AddCumulativeLightHoursView projectId={projectId} afterCreate={routeToView} onClose={requestClose} />
      )}
      {viewType === ViewType.ASHRAE && (
        <AddAshraeView projectId={projectId} afterCreate={routeToView} onClose={requestClose} />
      )}
      {(viewType === ViewType.DEW_POINT || viewType === ViewType.ABSOLUTE_HUMIDITY) && (
        <AddTemperatureHumidityView
          projectId={projectId}
          afterCreate={routeToView}
          onClose={requestClose}
          viewType={viewType}
        />
      )}
      {viewType === ViewType.LIGHT_BUDGET && (
        <AddLightBudgetView projectId={projectId} afterCreate={routeToView} onClose={requestClose} />
      )}
    </DrawerLayout>
  );
};
