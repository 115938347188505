import { FC, useCallback } from 'react';
import { useAddTemperatureHumidityView } from 'server';
import { View } from 'graphql/generated';
import { Sensor } from 'types/sensor';
import { TemperatureHumidityViewForm } from './TemperatureHumidityViewForm';
import { useTranslation } from 'react-i18next';
import { ViewType } from 'types';
import { SampleTime } from '@charphq/types';

type Props = {
  projectId: string;
  afterCreate?: (view: View) => void;
  onClose: () => void;
  viewType: ViewType.DEW_POINT | ViewType.ABSOLUTE_HUMIDITY;
};

export const AddTemperatureHumidityView: FC<Props> = ({ projectId, afterCreate, onClose, viewType }) => {
  const { t } = useTranslation();
  const [{ fetching: loading }, addTemperatureHumidity] = useAddTemperatureHumidityView();

  const addTemperatureHumidityViewToProject = useCallback(
    async (
      name: View['name'],
      sensorIds: Sensor['id'][],
      sampleTime: SampleTime = SampleTime.HOUR,
      autoAddNewDevices?: boolean,
    ) => {
      const temperatureHumidityView = await addTemperatureHumidity({
        projectId,
        data: {
          name,
          projectSensorIds: sensorIds,
          autoAddNewDevices,
          viewType,
          sampleTime,
        },
      });
      if (afterCreate) {
        temperatureHumidityView.data?.addTemperatureHumidityView &&
          afterCreate(temperatureHumidityView.data.addTemperatureHumidityView);
      }
    },
    [addTemperatureHumidity, projectId, viewType, afterCreate],
  );

  return (
    <TemperatureHumidityViewForm
      projectId={projectId}
      saveLabel={t('actions.Add view')}
      onSave={addTemperatureHumidityViewToProject}
      loading={loading}
      onClose={onClose}
      viewType={viewType}
    />
  );
};
