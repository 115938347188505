import { FC, useCallback } from 'react';
import { useAddCumulativeSumLightHoursView } from 'server';
import { View } from 'graphql/generated';
import { Sensor } from 'types/sensor';
import { CumulativeLightViewForm } from './CumulativeLightViewForm';
import { useTranslation } from 'react-i18next';

type Props = {
  projectId: string;
  afterCreate?: (view: View) => void;
  onClose: () => void;
};

export const AddCumulativeLightHoursView: FC<Props> = ({ projectId, afterCreate, onClose }) => {
  const { t } = useTranslation();
  const [{ fetching: loading }, addCumSumLightHours] = useAddCumulativeSumLightHoursView();

  const addCumulativeLightHoursViewToProject = useCallback(
    async (name: View['name'], sensorIds: Sensor['id'][], autoAddNewDevices?: boolean) => {
      const cumSumLighHoursView = await addCumSumLightHours({
        projectId,
        data: {
          name,
          projectSensorIds: sensorIds,
          autoAddNewDevices,
        },
      });
      if (afterCreate) {
        cumSumLighHoursView.data?.addCumulativeSumLightHoursView &&
          afterCreate(cumSumLighHoursView.data.addCumulativeSumLightHoursView);
      }
    },
    [projectId, afterCreate, addCumSumLightHours],
  );

  return (
    <CumulativeLightViewForm
      projectId={projectId}
      saveLabel={t('actions.Add view')}
      onSave={addCumulativeLightHoursViewToProject}
      loading={loading}
      onClose={onClose}
    />
  );
};
