import { css } from '@styled-system/css';
import { FC, useCallback, useState } from 'react';
import type { DropzoneProps } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import type { LayoutProps, SpaceProps } from 'styled-system';
import { compose, layout, space } from 'styled-system';
import { useTranslation } from 'react-i18next';
import { Text } from './typography';

type DragStateProps = {
  isDragAccept?: boolean;
  isDragReject?: boolean;
  isFocused?: boolean;
};

const getColour = (props: DragStateProps, baseColor?: string) => {
  if (props.isDragAccept) {
    return 'brandGreen.500';
  }
  if (props.isDragReject) {
    return 'red';
  }
  if (props.isFocused) {
    return 'grey.900';
  }
  return baseColor;
};

const DropzoneContainer = styled.div<
  DragStateProps & LayoutProps & SpaceProps & { $baseColor: string; $bgColor: string }
>`
  flex: 1;
  display: grid;
  place-items: center;
  padding: 1rem;
  & > p {
    max-width: 24rem;
    text-align: center;
  }
  border-width: 1px;
  border-radius: 0.3rem;
  ${(props) =>
    css({
      color: getColour(props, props.$baseColor),
      borderColor: getColour(props, props.$baseColor),
      backgroundColor: props.$bgColor,
    })};
  border-style: solid;
  outline: none;
  ${compose(layout, space)}
`;

type Props = {
  selectedFileName?: string;
  onAcceptedFile: (acceptedFile: File) => void;
  accept?: DropzoneProps['accept'];
  maxSize: DropzoneProps['maxSize'];
  multiple: DropzoneProps['multiple'];
  baseColor?: string;
  bgColor?: string;
  disabled?: boolean;
} & LayoutProps &
  SpaceProps;

export const Dropzone: FC<Props> = ({
  selectedFileName,
  accept,
  maxSize,
  multiple,
  onAcceptedFile,
  baseColor = 'grey.400',
  bgColor = 'grey.100',
  disabled,
  ...props
}) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState<string | undefined>(selectedFileName);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setFileName(file.name);
        onAcceptedFile(file);
      }
    },
    [onAcceptedFile],
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept,
    multiple,
    maxSize,
    onDrop,
    disabled,
  });

  return (
    <DropzoneContainer
      $baseColor={baseColor}
      $bgColor={bgColor}
      {...getRootProps({ isFocused, isDragAccept, isDragReject, ...props })}
    >
      <input {...getInputProps()} />
      {fileName === undefined ? (
        <Text variant="large" fontWeight="semibold">
          {t('Click or drag a file to this area to upload')}
        </Text>
      ) : (
        <p>{t('File: {{data}}, click to select another file.', { data: fileName })}</p>
      )}
    </DropzoneContainer>
  );
};
