import { SampleTime } from '@charphq/types';
import { useSnackbar } from 'components/Snackbar';
import { View, MonitorView, useQryMonitorView, ProjectSensor } from 'graphql/generated';
import { useEditMonitorViewYDomain } from 'graphql/mutation/useEditMonitorViewYDomain';
import { FC, useCallback } from 'react';
import { useEditMonitorViewSensors, useEditViewName } from 'server';
import { useEditMonitorView } from 'server/mutations/view/useEditMonitorView';
import { Severity } from 'state/snackbarStore';
import { viewConfigurations, ViewType } from 'types/view';
import { DomainView } from 'types/data';
import { isSamePeriod } from 'types/period';
import { Project } from 'types/project';
import { MonitorViewForm } from './MonitorViewForm';
import { useTranslation } from 'react-i18next';
import type { PeriodArray } from 'components/form/RangeDatePicker';
import { useHistory } from 'react-router-dom';
import { useOrganization } from 'context/OrgContext';
import { useAddPeriod } from 'graphql/mutation/useAddPeriod';
import { useEditPeriod } from 'graphql/mutation/useEditPeriod';

type Props = {
  projectId: Project['id'];
  view: Pick<View, 'id' | 'name' | 'type'>;
  afterEdit: () => void;
  onClickDelete?: () => void;
};

export const EditMonitorView: FC<Props> = ({ projectId, view, afterEdit, onClickDelete }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const { organization: org } = useOrganization();
  const [{ data: monitorView, fetching: loadingMonitorView }] = useQryMonitorView(
    {
      id: true,
      name: true,
      type: true,
      sampleTime: true,
      yDomain: {
        min: true,
        max: true,
        measurement: true,
      },
      period: {
        id: true,
        start: true,
        end: true,
        createdAt: true,
        updatedAt: true,
      },
      sensors: {
        id: true,
        unit: true,
        name: true,
        location: {
          id: true,
          name: true,
        },
        measurement: true,
      },
      updatedAt: true,
      autoAddNewDevices: true,
    },
    { uuid: view.id },
    {},
  );
  const [{ fetching: updatingName }, editViewName] = useEditViewName();
  const [{ fetching: updatingPeriod }, editPeriod] = useEditPeriod();
  const [{ fetching: addingPeriod }, addPeriod] = useAddPeriod();
  const history = useHistory();
  const [{ fetching: updatingView }, editView] = useEditMonitorView();
  const [{ fetching: updatingYDomain }, editYDomain] = useEditMonitorViewYDomain();
  const [{ fetching: updatingMonitorViewSensors }, editMonitorViewSensors] = useEditMonitorViewSensors();

  const loading =
    updatingName ||
    updatingMonitorViewSensors ||
    loadingMonitorView ||
    updatingPeriod ||
    updatingView ||
    addingPeriod ||
    updatingYDomain;

  const save = useCallback(
    async (
      name: View['name'],
      projectSensorIds: ProjectSensor['id'][],
      period?: PeriodArray,
      sampleTime?: SampleTime,
      autoAddNewDevices?: boolean,
      yDomain?: DomainView<number | undefined | null>[],
    ) => {
      if (monitorView && org) {
        try {
          let updated = false;
          if (name !== view.name) {
            await editViewName({
              name,
              viewId: view.id,
            });
            updated = true;
          }
          const currentSensor = monitorView.sensors.map((sensor) => sensor.id);
          const changedSensors = JSON.stringify(currentSensor) !== JSON.stringify(projectSensorIds);
          if (changedSensors) {
            await editMonitorViewSensors({
              viewId: view.id,
              projectSensorIds: projectSensorIds,
            });
            updated = true;
          }
          if (period && period[0]) {
            const currentPeriod = monitorView?.period;
            if (currentPeriod) {
              if (!isSamePeriod(currentPeriod, period)) {
                await editPeriod({
                  periodId: currentPeriod.id,
                  data: {
                    start: period[0].toISOString(),
                    end: period[1]?.toISOString(),
                  },
                });
                updated = true;
              }
            } else {
              await addPeriod({
                organizationId: org.id,
                viewId: monitorView?.id,
                data: {
                  start: period[0].toISOString(),
                  end: period[1]?.toISOString(),
                },
              });
              updated = true;
            }
          }
          if (
            (sampleTime && sampleTime !== monitorView.sampleTime) ||
            autoAddNewDevices !== monitorView.autoAddNewDevices
          ) {
            await editView({
              viewId: monitorView.id,
              data: {
                sampleTime,
                autoAddNewDevices,
              },
            });
          }
          updated = true;
          if (yDomain) {
            const parsedYDomain = yDomain
              .filter((domain) => domain.max && domain.max !== 0)
              .map((domain) => ({
                min: domain.min && +domain.min,
                max: domain.max && +domain.max,
                measurement: domain.measurement,
              }));
            const currentYDomain = monitorView.yDomain || [];
            const yDomainChanged =
              parsedYDomain.some((domain, index) => {
                const currentDomain = currentYDomain[index] || {};
                return (
                  domain.min !== currentDomain.min ||
                  domain.max !== currentDomain.max ||
                  domain.measurement !== currentDomain.measurement
                );
              }) || currentYDomain.length !== parsedYDomain.length;
            if (yDomainChanged) {
              await editYDomain({
                viewId: monitorView.id,
                data: parsedYDomain,
              });
              updated = true;
            }
          }
          if (updated) {
            snackbar.addAlert(
              t('{{view}} view saved', {
                view: t(viewConfigurations[view.type as ViewType].displayName),
              }),
              Severity.SUCCESS,
            );
          }
          if (afterEdit) {
            afterEdit();
          }
          window.location.href = history.location.pathname;
        } catch (e) {
          snackbar.addAlert(
            t('Failed to save changes to {{view}} view', {
              view: t(viewConfigurations[view.type as ViewType].displayName),
            }),
            Severity.ERROR,
          );
        }
      }
    },
    [
      monitorView,
      org,
      view.name,
      view.id,
      view.type,
      afterEdit,
      editViewName,
      editMonitorViewSensors,
      editPeriod,
      addPeriod,
      editView,
      editYDomain,
      history.location.pathname,
      snackbar,
      t,
    ],
  );

  return (
    <MonitorViewForm
      projectId={projectId}
      view={monitorView as MonitorView}
      saveLabel={t('actions.Save')}
      onSave={save}
      onClose={afterEdit}
      loading={loading}
      onClickDelete={onClickDelete}
    />
  );
};
