import { FC, useCallback } from 'react';
import { useAddAshrae2019View } from 'server/mutations/view/useAddAshrae2019View';
import { View, ProjectSensor } from 'graphql/generated';
import { Project } from 'types/project';
import { Ashrae2019Average, Ashrae2019Control } from 'types/view/ashrae2019View';
import { AshraeViewForm } from './AshraeViewForm';
import { useTranslation } from 'react-i18next';

type Props = {
  projectId: Project['id'];
  afterCreate?: (view: View) => void;
  onClose: () => void;
};

export const AddAshraeView: FC<Props> = ({ projectId, afterCreate, onClose }) => {
  const { t } = useTranslation();
  const [{ fetching: loading }, add] = useAddAshrae2019View();

  const addAshraeView = useCallback(
    async (
      name: string,
      control: Ashrae2019Control,
      average: Ashrae2019Average,
      projectSensorIds: ProjectSensor['id'][],
      autoAddNewDevices?: boolean,
    ) => {
      const ashraeView = await add({
        projectId,
        data: {
          name,
          control,
          average,
          projectSensorIds,
          autoAddNewDevices,
        },
      });
      if (afterCreate) {
        ashraeView.data?.addAshrae2019View && afterCreate(ashraeView.data.addAshrae2019View);
      }
    },
    [add, projectId, afterCreate],
  );

  return (
    <AshraeViewForm
      projectId={projectId}
      saveLabel={t('actions.Add view')}
      onSave={addAshraeView}
      onClose={onClose}
      loading={loading}
    />
  );
};
