import { Organization } from './organization';

export type LocationType = {
  id: string;
  name: string;
  color?: string;
  organization: Organization;
  /** Timestamp in ISO format */
  createdAt: string;
  /** Timestamp in ISO format */
  updatedAt: string;
};

export const defaultLocationTypes = [
  'nis/niche',
  'wall',
  'floor',
  'ceiling',
  'display case',
  'carton box',
  'packaged',
  'microclimate',
];

export type Location = {
  id: string;
  name: string;
  height?: string;
  description?: string;
  type?: LocationType;
  organization: Organization;
  colorIndex?: number | null;
  /** Timestamp in ISO format */
  createdAt: string;
  /** Timestamp in ISO format */
  updatedAt: string;
};

export type LocationReference = {
  id: string;
  location: Location;
  /** Timestamp in ISO format */
  createdAt: string;
  /** Timestamp in ISO format */
  updatedAt: string;
};
