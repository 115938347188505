import { FC, lazy, Suspense, useContext, useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { DeviceErrorScreen } from 'components/errorScreens/DeviceErrorScreen';
import { LocationErrorScreen } from 'components/errorScreens/LocationErrorScreen';
import { ProjectErrorScreen } from 'components/errorScreens/ProjectErrorScreen';
import { SensorErrorScreen } from 'components/errorScreens/SensorErrorScreen';
import { ViewErrorScreen } from 'components/errorScreens/ViewErrorScreen';
import { Loader } from 'components/loader/loader';
import { MainContent } from 'components/MainContent';
import { RouteValidGuard } from 'components/RouteValidGuard';
import { AuthContext } from 'context/AuthContext';
import { DrawerProvider } from 'context/DrawerContext';
import { OrgProvider } from 'context/OrgContext';
import { PeriodProvider } from 'context/PeriodContext';
import { ProjectProvider } from 'context/ProjectContext';
import { UserProvider } from 'context/UserContext';
import { queryDevice } from 'graphql/query/queryDevice';
import { queryLocation } from 'graphql/query/queryLocation';
import { queryProject } from 'graphql/query/queryProject';
import { querySensor } from 'graphql/query/querySensor';
import { queryView } from 'graphql/query/useQryView';
import Update from 'pages/Update';
import Users from 'pages/organization/Users';
import ProjectNotes from 'pages/project/notes/ProjectNotes';
import environment from 'utils/environment';

import type { History } from 'history';
import '@carbon/react/scss/components/tabs/_index.scss';
import RuleProjectSensorsDetail from 'pages/project/views/alert/components/RuleProjectSensorsDetail';
import { useQryProject } from 'graphql/generated';
import { isEmpty } from 'lodash';
import { NoteProvider } from 'context/NoteContext';

const Addons = lazy(() => import('./pages/addons/Addons'));
const Projects = lazy(() => import('./pages/projects/Projects'));
const NotFound = lazy(() => import('./pages/404'));
const Views = lazy(() => import('./pages/project/views/Views'));
const View = lazy(() => import('./pages/project/views/View'));
const ViewDetail = lazy(() => import('./pages/project/views/ViewDetail'));
const ProjectSensors = lazy(() => import('./pages/project/sensors/ProjectSensors'));
const ProjectNotifications = lazy(() => import('./pages/project/notifications/Notifications'));
const ProjectSettings = lazy(() => import('./pages/project/settings/Settings'));
const SensorDetail = lazy(() => import('./pages/sensors/SensorDetail'));
const Devices = lazy(() => import('./pages/devices/Devices'));
const Device = lazy(() => import('./pages/devices/Device'));
const Location = lazy(() => import('./pages/locations/Location'));
const Notifications = lazy(() => import('./pages/notifications/Notifications'));
const Organization = lazy(() => import('./pages/organization/Organization'));
const InvitationWhenLoggedIn = lazy(() => import('./pages/invitation/InvitationWhenLoggedIn'));
const Profile = lazy(() => import('./pages/profile/Profile'));
const ProjectAlertResponse = lazy(() => import('./pages/project/notifications/alert/AlertResponse'));
const Locations = lazy(() => import('./pages/locations/Locations'));
const Calibration = lazy(() => import('./pages/calibration/CalibrationSensorDetail'));

const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/register/Register'));
const LoginCallback = lazy(() => import('./pages/LoginCallback'));
const PasswordReset = lazy(() => import('./pages/PasswordReset'));
const Invitation = lazy(() => import('./pages/invitation/Invitation'));

type Props = {
  history: History;
};

export const Charp: FC<Props> = ({ history }) => {
  const { isLoggedIn } = useContext(AuthContext);

  // Checks that is a valid UUID
  const projectUuidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
  const match = window.location.pathname.match(new RegExp(`/projects/(${projectUuidPattern.source})`));
  const projectId = match?.[1] ?? '';

  useEffect(() => {
    projectId && localStorage.setItem('projectId', projectId);
  }, [projectId]);

  const isProjectRoute = Boolean(match);
  const [{ data: project, fetching: loadingShareableProject }] = useQryProject(
    { id: true, isShareable: true },
    { uuid: projectId },
    { pause: !isProjectRoute || isEmpty(projectId) },
  );

  if (environment.app.update) {
    return <Update />;
  }

  if (isProjectRoute && project?.isShareable && !loadingShareableProject && !isLoggedIn) {
    return (
      <Router history={history}>
        <NoteProvider>
          <OrgProvider>
            <ProjectProvider>
              <MainContent>
                <Suspense fallback={<Loader />}>
                  <Switch>
                    <Route exact path="/404" component={NotFound} />
                    <Route
                      path="/projects/:projectId"
                      render={(props) => (
                        <RouteValidGuard
                          {...props}
                          parameter={'projectId'}
                          query={queryProject}
                          errorPage={<ProjectErrorScreen />}
                        >
                          <Route exact path="/projects/:projectId" component={Views} />
                          <Route
                            path="/projects/:projectId/views/:viewId"
                            render={(props) => (
                              <RouteValidGuard
                                {...props}
                                parameter={'viewId'}
                                query={queryView}
                                errorPage={<ViewErrorScreen />}
                              >
                                <PeriodProvider>
                                  <Route exact path="/projects/:projectId/views/:viewId" component={View} />
                                  <Route
                                    exact
                                    path="/projects/:projectId/views/:viewId/detail/:elementId"
                                    component={ViewDetail}
                                  />
                                </PeriodProvider>
                              </RouteValidGuard>
                            )}
                          />
                        </RouteValidGuard>
                      )}
                    />
                  </Switch>
                </Suspense>
              </MainContent>
            </ProjectProvider>
          </OrgProvider>
        </NoteProvider>
      </Router>
    );
  }

  return isLoggedIn ? (
    <UserProvider>
      <NoteProvider>
        <OrgProvider>
          <Router history={history}>
            {/* ProjectProvider uses useRouteMatch */}
            <ProjectProvider>
              <DrawerProvider>
                <MainContent>
                  <Suspense fallback={<Loader />}>
                    <Switch>
                      <Route exact path="/404" component={NotFound} />
                      <Route exact path="/projects" component={Projects} />
                      <Route
                        path="/projects/:projectId"
                        render={(props) => (
                          <RouteValidGuard
                            {...props}
                            parameter={'projectId'}
                            query={queryProject}
                            errorPage={<ProjectErrorScreen />}
                          >
                            <Route exact path="/projects/:projectId" component={Views} />
                            <Route
                              path="/projects/:projectId/views/:viewId"
                              render={(props) => (
                                <RouteValidGuard
                                  {...props}
                                  parameter={'viewId'}
                                  query={queryView}
                                  errorPage={<ViewErrorScreen />}
                                >
                                  <PeriodProvider>
                                    <Route exact path="/projects/:projectId/views/:viewId" component={View} />
                                    <Route
                                      exact
                                      path="/projects/:projectId/views/:viewId/detail/:elementId"
                                      component={ViewDetail}
                                    />
                                  </PeriodProvider>
                                </RouteValidGuard>
                              )}
                            />
                            <Route exact path="/projects/:projectId/notes" component={ProjectNotes} />
                            <Route exact path="/projects/:projectId/sensors" component={ProjectSensors} />
                            <Route exact path="/projects/:projectId/notifications" component={ProjectNotifications} />
                            <Route
                              path="/projects/:projectId/notifications"
                              render={({ match: { url } }) => (
                                <Switch>
                                  <Route exact path={`${url}/rules`} component={ProjectNotifications} />
                                  <Route exact path={`${url}/rules/:ruleId`} component={RuleProjectSensorsDetail} />
                                  <Route exact path={`${url}/:alertId`} component={ProjectAlertResponse} />
                                </Switch>
                              )}
                            />
                            <Route exact path="/projects/:projectId/settings" component={ProjectSettings} />
                            <Route
                              exact
                              path="/projects/:projectId/settings/measurements"
                              component={ProjectSettings}
                            />
                            <Route
                              exact
                              path="/projects/:projectId/settings/project-members"
                              component={ProjectSettings}
                            />
                          </RouteValidGuard>
                        )}
                      />
                      <Route exact path="/devices" component={Devices} />
                      <Route exact path="/devices/gateways" component={Devices} />
                      <Route exact path="/devices/calibrations" component={Devices} />
                      <Route exact path="/devices/gateways/:deviceId" component={Device} />
                      <Route exact path="/devices/calibrations/:calibrationId" component={Calibration} />
                      <Route
                        path="/devices/:deviceId"
                        render={(props) => (
                          <RouteValidGuard
                            {...props}
                            parameter={'deviceId'}
                            query={queryDevice}
                            errorPage={<DeviceErrorScreen />}
                          >
                            <Route exact path="/devices/:deviceId" component={Device} />
                            <Route
                              path="/devices/:deviceId/sensors/:sensorId"
                              render={(props) => (
                                <RouteValidGuard
                                  {...props}
                                  parameter={'sensorId'}
                                  query={querySensor}
                                  errorPage={<SensorErrorScreen />}
                                >
                                  <PeriodProvider>
                                    <Route exact path="/devices/:deviceId/sensors/:sensorId" component={SensorDetail} />
                                  </PeriodProvider>
                                </RouteValidGuard>
                              )}
                            />
                          </RouteValidGuard>
                        )}
                      />
                      <Route exact path="/locations" component={Locations} />
                      <Route
                        path="/locations"
                        render={({ match: { url } }) => (
                          <Switch>
                            <Route exact path={`${url}/rooms`} component={Locations} />
                            <Route exact path={`${url}/floorplan`} component={Locations} />
                            <Route
                              path={`${url}/:locationId`}
                              render={(props) => (
                                <RouteValidGuard
                                  {...props}
                                  parameter={'locationId'}
                                  query={queryLocation}
                                  errorPage={<LocationErrorScreen />}
                                >
                                  <Location />
                                </RouteValidGuard>
                              )}
                            />
                          </Switch>
                        )}
                      />
                      <Route exact path="/alerts" component={Notifications} />
                      <Route exact path="/account/billing" component={Organization} />
                      <Route exact path="/account/members" component={Users} />
                      <Route exact path="/account/add-ons" component={Addons} />
                      <Route exact path="/account" component={Profile} />
                      <Route path="/invitation" component={InvitationWhenLoggedIn} />
                      <Redirect to="/projects" />
                    </Switch>
                  </Suspense>
                </MainContent>
              </DrawerProvider>
            </ProjectProvider>
          </Router>
        </OrgProvider>
      </NoteProvider>
    </UserProvider>
  ) : (
    <Router history={history}>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact path="/register" component={Register} />
          <Route path="/invitation" component={Invitation} />
          <Route path="/login/callback" component={LoginCallback} />
          <Route path="/password-reset" component={PasswordReset} />
          <Route path="/*" component={Login} />
        </Switch>
      </Suspense>
    </Router>
  );
};
