import { MutationRemoveRefFromNoteArgs } from 'graphql/generated';
import { useMutation } from 'urql';

const REMOVE_REF_TO_NOTE_KEY = 'removeRefFromNote';

const ADD_REF_TO_NOTE = `
  mutation ($data: NoteRefInput!) {
    ${REMOVE_REF_TO_NOTE_KEY}(data: $data) {
      id
    }
  }
`;

export const useRemoveRefToNote = () => {
  return useMutation<MutationRemoveRefFromNoteArgs>(ADD_REF_TO_NOTE);
};
