import { animated } from '@react-spring/web';
import { Box } from 'components/layout/Box';
import { FC, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import type { LayoutProps } from 'styled-system';
import { layout } from 'styled-system';
import { MenuLink } from './MenuLink';
import { useTranslation } from 'react-i18next';
import { ProjectContext } from 'context/ProjectContext';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Flex } from 'components/layout/Flex';
import useIsMobile from 'hooks/useIsMobile';
import { colors } from 'theme/colors';
import { auth } from 'utils/Authentication';
import { useOrganization } from 'context/OrgContext';

const ProjectFlex = styled(Flex)<{ $isActive: boolean }>`
  &:hover {
    ${(p) => (p.$isActive ? 'background-color: rgba(255, 255, 255, 0.25); color: white;' : '')};
  }
  padding: 0.7rem;
  border-radius: 0.25rem;
  background-color: ${(p) => (p.$isActive ? 'rgba(255,255,255,0.15)' : 'transparent')};
  border: ${(p) => (p.$isActive ? `1px solid ${colors.brandBlue[600]}` : '1px solid transparent')};
`;

const Container = animated(styled.nav<LayoutProps>`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-x: hidden;
  overflow-y: auto;
  ${layout}
  @media (max-width: 600px) {
    height: auto;
    flex-grow: 1;
  }
`);

const InnerContainer = styled.div`
  padding: 1rem 2rem;
`;

type PanelContainerProps = {
  flexGrow: number;
};

const PanelContainer = styled.div<PanelContainerProps>`
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-grow: ${(props) => props.flexGrow};
  overflow: visible;
`;

const MenuContainer = styled(Container)`
  min-height: 94vh !important;
  height: 94vh !important;
`;

const AnimatedPanelContainer = animated(PanelContainer);

type PanelProps = {
  justifyContent?: 'end';
};

const Panel = animated(styled.div<PanelProps>`
  width: 100%;
  padding: 1rem 2rem;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${(props) => props.justifyContent && `justify-content: ${props.justifyContent};`}
`);

export enum LeafMenuActionType {
  button = 'button',
  route = 'route',
}

type ButtonLeafMenuAction = {
  type: LeafMenuActionType.button;
  value: () => void;
};

type RouteLeafMenuAction = {
  type: LeafMenuActionType.route;
  value: string;
};

type LeafMenuAction = ButtonLeafMenuAction | RouteLeafMenuAction;

export type MenuNode = {
  label: string;
  indent?: boolean;
  icon?: React.ReactNode;
  isSubMenu?: boolean;
  dropdownItems?: MenuNode[];
  action: LeafMenuAction;
};

export type MenuType = {
  label: string;
  color: string;
  showLabel?: boolean;
  logo: React.ReactNode;
  top: MenuNode[];
  middle: MenuNode[];
  bottom: MenuNode[];
};

type Props = {
  rootMenu: MenuType;
  setScrollPosition?: (scrollPosition: number) => void;
  scrollPosition?: number;
} & LayoutProps;

const CustomBox = styled.div`
  color: ${colors.brandBlue[600]};
  margin-right: auto;
  margin-top: 1rem;
  margin-left: initial;
  padding: 0.7rem;
  margin-bottom: 0px;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  @media (max-width: 600px) {
    font-size: 140%;
  }
`;

const ProjectBox = styled(Box)`
  font-weight: 500;
  font-size: 1.2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  @media (max-width: 600px) {
    font-size: 140%;
  }
`;

export const Menu: FC<Props> = ({ rootMenu, width, setScrollPosition, scrollPosition, ...styleProps }) => {
  const currentLocation = useLocation();
  const { t } = useTranslation();
  const projectsRoute = '/projects';
  const isAuthenticated = auth.isAuthenticated();
  const { organization } = useOrganization();

  const activeNode: MenuNode | undefined = useMemo(() => {
    const nodes: MenuNode[] = [];
    nodes.push(...rootMenu.top, ...rootMenu.middle, ...rootMenu.bottom);

    nodes.sort((a, b) => b.action.value.length - a.action.value.length);

    for (const node of nodes) {
      if (node.action.type === LeafMenuActionType.route) {
        if (currentLocation.pathname.startsWith(node.action.value)) {
          return node;
        }
      }
    }
  }, [currentLocation.pathname, rootMenu]);
  const { project } = useContext(ProjectContext);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      setScrollPosition?.(containerRef.current.scrollTop);
    }
  }, [setScrollPosition]);

  useEffect(() => {
    const container = containerRef.current;
    if (scrollPosition && container) {
      container.scrollTop = scrollPosition;
    }
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, scrollPosition]);

  const isMobile = useIsMobile();

  return (
    <MenuContainer {...styleProps} width={width} ref={containerRef}>
      {!isMobile && <InnerContainer>{rootMenu.logo}</InnerContainer>}
      <>
        {/* Top panel */}
        <AnimatedPanelContainer flexGrow={1}>
          <Panel>
            <CustomBox>{t('Project')}</CustomBox>
            {project?.name &&
              (isAuthenticated ? (
                <Link to={projectsRoute}>
                  <ProjectFlex
                    $isActive={currentLocation.pathname === projectsRoute}
                    justifyContent="space-between"
                    marginBottom="1rem"
                    alignItems="center"
                    color="grey.200"
                  >
                    <ProjectBox title={project.name}>{project.name}</ProjectBox>
                    <MdKeyboardArrowRight />
                  </ProjectFlex>
                </Link>
              ) : (
                <ProjectFlex
                  $isActive={false}
                  justifyContent="space-between"
                  marginBottom="1rem"
                  alignItems="center"
                  color="grey.200"
                >
                  <ProjectBox title={project.name}>{project.name}</ProjectBox>
                  <MdKeyboardArrowRight />
                </ProjectFlex>
              ))}
            {rootMenu.top.map((node, index) => (
              <MenuLink key={index} active={node === activeNode} node={node} />
            ))}
          </Panel>
        </AnimatedPanelContainer>
        {isAuthenticated && (
          <>
            {/* Middle panel */}
            <AnimatedPanelContainer flexGrow={1}>
              <Panel>
                <CustomBox>{t('Organization')}</CustomBox>
                {rootMenu.middle.map((node, index) => (
                  <MenuLink key={index} active={node === activeNode} node={node} />
                ))}
              </Panel>
            </AnimatedPanelContainer>
            {/* Bottom panel */}
            <AnimatedPanelContainer flexGrow={0}>
              <Panel justifyContent="end">
                {project?.id && <CustomBox>{organization?.name}</CustomBox>}
                {rootMenu.bottom.map((node, index) =>
                  isMobile ? (
                    <div key={index} style={{ marginBottom: '5rem' }}>
                      <MenuLink active={node === activeNode} node={node} />
                    </div>
                  ) : (
                    <MenuLink key={index} active={node === activeNode} node={node} />
                  ),
                )}
              </Panel>
            </AnimatedPanelContainer>
          </>
        )}
      </>
    </MenuContainer>
  );
};
