import { Dialog } from 'components/Dialog';
import { Text } from 'components/typography';
import { CharpDeviceInput } from 'pages/devices/AddCharpDevice';
import { CharpMultipleDevicesInput } from 'pages/devices/AddBulkCharpDevices';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RequireAtLeastOne } from 'utils/types';

type CreateDialogProps = RequireAtLeastOne<
  {
    deviceData?: CharpDeviceInput & { organization?: string };
    multipleDevicesData?: CharpMultipleDevicesInput & { organization?: string };
    onCancel: () => void;
    onConfirm: () => void;
  },
  'deviceData' | 'multipleDevicesData'
>;

const DeviceCreateConfirmationDialog: FC<CreateDialogProps> = ({
  deviceData,
  multipleDevicesData,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('Confirm device creation')}
      description={
        <Text>
          <ul>
            <li style={{ fontWeight: 'bold' }}>
              {t('Organization')}: {deviceData?.organization ?? multipleDevicesData?.organization}
            </li>
            <li>
              {t('Type')}: {deviceData?.deviceType ?? multipleDevicesData?.deviceType}
            </li>
            {multipleDevicesData?.deviceEUIs ? (
              <>
                <li>
                  {t('DevEUIs')}: {multipleDevicesData?.deviceEUIs.join(', \n')}
                </li>
              </>
            ) : (
              <>
                <li>
                  {t('Name')}: {deviceData?.deviceName}
                </li>
                <li>
                  {t('DevEUI')}: {deviceData?.deviceEUI}
                </li>
              </>
            )}
          </ul>
        </Text>
      }
      maxWidth="640px"
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export { DeviceCreateConfirmationDialog };
