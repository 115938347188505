import { CumulativeSumLightHoursView } from 'types/view/cumulativeSumLightHoursView';
import { ProjectSensor } from 'graphql/generated';
import { useMutation } from 'urql';

const EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS_KEY = 'editCumulativeSumLightHoursSensors';

const EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS = `
  mutation ($viewId: String!, $data: EditViewWithSensorsInput!) {
    ${EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS_KEY}(uuid: $viewId, data: $data) {
      id
      name
      type
      sensors {
        id
        name
        unit
        location {
          id
          name
        }
        sensorId
        measurement
        updatedAt
      }
      updatedAt
    }
  }
`;

type MutVarEditCumulativeSumLightHoursViewSensors = {
  viewId: string;
  data: {
    projectSensorIds: string[];
    autoAddNewDevices?: boolean;
  };
};

type MutProjectSensor = Pick<
  ProjectSensor,
  'id' | 'name' | 'unit' | 'location' | 'sensorId' | 'measurement' | 'updatedAt'
>;

type MutResEditCumulativeSumLightHoursViewSensors = Pick<
  CumulativeSumLightHoursView,
  'id' | 'name' | 'type' | 'updatedAt'
> & {
  sensors: MutProjectSensor[];
};

export const useEditCumulativeLightViewSensors = () => {
  return useMutation<MutResEditCumulativeSumLightHoursViewSensors, MutVarEditCumulativeSumLightHoursViewSensors>(
    EDIT_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_SENSORS,
  );
};
