import { useMutation } from 'urql';
import { Sensitivity, LightBudgetView } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';

const ADD_LIGHT_BUDGET_VIEW_KEY = 'addLightBudgetView';

const ADD_LIGHT_BUDGET_VIEW = `
  mutation ($projectId: String!, $data: AddLightBudgetViewInput!) {
    ${ADD_LIGHT_BUDGET_VIEW_KEY}(uuid: $projectId, data: $data) {
      id
      name
      type
      updatedAt
    }
  }
`;

type MutVarAddLightBudgetView = {
  projectId: string;
  data: {
    name: string;
    projectSensorIds: string[];
    period: {
      start: string;
      end?: string | null;
    };
    control: {
      targetLifetime: number;
      roleOfColor: number;
      exposureTime: number;
      uv: boolean;
      sensitivity: Sensitivity;
    };
    autoAddNewDevices?: boolean;
  };
};

type MutResAddLightBudgetView = DefaultQueryResponse<
  typeof ADD_LIGHT_BUDGET_VIEW_KEY,
  Pick<LightBudgetView, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId'>
>;

export const useAddLightBudgetView = () => {
  return useMutation<MutResAddLightBudgetView, MutVarAddLightBudgetView>(ADD_LIGHT_BUDGET_VIEW);
};
