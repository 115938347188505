import { useMutation } from 'urql';
import { DefaultQueryResponse } from 'graphql/query/default';

const EDIT_CALIBRATION_AND_SENSORS_KEY = 'editCalibrationAndCalibrationSensors';

const EDIT_CALIBRATION_AND_SENSORS = `
  mutation ($uuid: String!, $data: CalibrationAndCalibrationSensorsInput!) {
    ${EDIT_CALIBRATION_AND_SENSORS_KEY}(uuid: $uuid, data: $data) {
      id
      name
      formula
      createdAt
      updatedAt
      calibrationSensors {
        id
        calibrationId
        sensorId
        startDate
        endDate
        createdAt
        updatedAt
      }
    }
  }
`;

type CalibrationInput = {
  name: string;
  formula: string;
  organizationId: string;
};

type CalibrationSensorInput = {
  sensorIds: string[];
  startDate?: string;
  endDate?: string;
};

type EditCalibrationAndCalibrationSensorsInput = {
  calibration: CalibrationInput;
  calibrationSensor: CalibrationSensorInput;
};

type MutVarEditCalibrationAndSensors = {
  uuid: string;
  data: EditCalibrationAndCalibrationSensorsInput;
};

type MutResEditCalibrationAndSensors = DefaultQueryResponse<
  typeof EDIT_CALIBRATION_AND_SENSORS_KEY,
  {
    id: string;
    name: string;
    formula: string;
    createdAt: string;
    updatedAt: string;
    calibrationSensors: {
      id: string;
      sensorId: string;
      startDate: string;
      endDate: string;
      createdAt: string;
      updatedAt: string;
    }[];
  }
>;

export const useEditCalibration = () => {
  return useMutation<MutResEditCalibrationAndSensors, MutVarEditCalibrationAndSensors>(EDIT_CALIBRATION_AND_SENSORS);
};
