import { useMutation } from 'urql';
import { CumulativeSumLightHoursView } from 'types/view/cumulativeSumLightHoursView';
import { DefaultQueryResponse } from 'graphql/query/default';

const ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_KEY = 'addCumulativeSumLightHoursView';

const ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW = `
  mutation ($projectId: String!, $data: AddViewWithSensorsInput!) {
    ${ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_KEY}(uuid: $projectId, data: $data) {
      id
      name
      type
      projectId
      updatedAt
    }
  }
`;

type MutVarAddCumulativeSumLightHoursView = {
  projectId: string;
  data: {
    name: string;
    projectSensorIds: string[];
    autoAddNewDevices?: boolean;
  };
};

export const useAddCumulativeSumLightHoursView = () => {
  return useMutation<
    DefaultQueryResponse<
      typeof ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW_KEY,
      Pick<CumulativeSumLightHoursView, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId'>
    >,
    MutVarAddCumulativeSumLightHoursView
  >(ADD_CUMULATIVE_SUM_LIGHT_HOURS_VIEW);
};
