import { AddLocationDrawer } from 'components/AddLocationDrawer';
import { AddRoomDrawer } from 'components/AddRoomDrawer';
import { DeleteDeviceDataDrawer } from 'components/DeleteDeviceDataDrawer';
import { DocumentationDrawer } from 'components/DocumentationDrawer';
import { DownloadDeviceDataDrawer } from 'components/DownloadDeviceDataDrawer';
import { DownloadSensorDataDrawer } from 'components/DownloadSensorDataDrawer';
import { EditLocationDrawer } from 'components/EditLocationDrawer';
import { EditRoomDrawer } from 'components/EditRoomDrawer';
import { ManualUploadDrawer } from 'components/manualUpload/ManualUploadDrawer';
import { NoteDrawer } from 'components/notes/NoteDrawer';
import { useGuard } from 'hooks/useGuard';
import { useSearchParameterObjectState, useSearchParameterStringState } from 'hooks/useSearchParameterState';
import { pick } from 'lodash-es';
import { AddonDrawer } from 'pages/addons/components/AddonDrawer';
import { DataStreamDrawer } from 'pages/dataStreams/components/DataStreamDrawer';
import { AddDeviceDrawer } from 'pages/devices/AddDeviceDrawer';
import { EditOrganizationUserDrawer } from 'pages/organization/EditOrganizationUserDrawer';
import { EditProjectUserDrawer } from 'pages/project/team/components/EditProjectUserDrawer';
import { InviteUserDrawer } from 'pages/organization/InviteUserDrawer';
import { ProfileEditDrawer } from 'pages/profile/components/ProfileEditDrawer';
import { NotificationConfigurationDrawer } from 'pages/project/notifications/components/NotificationConfigurationDrawer';
import { CreateProjectSensorDrawer } from 'pages/project/sensors/components/CreateProjectSensorDrawer';
import { ProjectUserDrawer } from 'pages/project/team/components/ProjectUserDrawer';
import { AlertRuleDrawer } from 'pages/project/views/alert/addRule/AlertRuleDrawer';
import { ViewCreationWizardDrawer } from 'pages/project/views/components/ViewCreationWizardDrawer';
import { ViewEditDrawer } from 'pages/project/views/components/ViewEditDrawer';
import { AddProjectDrawer } from 'pages/projects/components/AddProjectDrawer';
import { AddCalibrationDrawer } from 'pages/calibration/AddCalibrationDrawer';
import {
  ComponentProps,
  createContext,
  createElement,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
  VFC,
} from 'react';

export type DrawerComponent<DrawerArgs> = VFC<DrawerArgs & DrawerComponentProps> & {
  isShareable?: boolean;
  /** Drawer component props with these keys will get stored in the search query paremeters. Be careful when handling sensitive information. */
  shareableArgs?: [keyof DrawerArgs];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const drawerRegistration: { [drawerId: string]: DrawerComponent<any> } = {
  note: NoteDrawer,
  addon: AddonDrawer,
  dataStream: DataStreamDrawer,
  inviteUser: InviteUserDrawer,
  editOrganizationUser: EditOrganizationUserDrawer,
  editProjectUser: EditProjectUserDrawer,
  editProfile: ProfileEditDrawer,
  notificationConfig: NotificationConfigurationDrawer,
  createProjectSensor: CreateProjectSensorDrawer,
  addProject: AddProjectDrawer,
  projectUser: ProjectUserDrawer,
  alertRule: AlertRuleDrawer,
  createViewWizard: ViewCreationWizardDrawer,
  editView: ViewEditDrawer,
  manualUpload: ManualUploadDrawer,
  addDevice: AddDeviceDrawer,
  addCalibration: AddCalibrationDrawer,
  addLocation: AddLocationDrawer,
  editLocation: EditLocationDrawer,
  documentation: DocumentationDrawer,
  addRoom: AddRoomDrawer,
  editRoom: EditRoomDrawer,
  downloadDeviceData: DownloadDeviceDataDrawer,
  deleteDeviceData: DeleteDeviceDataDrawer,
  downloadSensorData: DownloadSensorDataDrawer,
};

// we must narrow to string because keyof operator resolves to type 'string|number'
type DrawerType = string & keyof typeof drawerRegistration;
type DrawerRegistration = typeof drawerRegistration;

type DrawerComponentArgs<T extends DrawerType> = Omit<
  ComponentProps<DrawerRegistration[T]>,
  keyof DrawerComponentProps
>;

type DrawerRequest<DrawerId extends DrawerType> = {
  drawerId: DrawerId;
  args: DrawerComponentArgs<DrawerId>;
};

export type DrawerComponentProps = {
  /** Is the guard active. */
  guarded: boolean;
  /** Activate or deactivate a guard for this drawer. */
  setGuarded: (guardActive: boolean) => void;
  /** Is there a request for this drawer to close pending? */
  closePending: boolean;
  /** Request a graceful closing of this drawer. */
  requestClose: (ignoreGuard?: boolean) => void;
  /** Accept a close request for this drawer. */
  acceptClose: () => void;
  /** Reject a close request for this drawer. */
  denyClose: () => void;
};

type KeyedDrawerRequest = DrawerRequest<DrawerType> & { key: number };

type DrawerContextType = {
  drawerChildren?: ReactNode;
  drawerRequest?: KeyedDrawerRequest;
  requestDrawer: <DrawerId extends DrawerType>(
    drawerId: DrawerId | undefined,
    args?: DrawerComponentArgs<DrawerId>,
    replace?: boolean,
    force?: boolean,
  ) => void;
};

export const DrawerContext = createContext<DrawerContextType>({
  requestDrawer: () => {
    throw new Error('Not in DrawerContext');
  },
});

function useDrawerState(
  initialKey: number,
): [drawer: KeyedDrawerRequest | undefined, setDrawer: (drawer: KeyedDrawerRequest | undefined) => void] {
  const [drawerId, setDrawerId] = useState<DrawerType>();
  const [shareableDrawerId, setShareableDrawerId] = useSearchParameterStringState<DrawerType>('drawer');

  const [shareableArgs, setShareableArgs] =
    useSearchParameterObjectState<Partial<DrawerComponentArgs<DrawerType> | undefined>>('args');
  const [args, setArgs] = useState<Partial<DrawerComponentArgs<DrawerType>>>();
  const [key, setKey] = useState<number | undefined>(initialKey);

  const value = useMemo(() => {
    const actualDrawerId = drawerId ?? shareableDrawerId;
    if (actualDrawerId && key !== undefined) {
      const actualArgs = { ...shareableArgs, ...args };
      return { args: actualArgs, drawerId: actualDrawerId, key };
    } else {
      return undefined;
    }
  }, [shareableDrawerId, key, drawerId, shareableArgs, args]);

  const setValue = useCallback(
    (value?: KeyedDrawerRequest) => {
      if (value !== undefined) {
        const drawerComponent = drawerRegistration[value.drawerId];
        const drawerIsShareable = drawerComponent.isShareable ?? false;
        const drawerShareableArgs = drawerIsShareable ? drawerComponent.shareableArgs ?? ([] as string[]) : [];
        const drawerArgs = Object.keys(value.args).filter((a) => !drawerShareableArgs.includes(a)) as string[];
        setDrawerId(value.drawerId);
        setShareableDrawerId(drawerIsShareable ? value.drawerId : undefined);
        const newShareableArgs = pick(value.args, drawerShareableArgs);
        const newShareableArgsHasProperties = Object.keys(newShareableArgs).length > 0;
        setShareableArgs(newShareableArgsHasProperties ? newShareableArgs : undefined);
        setArgs(pick(value.args, drawerArgs));
        setKey(value.key);
      } else {
        setDrawerId(undefined);
        setShareableDrawerId(undefined);
        setShareableArgs(undefined);
        setArgs(undefined);
        setKey(undefined);
      }
    },
    [setShareableArgs, setShareableDrawerId],
  );

  return [value, setValue];
}

export const DrawerProvider: FC = (props) => {
  const key = useRef(0);
  const [drawer, setDrawer] = useDrawerState(key.current);

  const { guarded, setGuarded, nextValue, requestNext, acceptNext, denyNext } = useGuard(setDrawer);

  const requestDrawer = useCallback(
    (drawerId, args = {}, replace = false, force = false) => {
      if (!replace) {
        key.current++;
      }
      if (drawerId === undefined) {
        requestNext(undefined, force);
      } else {
        if (drawer && drawer.drawerId === drawerId && args.noteId && drawer.args.noteId === args.noteId) {
          setGuarded(false);
          drawer.args.onClose?.();
          requestNext(undefined, true);
        }

        setGuarded(false);
        requestNext({ drawerId, args, key: key.current }, force);
      }
    },
    [drawer, requestNext, setGuarded],
  );

  const drawerChildren = useMemo(() => {
    if (drawer) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return createElement(drawerRegistration[drawer.drawerId] as any, {
        ...drawer.args,
        // when the key changes, the drawer gets remounted
        key: drawer.key,
        guarded,
        setGuarded,
        closePending: nextValue !== undefined,
        requestClose: (ignoreGuard?: boolean) => requestNext(undefined, ignoreGuard),
        acceptClose: acceptNext,
        denyClose: denyNext,
      });
    }
  }, [acceptNext, denyNext, drawer, guarded, nextValue, requestNext, setGuarded]);

  return (
    <DrawerContext.Provider
      value={{
        drawerChildren,
        drawerRequest: drawer,
        requestDrawer,
      }}
      {...props}
    />
  );
};
