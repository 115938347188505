import shallow from 'zustand/shallow';
import { useLastDefinedValue } from 'hooks/useLastDefinedValue';
import { useGetProjectSensorPages } from 'server/queries/useGetProjectSensorPages';
import { useFilterStore } from 'state/filterStore';
import { usePaginationStore } from 'state/paginationStore';
import { MonitorView, TemperatureHumidityView, View } from 'graphql/generated';
import { Location } from 'types/location';
import { Project } from 'types/project';
import { LightView } from 'pages/project/views/components/CumulativeLightViewForm';
import { LightBudgetView } from 'types/view/lightBudgetView';
import { OptionType } from 'types/select';

export type ViewTypes = MonitorView | View | LightView | LightBudgetView | TemperatureHumidityView | undefined;

export const useProjectSensorLocation = ({ projectId }: { projectId: Project['id'] }) => {
  const [limit, offset, setOffset] = usePaginationStore(
    (state) => [state.monitorViewForm.limit, state.monitorViewForm.offset, state.monitorViewForm.setOffset],
    shallow,
  );

  const [filter, setFilter] = useFilterStore(
    (state) => [state.projectSensorsForm.values, state.projectSensorsForm.filter],
    shallow,
  );

  const { data, fetching: loadingProjectSensors } = useGetProjectSensorPages({
    uuid: projectId,
    filter,
    page: {
      limit,
      offset,
    },
  });

  const sensorPages = useLastDefinedValue(data?.project.sensorPages);

  const noSensorsYet = !filter.name && sensorPages?.count === 0;

  const onAddLocations = (
    locations: Location[],
    setSelectedLocations: (locations: Location[]) => void,
    selectedLocations: Location[],
  ) => {
    const locationMap = new Map(selectedLocations.map((location) => [location.id, location]));
    locations.forEach((location) => locationMap.set(location.id, location));
    const newLocations = [...new Set(locationMap.values())];
    setSelectedLocations(newLocations);
  };

  const onRemoveLocation = (
    location: Location,
    setSelectedLocations: (locations: Location[]) => void,
    selectedLocations: Location[],
  ) => {
    const newLocations = selectedLocations.filter((selectedLocation) => selectedLocation.id !== location.id);
    setSelectedLocations(newLocations);
  };

  const onSelectorMeasurementTypeChange = (
    selectedMeasurements: string[],
    setMeasurementTypes: (type?: OptionType[] | null | undefined) => void,
  ) => {
    setMeasurementTypes(selectedMeasurements.map((measurement) => ({ value: measurement, label: measurement })));
  };

  const loadSelectOptions = async <T>(options: T[] | undefined) => {
    const result = {
      options: options ?? [],
      hasMore: false,
    };
    return result;
  };

  const handleSearch = (search: string) => {
    setFilter({ name: search });
  };

  return {
    setOffset,
    loadingProjectSensors,
    noSensorsYet,
    onAddLocations,
    onRemoveLocation,
    onSelectorMeasurementTypeChange,
    loadSelectOptions,
    handleSearch,
    filter,
  };
};
