import { animated, useSpring } from '@react-spring/web';
import { css } from '@styled-system/css';
import { useDomRect } from 'hooks/useDomRect';
import { useHover } from 'hooks/useHover';
import { useLocalStorage } from 'hooks/useLocalStorage';
import {
  ComponentPropsWithoutRef,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from 'react';
import styled from 'styled-components';
import { Box } from './layout/Box';
import { DrawerSide, WithDrawerContext } from './layout/drawer/WithDrawer';
import { colors } from 'theme/colors';
import { ReactComponent as SideBarIcon } from 'images/sidebar-left-expand.svg';

const Container = styled(animated(Box))<{ $side: DrawerSide; $borderColor: string }>((p) => ({
  position: 'relative',
  isolation: 'isolate',
  borderColor: p.$borderColor,
  borderLeftWidth: p.$side === 'left' ? 0 : 2,
  borderRightWidth: p.$side === 'right' ? 0 : 2,
}));

const BackgroundFill = styled.div<{ $side: DrawerSide; $width: number; $backgroundColor?: string }>((p) =>
  css({
    position: 'absolute',
    left: p.$side === 'left' ? 'auto' : 0,
    right: p.$side === 'right' ? 'auto' : 0,
    top: 0,
    bottom: 0,
    height: '100%',
    width: p.$width,
    backgroundColor: p.$backgroundColor,
    zIndex: -1,
  }),
);

const Inner = styled(animated.div)<{ $side: DrawerSide; $minimazed?: boolean }>((p) => ({
  position: 'absolute',
  left: p.$side === 'left' ? 'auto' : 0,
  right: p.$side === 'right' ? 'auto' : 0,
  top: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'stretch',
  pointerEvents: p.$minimazed ? 'none' : 'auto',
}));

export const standardMainWidth = 286;
export const standardMinimizedWidth = 64;

const InnerCollapsible = styled(animated.div)<{ $side: DrawerSide }>((p) => ({
  position: 'relative',
  overflow: 'auto',
  left: p.$side === 'left' ? 'auto' : 0,
  right: p.$side === 'right' ? 'auto' : 0,
  width: `${standardMinimizedWidth}px`,
  background: colors.primary[900],
  height: '98%',
}));

const CollapsibleContainer = styled(animated.div)<{ $collapsible?: boolean }>((p) => ({
  position: 'fixed',
  bottom: '0',
  height: '49px',
  border: 'none',
  textAlign: 'center',
  alignContent: 'center',
  cursor: 'pointer',
  background: p.$collapsible ? colors.brandBlue[900] : 'transparent',
}));

type Props = ComponentPropsWithoutRef<typeof Container> & {
  children: {
    main: ReactNode;
    minimized: ReactNode;
  };
  backgroundColor?: string;
  borderColor?: string;
  /** If true, the pinnable state is shared across all drawers with pinnable={true}. If string, shared across all drawers with the same string. */
  pinnable?: boolean | string;
  initiallyMaximize?: boolean;
  initiallyPinned?: boolean;
  collapsible?: boolean;
};

export const MinimizableDrawerContent: VFC<Props> = ({
  children,
  backgroundColor = 'white',
  borderColor,
  pinnable,
  initiallyMaximize = true,
  initiallyPinned = false,
  collapsible,
  ...props
}) => {
  const { side, reserveSpace, setReserveSpace } = useContext(WithDrawerContext);

  const [containerRef, hovered] = useHover<HTMLDivElement>();
  const mainRef = useRef<HTMLDivElement>(null);
  const minimizedRef = useRef<HTMLDivElement>(null);

  const { width: mainWidth } = useDomRect(mainRef) ?? { width: 0 };
  const { width: minimizedWidth } = useDomRect(minimizedRef) ?? { width: 0 };

  const [hoveredOnce, setHoveredOnce] = useState(false);
  useLayoutEffect(() => {
    if (hovered) {
      setHoveredOnce(true);
    }
  }, [collapsible, hovered]);

  // shared state for all pinnable MinimizableDrawerContent
  const [pinnedPreference, setPinnedPreference] = useLocalStorage(
    typeof pinnable === 'string' ? pinnable : 'drawer-pinned',
    initiallyPinned,
  );

  const pinned = pinnedPreference && pinnable !== false;
  const minimized = useMemo(() => {
    const isMinimized = (!initiallyMaximize || hoveredOnce) && !pinned;
    if (collapsible) return isMinimized;
    return isMinimized && !hovered;
  }, [collapsible, hovered, hoveredOnce, initiallyMaximize, pinned]);

  useEffect(() => {
    setReserveSpace(pinned ? mainWidth : minimizedWidth);
  }, [mainWidth, minimized, minimizedWidth, pinned, reserveSpace, setReserveSpace]);

  const wrapperStyle = useSpring({
    width: minimized ? minimizedWidth : mainWidth,
    immediate: true,
  });

  const mainStyle = useSpring({
    opacity: minimized ? 0 : 1,
  });

  const minimizedStyle = useSpring({
    opacity: minimized ? 1 : 0,
  });

  return (
    <Container {...props} borderColor={borderColor} style={wrapperStyle} ref={containerRef}>
      <BackgroundFill $side={side} $width={mainWidth} $backgroundColor={backgroundColor} />
      <Inner $side={side} style={mainStyle} ref={mainRef}>
        {children.main}
      </Inner>
      {collapsible ? (
        <InnerCollapsible $side={side} style={minimizedStyle} ref={minimizedRef}>
          {children.minimized}
        </InnerCollapsible>
      ) : (
        <Inner $minimazed={true} $side={side} style={minimizedStyle} ref={minimizedRef}>
          {children.minimized}
        </Inner>
      )}
      {collapsible && (
        <CollapsibleContainer
          style={{ width: `${!pinnedPreference ? standardMinimizedWidth : standardMainWidth}px` }}
          $collapsible={collapsible}
          onClick={() => setPinnedPreference(!pinnedPreference)}
        >
          <SideBarIcon
            color="white"
            width="1.5rem"
            height="1.5rem"
            style={{ margin: 'auto', transform: pinnedPreference ? 'rotate(180deg)' : 'none' }}
          />
        </CollapsibleContainer>
      )}
    </Container>
  );
};
