import { useMutation } from 'urql';
import { DefaultQueryResponse } from 'graphql/query/default';
import { Addon, AddonData } from 'types/addon';

const EDIT_ADDON_CUSTOMERID_KEY = 'editAddonCustomerId';

const EDIT_ADDON_CUSTOMERID = `
  mutation ($addonId: String!, $customerId: String!) {
    ${EDIT_ADDON_CUSTOMERID_KEY}(uuid: $addonId, data: { item: $customerId }) {
      id
      data {
        customerId
      }
    }
  }
`;

type MutVarEditAddonCustomerId = {
  addonId: string;
  customerId: string;
};

type MutAddonData = Pick<AddonData, 'customerId'>;

type MutResEditAddonCustomerId = DefaultQueryResponse<
  typeof EDIT_ADDON_CUSTOMERID_KEY,
  Pick<Addon, 'id'> & { data: MutAddonData }
>;

export const useEditAddonCustomerId = () => {
  return useMutation<MutResEditAddonCustomerId, MutVarEditAddonCustomerId>(EDIT_ADDON_CUSTOMERID);
};
