import { Container, Content } from 'components/layout';
import { Header } from 'components/layout/Header';
import { UserTable, UserTableType } from 'components/table/UserTable';
import { H4 } from 'components/typography';
import { DrawerContext } from 'context/DrawerContext';
import { useQryInvitations, useQryUserRole } from 'graphql/generated';
import { useContext, useMemo, VFC } from 'react';
import { OrganizationUserRole } from 'types/organizationUser';
import { UserInvitationTable } from './components/UserInvitationTable';
import { Button } from 'components/buttons';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'context/OrgContext';
import { useDeleteOrganizationUser } from 'graphql/mutation/useDeleteOrganizationUser';
import { useSnackbar } from 'components/Snackbar';
import { Severity } from 'state/snackbarStore';
import { auth } from 'utils/Authentication';
import { UserContext } from 'context/UserContext';

const Users: VFC = () => {
  const { t } = useTranslation();
  const [me] = useContext(UserContext);
  const { organization: org, loading, refetchOrganization } = useOrganization();
  const [{ data: organizationUserRole }] = useQryUserRole(
    true,
    {
      orgId: org?.id ?? '',
      uuid: me?.id ?? '',
    },
    { pause: !org?.id || !me?.id },
  );
  const context = useMemo(() => ({ additionalTypenames: ['UserInvitation'] }), []);
  const [{ data: invitations }] = useQryInvitations(
    {
      id: true,
      role: true,
      email: true,
      status: true,
      updatedAt: true,
      expiration: true,
    },
    {
      context,
    },
  );

  const { requestDrawer } = useContext(DrawerContext);
  const [, deleteOrganizationUser] = useDeleteOrganizationUser();
  const snackbar = useSnackbar();

  const controls = useMemo(() => {
    if (
      organizationUserRole === OrganizationUserRole.enum.collaborator ||
      organizationUserRole === OrganizationUserRole.enum.owner
    ) {
      return (
        <Button smallPad onClick={() => requestDrawer('inviteUser')}>
          {t('actions.Invite member')}
        </Button>
      );
    }
    return undefined;
  }, [organizationUserRole, requestDrawer, t]);

  const onDeleteOrganizationUser = async (user: UserTableType) => {
    if (!org) return;
    const userDeleted = await deleteOrganizationUser({
      organizationId: org.id,
      userId: user.id,
    });
    if (userDeleted) {
      snackbar.addAlert(t('User successfully removed'), Severity.SUCCESS);
      refetchOrganization();
    } else {
      snackbar.addAlert(t('Failed to remove user'), Severity.ERROR);
    }
  };
  const isUserOwnerOrAdmin =
    organizationUserRole === OrganizationUserRole.enum.owner || auth.checkIsAdmin(me?.email ?? '');

  return (
    <Container>
      <Header title={t('Members')} controls={controls} />
      <Content loading={loading}>
        <UserTable
          org={org}
          me={me}
          users={org?.users ?? []}
          {...(isUserOwnerOrAdmin && { onDelete: onDeleteOrganizationUser })}
        />
        <H4 mt={16} mb={4}>
          {t('Invitations')}
        </H4>
        <div style={{ marginBottom: '5rem' }}>
          <UserInvitationTable invitations={invitations} />
        </div>
      </Content>
    </Container>
  );
};

export default Users;
