import { Button } from 'components/buttons';
import { Flex } from 'components/layout/Flex';
import { Dispatch, FC, SetStateAction, useCallback, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import type { LayoutProps, MarginProps } from 'styled-system';
import { layout, margin } from 'styled-system';
import { isNonEmptyString, isStringWithMaxLength } from 'utils/validation';
import { MdClose, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme/colors';
import NoteLocations from './NoteLocations';
import { Location } from 'types/location';
import { useNoteContext } from 'context/NoteContext';
import { PartialNote } from 'types/note';
import { EditorComponent } from './EditorComponent';
import { Label } from 'components/form/Label';
import NoteDayPicker from './NoteDayPicker';
import { UserContext } from 'context/UserContext';

type ContainerProps = LayoutProps & MarginProps;

const Container = styled.div<ContainerProps>(layout, margin);

type Props = {
  isNewNote?: boolean;
  note?: PartialNote;
  saving: boolean;
  loading: boolean;
  onSubmit: () => void;
  onClickClose: (useGuard?: boolean) => void;
  hasChanges: {
    title: boolean;
    description: boolean;
    start: boolean | '' | undefined;
    end: boolean | '' | null | undefined;
  };
  setSelectedLocations: Dispatch<SetStateAction<Location[]>>;
  selectedLocations: Location[];
} & ContainerProps;

export const NoteDetail: FC<Props> = ({
  isNewNote,
  note,
  saving,
  loading,
  onSubmit,
  onClickClose,
  hasChanges,
  setSelectedLocations,
  selectedLocations,
  ...props
}) => {
  const { t } = useTranslation();
  const { editedNote } = useNoteContext();
  const [user] = useContext(UserContext);
  const owner = useMemo(() => (isNewNote ? user : note?.owner), [isNewNote, user, note]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const userIsOwner = useMemo(() => user?.id !== undefined && user.id === owner?.id, [owner, user]);

  const editingAllowed = userIsOwner;
  const formIsDirty = useMemo(() => Object.values(hasChanges).includes(true), [hasChanges]);
  const valid = useMemo(
    () => ({
      title:
        editedNote?.title !== undefined &&
        isNonEmptyString(editedNote?.title ?? '') &&
        isStringWithMaxLength(editedNote?.title ?? '', 255),
    }),
    [editedNote],
  );

  const formIsValid = useMemo(() => !Object.values(valid).includes(false), [valid]);

  const handleSubmit = useCallback(() => {
    if (formIsValid) {
      onSubmit();
    }
  }, [onSubmit, formIsValid]);

  return (
    <Container style={{ display: 'flex', flexDirection: 'column', flex: 1 }} {...props}>
      <Flex flexDirection="column" height="100%">
        <Flex
          alignItems="center"
          justifyContent={'flex-end'}
          padding="0.25rem 1.5rem"
          backgroundColor={colors.secondary[200]}
          borderBottom="1px solid"
          height="49px"
          borderColor={colors.secondary[700]}
        >
          <Button
            smallPad
            iconLeft={MdClose}
            variant="plain"
            onClick={() => onClickClose()}
            color={colors.secondary[900]}
          >
            {t('Close')}
          </Button>
        </Flex>
        <NoteDayPicker note={note} />
        <Flex
          onClick={(e) => {
            e.preventDefault();
            setIsCollapsed(!isCollapsed);
          }}
          alignItems="center"
          backgroundColor={colors.secondary[200]}
          gap="0.5rem"
          style={{
            borderBottom: `1px solid ${colors.secondary[700]}`,
            paddingLeft: '0.7rem',
          }}
        >
          {isCollapsed ? <MdKeyboardArrowRight size="1.5rem" /> : <MdKeyboardArrowDown size="1.5rem" />}
          <Label style={{ cursor: 'pointer', height: '3rem', alignContent: 'center' }}>{t('Locations')}</Label>
        </Flex>
        {!isCollapsed && (
          <NoteLocations setSelectedLocations={setSelectedLocations} selectedLocations={selectedLocations} />
        )}
        <EditorComponent editingAllowed={editingAllowed} />
      </Flex>
      {editingAllowed && (
        <DrawerFooter
          gap="1.5rem"
          style={{
            justifyContent: 'flex-end',
            backgroundColor: colors.secondary[200],
            borderColor: colors.secondary[700],
          }}
        >
          <Button
            smallPad
            color={colors.secondary[900]}
            type="button"
            onClick={() => onClickClose()}
            disabled={loading}
            variant="plain"
          >
            {t('actions.Cancel')}
          </Button>
          <Button
            variant="note"
            smallPad
            bg={colors.secondary[900]}
            color={colors.base[100]}
            onClick={() => handleSubmit()}
            disabled={!formIsDirty || !formIsValid || loading}
            loading={saving}
          >
            {isNewNote ? t('actions.Add') : t('actions.Update note')}
          </Button>
        </DrawerFooter>
      )}
    </Container>
  );
};
