import { useMutation } from 'urql';
import { DefaultQueryResponse } from 'graphql/query/default';
import { Vendor } from 'types/vendor';

const ADD_VERSASENSE_VENDOR_KEY = 'addVersaSenseVendor';

const ADD_VERSASENSE_VENDOR = `
  mutation ($data: AddVersaSenseVendorInput!) {
    ${ADD_VERSASENSE_VENDOR_KEY}(data: $data) {
      id
      name
      type
      updatedAt
    }
  }
`;

type MutVarAddVersasenseVendor = {
  data: {
    name: string;
    metadata: {
      topic: string;
      username: string;
      password: string;
    };
  };
};

type MutResAddVersasenseVendor = DefaultQueryResponse<
  typeof ADD_VERSASENSE_VENDOR_KEY,
  Pick<Vendor, 'id' | 'name' | 'type' | 'updatedAt'>
>;

export const useAddVersaSenseVendor = () => {
  return useMutation<MutResAddVersasenseVendor, MutVarAddVersasenseVendor>(ADD_VERSASENSE_VENDOR);
};
