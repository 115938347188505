import { DefaultQueryResponse } from 'graphql/query/default';
import { LinkedPeriod } from 'types/period';
import { useMutation } from 'urql';

const ADD_PERIOD_KEY = 'addPeriod';

const ADD_PERIOD = `
mutation ($organizationId: String!, $viewId: String!, $data: PeriodInput!) {
  ${ADD_PERIOD_KEY}(organizationId: $organizationId, viewId: $viewId, data: $data) {
    id
    start
    end
    updatedAt
    createdAt
  }
}
`;

type MutVarAddPeriod = {
  organizationId: string;
  viewId: string;
  data: {
    start: string;
    end?: string | null;
  };
};

type MutResAddPeriod = DefaultQueryResponse<
  typeof ADD_PERIOD_KEY,
  Pick<LinkedPeriod, 'id' | 'start' | 'end' | 'updatedAt' | 'createdAt'>
>;

export const useAddPeriod = () => {
  return useMutation<MutResAddPeriod, MutVarAddPeriod>(ADD_PERIOD);
};
