import { DataParserSettings } from '@charphq/parser';
import { Delimiter } from '@charphq/types';
import { Div } from 'components/basics';
import { Dropzone } from 'components/Dropzone';
import { Loading } from 'components/Loading';
import { PropertiesTable, TitleProperty } from 'components/properties/PropertiesTable';
import { useSnackbar } from 'components/Snackbar';
import { H4 } from 'components/typography';
import { Text } from 'components/typography/Text';
import { useAuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useQryDevice } from 'graphql/generated';
import { useAddFileParserSettings } from 'graphql/mutation/useAddFileParserSettings';
import { useAddManualUpload } from 'graphql/mutation/useAddManualUpload';
import { ManualUploadResult, useManualUpload } from 'hooks/useManualUpload';
import { upperFirst } from 'lodash-es';
import { Stepper, StepperButton } from 'pages/project/views/alert/addRule/components/Stepper';
import { useCallback, useMemo, useState, VFC } from 'react';
import { useUploadFileApi } from 'server/util/useUploadFileApi';
import { Severity } from 'state/snackbarStore';
import { acceptedMimeTypes } from 'types/data';
import { DeviceType } from 'types/device';
import { dispatchErrors } from 'utils/util';
import { DrawerLayout } from '../layout/drawer/DrawerLayout';
import { PrepareDatapoints } from './PrepareDatapoints';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'context/OrgContext';

type Props = DrawerComponentProps & { deviceId: string };

export const ManualUploadDrawer: VFC<Props> = ({ deviceId, requestClose }) => {
  const { t } = useTranslation();
  const { addAlert } = useSnackbar();
  const authContext = useAuthContext();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [file, setFile] = useState<File | undefined>();
  const [fileEncoding, setFileEncoding] = useState<string | undefined>(undefined);
  const [fileContent, setFileContent] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [manualUploadError, setManualUploadError] = useState<string | undefined>();
  const [dataFileParserSettings, setDataFileParserSettings] = useState<DataParserSettings | undefined>();
  const [hideFilePreview, setHideFilePreview] = useState<boolean>(false);
  const [sensorColumns, setSensorColumns] = useState<{ [sensorId: string]: number }>({});

  const { organization: org } = useOrganization();
  const [uploadFile] = useUploadFileApi();
  const [, addManualUpload] = useAddManualUpload();
  const [, addParserSettings] = useAddFileParserSettings();

  const [{ data: device }] = useQryDevice(
    {
      id: true,
      name: true,
      type: true,
      model: true,
      sensors: {
        id: true,
        name: true,
        measurement: true,
        unit: true,
      },
      locations: {
        createdAt: true,
        location: {
          name: true,
        },
      },
    },
    {
      uuid: deviceId,
    },
    {},
  );

  const onClose = useCallback(
    (ignore?: boolean) => {
      requestClose(ignore);
    },
    [requestClose],
  );

  const onUpload = useCallback(async () => {
    if (!file || !org || !device || !dataFileParserSettings || !fileEncoding || !startDate || !endDate) {
      return;
    }
    try {
      const fileObject = await uploadFile(file, org.id);
      const parserSettings = await addParserSettings({
        data: {
          name: device.type,
          fileEncoding: fileEncoding,
          fileDelimiter: dataFileParserSettings.fileDelimiter,
          startDataRow: dataFileParserSettings.dataRow,
          dateFormat: dataFileParserSettings.dateFormat,
          dateDelimiter: dataFileParserSettings.dateDelimiter,
          dateColumn: dataFileParserSettings.dateColumn,
          timeFormat: dataFileParserSettings.timeFormat,
          utcOffset: dataFileParserSettings.utcOffset,
          timeColumn: dataFileParserSettings.timeColumn,
          sampleTime: dataFileParserSettings.sampleTime,
          startDateOfSampleTime: dataFileParserSettings.startDate,
        },
        organizationId: org.id,
      });
      if (parserSettings.error) {
        dispatchErrors({ addAlert }, parserSettings.error, authContext, t);
        return;
      }
      if (!parserSettings.data) {
        addAlert(t('Unable to upload {{name}} due to invalid parser settings', { name: file.name }), Severity.ERROR);
        return;
      }
      const manualUpload = await addManualUpload({
        organizationId: org.id,
        deviceId: device.id,
        fileId: fileObject.id,
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        sensors: Object.entries(sensorColumns).map(([sensorId, column]) => ({ sensorId, column })),
        fileParserSettingsId: parserSettings.data.addFileParserSettings.id,
      });
      if (manualUpload.error) {
        dispatchErrors({ addAlert }, manualUpload.error, authContext, t);
        return;
      }
      if (manualUpload.data) {
        onClose(true);
        addAlert(t('File {{name}} successfully uploaded', { name: file.name }), Severity.SUCCESS);
      }
    } catch (error) {
      addAlert(t('Failed to upload file: {{name}}', { name: file.name }), Severity.ERROR);
    }
  }, [
    addAlert,
    addManualUpload,
    addParserSettings,
    authContext,
    dataFileParserSettings,
    device,
    endDate,
    file,
    fileEncoding,
    onClose,
    org,
    sensorColumns,
    startDate,
    uploadFile,
    t,
  ]);

  const deviceProperties = useMemo(() => {
    const properties: TitleProperty[] = [];
    if (device) {
      properties.push({
        type: 'static',
        title: t('Device'),
        value: device.name,
      });
      properties.push({
        type: 'badge',
        title: t('Type'),
        value: upperFirst(device.type),
      });
      properties.push({
        type: 'badge',
        title: t('Model'),
        value: upperFirst(device.model ?? 'unknown'),
      });
    }
    return properties;
  }, [device, t]);

  const onFinishParsing = useCallback(
    ({ error, startDate, endDate, fileAsString, fileEncoding, parserSettings }: ManualUploadResult) => {
      if (error) {
        setManualUploadError(error);
        return;
      }
      if (!parserSettings || !fileAsString) {
        setManualUploadError(t('No parser settings or file content available'));
        return;
      }
      setDataFileParserSettings(parserSettings);
      setFileContent(fileAsString);
      setFileEncoding(fileEncoding);
      setStartDate(startDate);
      setEndDate(endDate);
      addAlert(t('File parsed successfully'), Severity.SUCCESS);
      setCurrentStep(2);
    },
    [addAlert, t],
  );

  const [processingDataFile, parseDataFile] = useManualUpload({
    onFinish: onFinishParsing,
    t,
  });

  const onAcceptedFile = useCallback(
    (file: File) => {
      setFile(file);
      setFileContent(undefined);
      setDataFileParserSettings(undefined);
      setManualUploadError(undefined);
      setCurrentStep(1);
      parseDataFile(file, device?.type as DeviceType);
    },
    [device?.type, parseDataFile],
  );

  return (
    <DrawerLayout title={t('Manual upload')} onClose={onClose} width={800}>
      <Stepper
        activeStep={currentStep}
        steps={[{ label: t('Upload file') }, { label: t('Parse file') }, { label: t('Prepare datapoints') }]}
      />
      <Div mt={16}>
        {currentStep === 0 && (
          <>
            <H4>{t('Upload data for')}</H4>
            <PropertiesTable properties={deviceProperties} />
            <H4>{t('Select your file to upload')}</H4>
            <Dropzone
              height={60}
              selectedFileName={file?.name}
              onAcceptedFile={onAcceptedFile}
              multiple={false}
              // Max 20mb
              maxSize={20971520}
              accept={acceptedMimeTypes}
            />
          </>
        )}
        {currentStep === 1 && (
          <>
            <H4>{t('Upload data for')}</H4>
            <PropertiesTable properties={deviceProperties} />
            <H4>{t('You have selected the following file')}</H4>
            <Div>{file !== undefined && file.name}</Div>
            <Loading
              mt={4}
              loading={processingDataFile}
              message={t(
                'We are currently parsing your file. This may take a while depending on the size of your file',
              )}
            />
            {manualUploadError && (
              <>
                <Div mt={4}>
                  <H4>{t('Failed to parse file')}</H4>
                  {manualUploadError}
                </Div>
                <Div mt={4}>
                  <StepperButton arrow="left" stepText="previous" onClick={() => setCurrentStep(0)}>
                    <Text fontWeight="bold">{t('Change file')}</Text>
                  </StepperButton>
                </Div>
              </>
            )}
          </>
          // Todo: custom parser settings
        )}
        {currentStep === 2 && (
          <PrepareDatapoints
            fileName={file?.name ?? ''}
            fileContent={fileContent ?? ''}
            fileDelimiter={dataFileParserSettings?.fileDelimiter ?? Delimiter.enum.comma}
            device={device}
            showFilePreview={hideFilePreview}
            toggleFilePreview={() => setHideFilePreview((p) => !p)}
            sensorColumns={sensorColumns}
            setSensorColumns={setSensorColumns}
            onNext={onUpload}
            onBack={() => {
              setCurrentStep(0);
            }}
          />
        )}
      </Div>
    </DrawerLayout>
  );
};
