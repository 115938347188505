import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from 'components/buttons';
import { Form } from 'components/form';
import { Label } from 'components/form/Label';
import { Box } from 'components/layout/Box';
import { useQryOrganization } from 'graphql/generated';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import {
  CharpDeviceTypes,
  charpDeviceTypes,
  ConnectionType,
  DeviceRoleTypes,
  DeviceType,
  ElsysDeviceModels,
} from 'types/device';
import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { SelectInput } from 'components/form/SelectInput';
import { DeviceCreateConfirmationDialog } from 'components/DevicesCreateConfirmationDialog';
import { TextArea } from 'components/form/TextAreaPure';
import { useAddDevices } from 'graphql/mutation/useAddDevices';
import { OptionType } from 'types/select';
import { Flex } from 'components/layout/Flex';
import { patterns } from './AddCharpDevice';
import { useSnackbar } from 'components/Snackbar';
import { Severity } from 'state/snackbarStore';

const charpMultipleDevicesInput = z.object({
  deviceType: z.enum([...Object.values(CharpDeviceTypes)] as [DeviceType, ...DeviceType[]]),
  deviceEUIs: z.array(z.string()),
  externalIds: z.array(z.string()).optional(),
});
export type CharpMultipleDevicesInput = z.infer<typeof charpMultipleDevicesInput>;

type Props = { requestClose: (ignoreGuard?: boolean | undefined) => void };

export const AddBulkCharpDevices: FC<Props> = ({ requestClose }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const {
    handleSubmit,
    formState: { isSubmitting },
    setValue,
    control,
  } = useForm<CharpMultipleDevicesInput>({
    resolver: zodResolver(charpMultipleDevicesInput),
    defaultValues: {
      deviceType: CharpDeviceTypes.TALKPOOL,
      deviceEUIs: [],
      externalIds: [],
    },
  });
  const deviceEUIs = useWatch({
    control,
    name: 'deviceEUIs',
  });
  const externalIds = useWatch({
    control,
    name: 'externalIds',
  });
  const charpDeviceType = useWatch({
    control,
    name: 'deviceType',
  });

  useEffect(() => {
    if (deviceEUIs.length > 0) {
      const sanitizedDevEUI = deviceEUIs[0].replace(/\s|[-:]/g, '');
      const matchingPattern = patterns.find(
        (pattern) => sanitizedDevEUI.startsWith(pattern.prefix) && sanitizedDevEUI.length === pattern.length,
      );

      const deviceType = matchingPattern ? matchingPattern.type : CharpDeviceTypes.WATTECO;
      setValue('deviceType', deviceType, { shouldValidate: true });
    }
  }, [deviceEUIs, setValue]);

  const [{ data: org }] = useQryOrganization({ id: true, name: true }, {});
  const [, addDevices] = useAddDevices();

  const handleDeviceEUIChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const values = e.target.value.split(/\n/).map((eui) => eui.replace(/[\s-:]/g, ''));
      setValue('deviceEUIs', values, { shouldValidate: true });
    },
    [setValue],
  );

  const handleExternalIdsChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const values = e.target.value.split(/\n/).map((eui) => eui.replace(/[\s-:]/g, ''));
      setValue('externalIds', values, { shouldValidate: true });
    },
    [setValue],
  );

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formData, setFormData] = useState<CharpMultipleDevicesInput | null>(null);

  const validateDeviceEUIs = (deviceEUIs: string[]) => {
    return deviceEUIs.every((eui) => eui.startsWith(deviceEUIs?.[0]?.slice(0, 4)));
  };

  const onSubmit: SubmitHandler<CharpMultipleDevicesInput> = useCallback(
    (data) => {
      if (!validateDeviceEUIs(data.deviceEUIs)) {
        snackbar.addAlert(t('All <identifiers> must have the same type'), Severity.ERROR);
        return;
      }

      if (data.deviceType === CharpDeviceTypes.XTEL && data.deviceEUIs.length !== data.externalIds?.length) {
        snackbar.addAlert(t('The <code> and <identifier> must be on the same line'), Severity.ERROR);
        return;
      }

      setFormData(data);
      setShowConfirmation(true);
    },
    [snackbar, t],
  );

  const handleConfirm = useCallback(async () => {
    setShowConfirmation(false);
    if (formData && org) {
      addDevices({
        organizationId: org.id,
        data: {
          connection: ConnectionType.CHARP,
          type: formData.deviceType,
          model: formData.deviceType === CharpDeviceTypes.ELSYS ? ElsysDeviceModels.ERS : undefined,
          devEUIs: formData.deviceEUIs,
          externalIds: formData.externalIds,
        },
      });
      snackbar.addAlert(t('Devices successfully created'), Severity.SUCCESS);

      requestClose();
    }
  }, [formData, org, addDevices, snackbar, t, requestClose]);

  const handleCancel = useCallback(() => {
    setShowConfirmation(false);
  }, []);

  const charpTypeOptions: OptionType[] = useMemo(
    () =>
      Object.entries(charpDeviceTypes)
        .filter(([, metadata]) => [DeviceRoleTypes.MAXI, DeviceRoleTypes.MINI].some((x) => metadata.roles.includes(x)))
        .map(([type, metadata]) => ({
          label: metadata.label,
          value: type,
        })),
    [],
  );

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Body>
          <Flex gap="2rem" justifyContent="space-between">
            <Box flex="1">
              <Label>{t('Device Identifiers')}*</Label>
              <TextArea
                value={deviceEUIs.join('\n')}
                placeholder={t('Enter one device identifier per line')}
                onChange={handleDeviceEUIChange}
              />
            </Box>
            {charpDeviceType === CharpDeviceTypes.XTEL && (
              <Box flex="1">
                <Label>{t('Codes')}*</Label>
                <TextArea
                  value={externalIds?.join('\n')}
                  placeholder={t('Enter the code per line')}
                  onChange={handleExternalIdsChange}
                />
              </Box>
            )}
          </Flex>
          <Box mt="1.5rem">
            <Label>{t('The device type')}*</Label>
            <Controller
              name="deviceType"
              control={control}
              rules={{ required: true }}
              render={({ field: { value } }) => (
                <SelectInput
                  options={charpTypeOptions}
                  onChange={(selectedValue) =>
                    setValue('deviceType', selectedValue as CharpDeviceTypes, { shouldValidate: true })
                  }
                  placeholder={t('actions.Select a device type')}
                  value={charpTypeOptions.find((i) => i.value === value)}
                  width="16.5rem"
                />
              )}
            />
          </Box>
        </Body>
        <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
          <Button smallPad onClick={() => requestClose()} variant="plain">
            {t('actions.Cancel')}
          </Button>
          <Button smallPad type="submit" loading={isSubmitting}>
            {t('actions.Save')}
          </Button>
        </DrawerFooter>
      </Form>
      {showConfirmation && formData && (
        <DeviceCreateConfirmationDialog
          multipleDevicesData={{
            ...formData,
            organization: org?.name,
          }}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};
