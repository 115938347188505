import { useMutation } from 'urql';
import { DefaultQueryResponse } from 'graphql/query/default';

const DELETE_NOTE_KEY = 'deleteNote';

const DELETE_NOTE = `
  mutation ($noteId: String!) {
    ${DELETE_NOTE_KEY}(uuid: $noteId)
  }
`;

type MutVarDeleteNote = {
  noteId: string;
};

type MutResDeleteNote = DefaultQueryResponse<typeof DELETE_NOTE_KEY, string>;

export const useDeleteNote = () => {
  return useMutation<MutResDeleteNote, MutVarDeleteNote>(DELETE_NOTE);
};
