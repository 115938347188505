import { useMutation } from 'urql';
import { Ashrae2019Average, Ashrae2019Control, Ashrae2019View } from 'types/view/ashrae2019View';
import { DefaultQueryResponse } from 'graphql/query/default';

const ADD_ASHRAE_2019_VIEW_KEY = 'addAshrae2019View';

const ADD_ASHRAE_2019_VIEW = `
  mutation ($projectId: String!, $data: AddAshrae2019ViewInput!) {
    ${ADD_ASHRAE_2019_VIEW_KEY}(uuid: $projectId, data: $data) {
      id
      name
      type
      projectId
      updatedAt
    }
  }
`;

type MutVarAddAshrae2019View = {
  projectId: string;
  data: {
    name: string;
    average?: Ashrae2019Average;
    control: Ashrae2019Control;
    projectSensorIds: string[];
    autoAddNewDevices?: boolean;
  };
};

export const useAddAshrae2019View = () => {
  return useMutation<
    DefaultQueryResponse<
      typeof ADD_ASHRAE_2019_VIEW_KEY,
      Pick<Ashrae2019View, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId'>
    >,
    MutVarAddAshrae2019View
  >(ADD_ASHRAE_2019_VIEW);
};
