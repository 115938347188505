import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from 'components/buttons';
import { Form } from 'components/form';
import { Input } from 'components/form/InputPure';
import { Label } from 'components/form/Label';
import { Box } from 'components/layout/Box';
import { ErrorMessage } from 'components/typography/ErrorMessage';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { z } from 'zod';
import { DrawerComponentProps } from 'context/DrawerContext';
import { MeasurementSelect } from 'components/form/MeasurementSelect';
import { Calibration, Sensor, useQrySensorPages } from 'graphql/generated';
import { usePaginationStore } from 'state/paginationStore';
import { useFilterStore } from 'state/filterStore';
import shallow from 'zustand/shallow';
import { PeriodArray, RangeDatePicker } from 'components/form/RangeDatePicker';
import { Flex } from 'components/layout/Flex';
import useIsMobile from 'hooks/useIsMobile';
import { useSnackbar } from 'components/Snackbar';
import { Severity } from 'state/snackbarStore';
import { useAddCalibrationAndSensors } from 'server/mutations/calibration/useAddCalibration';
import { useEditCalibration } from 'server/mutations/calibration/useEditCalibration';
import Tooltip from 'components/tooltip/Tooltip';
import { useOrganization } from 'context/OrgContext';

const calibrationInput = z.object({
  name: z.string().nonempty('Name is required'),
  formula: z.string().nonempty('Formula is required'),
});

type CalibrationInput = z.infer<typeof calibrationInput>;

type Props = DrawerComponentProps & {
  calibration: Calibration;
  onSuccess: () => void;
};

export const AddCalibrationDrawer: FC<Props> = ({ calibration, onSuccess, requestClose }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CalibrationInput>({
    resolver: zodResolver(calibrationInput),
    defaultValues: {
      name: calibration?.name ?? '',
      formula: calibration?.formula ?? '',
    },
  });

  const [selectedSensors, setSelectedSensors] = useState<Sensor[] | undefined>([]);
  const [limit, offset, setOffset] = usePaginationStore(
    (state) => [state.monitorViewForm.limit, state.monitorViewForm.offset, state.monitorViewForm.setOffset],
    shallow,
  );

  const [filter, setFilter] = useFilterStore(
    (state) => [state.projectSensorsForm.values, state.projectSensorsForm.filter],
    shallow,
  );

  // All the calibrations sensors linked to a calibration will have the same sensors and dates
  // So we can use the first calibration sensor to set the initial period and selected sensors
  const [period, setPeriod] = useState<PeriodArray>([
    calibration?.calibrationSensors?.[0]?.startDate
      ? new Date(calibration.calibrationSensors[0]?.startDate)
      : undefined,
    calibration?.calibrationSensors?.[0]?.endDate ? new Date(calibration.calibrationSensors[0]?.endDate) : undefined,
  ]);
  const snackbar = useSnackbar();

  const initialSensors = useMemo(
    () => calibration?.calibrationSensors?.[0]?.sensors ?? [],
    [calibration?.calibrationSensors],
  );

  useEffect(() => {
    if (selectedSensors?.length === 0 && initialSensors.length > 0) {
      setSelectedSensors(initialSensors);
    }
  }, [initialSensors, selectedSensors]);

  const [{ data: sensorPages, fetching: loadingSensors }] = useQrySensorPages(
    {
      items: {
        id: true,
        name: true,
        measurement: true,
        unit: true,
      },
      count: true,
    },
    {
      page: {
        limit,
        offset,
      },
      filter,
    },
    {},
  );

  useEffect(() => {
    setOffset(0);
  }, [filter.name, setOffset]);

  const loadOptions = useCallback(async () => {
    const pageCount = sensorPages?.count ?? 0;
    const totalPageCount = Math.ceil(pageCount / limit);
    const currentPage = Math.floor(offset / limit) + 1;

    const hasMore = currentPage < totalPageCount;

    if (hasMore) {
      setOffset(currentPage * limit);
    }

    return {
      options: sensorPages?.items as Sensor[],
      hasMore,
    };
  }, [offset, sensorPages, limit, setOffset]);

  const removeSensor = useCallback(
    (sensor: Sensor) => {
      const filteredSensors = selectedSensors?.filter((selectedSensor) => selectedSensor.id !== sensor.id);
      setSelectedSensors(filteredSensors);
    },
    [selectedSensors, setSelectedSensors],
  );

  const handleSearch = (search: string) => {
    setFilter({ name: search });
  };

  const [, addCalibrationAndSensors] = useAddCalibrationAndSensors();
  const [, editCalibration] = useEditCalibration();
  const { organization: org } = useOrganization();

  const onSubmit: SubmitHandler<CalibrationInput> = useCallback(
    async (data) => {
      const calibrationData = {
        calibration: {
          name: data.name,
          formula: data.formula,
          organizationId: org?.id ?? '',
        },
        calibrationSensor: {
          sensorIds: selectedSensors?.map((sensor) => sensor.id) ?? [],
          startDate: period[0]?.toISOString(),
          endDate: period[1]?.toISOString(),
        },
      };

      if (calibration) {
        try {
          await editCalibration({ uuid: calibration.id, data: calibrationData });
          snackbar.addAlert(t('Edited calibration successfully'), Severity.SUCCESS);
          onSuccess();
          requestClose();
        } catch (error) {
          snackbar.addAlert(t('Error editing calibration or calibration sensors'), Severity.ERROR);
        }
      } else {
        try {
          await addCalibrationAndSensors({ data: calibrationData });
          snackbar.addAlert(t('Created new calibration'), Severity.SUCCESS);
          onSuccess();
          requestClose();
        } catch (error) {
          snackbar.addAlert(t('Error adding calibration'), Severity.ERROR);
        }
      }
    },
    [
      editCalibration,
      addCalibrationAndSensors,
      calibration,
      period,
      selectedSensors,
      requestClose,
      onSuccess,
      snackbar,
      t,
      org?.id,
    ],
  );

  const isMobile = useIsMobile();
  return (
    <DrawerLayout title={calibration ? t('Edit calibration') : t('Add calibration')} onClose={requestClose}>
      <Form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Body>
          <Flex flexDirection={isMobile ? 'column' : 'row'}>
            <Box mt="1.5rem">
              <Label>{t('Name')}</Label>
              <Input
                type="text"
                width={isMobile ? '100%' : '16.5rem'}
                placeholder={t('Enter the name')}
                {...register('name', { required: true })}
              />
              <Box>{errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}</Box>
            </Box>
            <Box mt="1.5rem" ml={!isMobile ? '1.5rem' : ''} data-tooltip-id="formula">
              <Label>{t('Formula')}</Label>
              <Input
                type="text"
                width={isMobile ? '100%' : '16.5rem'}
                placeholder={t('Enter the formula')}
                {...register('formula', { required: true })}
              />
              <Box>{errors.formula && <ErrorMessage>{errors.formula.message}</ErrorMessage>}</Box>
            </Box>
            <Tooltip id="formula" place="bottom" content={t('Use x to refer to the measurement to calibrate')} />
          </Flex>
          <Box mt="1.5rem">
            <Label>{t('Period')}</Label>
            <RangeDatePicker period={period} onChange={setPeriod} />
          </Box>
          <Box mt="1.5rem">
            <MeasurementSelect<Sensor>
              label={`${t('Select measurement to calibrate')}*`}
              selected={selectedSensors}
              inputValue={filter.name}
              loading={loadingSensors}
              onChange={(v) => setSelectedSensors(v as Sensor[])}
              loadOptions={loadOptions}
              onRemoveItem={removeSensor}
              onInputChange={handleSearch}
              onMenuClose={() => {
                setOffset(0);
              }}
            />
          </Box>
        </Body>
        <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
          <Button smallPad onClick={() => requestClose()} variant="plain">
            {t('Cancel')}
          </Button>
          <Button smallPad type="submit" loading={isSubmitting}>
            {t('actions.Save')}
          </Button>
        </DrawerFooter>
      </Form>
    </DrawerLayout>
  );
};
