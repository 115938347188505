import { FC } from 'react';
import { DayPicker } from 'react-day-picker';
import styled, { createGlobalStyle } from 'styled-components';
import { colors } from './colors';

const DatePickerGlobalStyles: FC = () => {
  const Style = createGlobalStyle`
    .rdp-caption_label {
        display: none;
    }
    .rdp-day {
        text-align: center;
    }
    .rdp-month {
        display: flex;
        gap: 1rem;
        flex-direction: column;
    }
    .rdp-nav {
        position: absolute;
    }
    .rdp-dropdowns {
        display: flex;
        justify-content: end;
        .rdp-dropdown {
            color: black;
            font-size: 1.25rem; 
            font-weight: 600;
            font-family: inherit;
            text-align: center;
        }
    }
    `;
  return <Style />;
};

const StyledSingleDayPicker = styled(DayPicker)`
  .rdp-disabled {
    color: ${colors.grey[600]};
    background-color: ${colors.grey[100]};
    cursor: not-allowed !important;
    opacity: 0.8;
    .rdp-day_button {
      cursor: not-allowed !important;
    }
    &:hover {
      background-color: ${colors.grey[100]} !important;
    }
  }
  .rdp-months {
    padding: 0.5rem;
  }
  .rdp-month {
    transform: translateY(-24px);
    margin-bottom: -24px;
    font-size: 14px;
  }
  .rdp-chevron {
    width: 16px;
    height: 16px;
  }
  .rdp-day {
    margin: 0.2rem;
    padding: 0.5rem;
    cursor: pointer;
    text-align: 'center';

    &:hover {
      background-color: ${colors.secondary[700]};
    }
  }

  .rdp-nav {
    position: relative;
    z-index: 10;
    width: 3.3rem;
  }

  .rdp-dropdowns {
    .rdp-dropdown {
      background-color: transparent;
      cursor: pointer;
      font-size: 14px;
    }
  }

  .rdp-selected {
    background-color: ${colors.secondary[900]} !important;
    color: white !important;
    font-weight: 600;
  }
`;

export { DatePickerGlobalStyles, StyledSingleDayPicker };
