import { useMutation } from 'urql';
import { DefaultQueryResponse } from 'graphql/query/default';

const ADD_EXTERNAL_TAG_KEY = 'addExternalTag';

const ADD_EXTERNAL_TAG = `
  mutation ($data: AddExternalTagInput!) {
    ${ADD_EXTERNAL_TAG_KEY}(data: $data) {
      id
      externalId
      devEUI
    }
  }
`;

type ExternalTagInput = {
  externalId: string;
  provider: string;
  type: string;
  devEUI: string;
};

type MutVarAddExternalTag = {
  data: ExternalTagInput;
};

type MutResAddExternalTag = DefaultQueryResponse<
  typeof ADD_EXTERNAL_TAG_KEY,
  {
    id: string;
    externalId: string;
    devEUI: string;
    createdAt: string;
    updatedAt: string;
  }
>;

export const useAddExternalTag = () => {
  return useMutation<MutResAddExternalTag, MutVarAddExternalTag>(ADD_EXTERNAL_TAG);
};
