import React from 'react';
import { Label } from 'components/form/Label';
import { MdAddCircleOutline, MdClose, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { StyledSingleDayPicker } from 'theme/datePicker';
import { format } from 'date-fns';
import { Flex } from 'components/layout/Flex';
import { colors } from 'theme/colors';
import { IconButton } from 'components/buttons/IconButton';
import { Button } from 'components/buttons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Tooltip from 'components/tooltip/Tooltip';

const labelStyle: React.CSSProperties = {
  padding: '0.5rem 0.7rem',
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  height: '3.05rem',
  width: '100%',
  borderBottom: `1px solid ${colors.secondary[700]}`,
  borderTop: `1px solid ${colors.secondary[700]}`,
  cursor: 'pointer',
};

interface DatePickerSectionProps {
  date?: Date;
  time: string;
  onTimeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDayClick: (date: Date) => void;
  isStart?: boolean;
  isEndDatePickerVisible?: boolean;
  setIsEndDatePickerVisible?: (value: boolean) => void;
  onRemoveEndDate?: () => void;
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDropdownOpen?: boolean;
}

const TimeInput = styled.input`
  cursor: pointer;
  font-size: 14px;
  background: transparent;
  border: 1px solid ${colors.secondary[700]};
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-right: 1rem;
  margin-left: auto;
`;

const DatePickerSection: React.FC<DatePickerSectionProps> = ({
  date,
  time,
  onTimeChange,
  onDayClick,
  isStart = true,
  isEndDatePickerVisible = true,
  setIsEndDatePickerVisible,
  onRemoveEndDate,
  setIsDropdownOpen,
  isDropdownOpen,
}) => {
  const { t } = useTranslation();

  const handleLabelClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const handleAddEndDateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDropdownOpen(true);
    if (!date) {
      onDayClick(new Date());
    }
    setIsEndDatePickerVisible?.(true);
  };

  return (
    <Flex
      flexDirection="column"
      flex={1}
      borderRight={isStart ? `1px solid ${colors.secondary[700]}` : 'none'}
      borderBottom={isDropdownOpen ? `1px solid ${colors.secondary[700]}` : 'none'}
    >
      {isEndDatePickerVisible ? (
        <Flex style={labelStyle} justifyContent={isStart ? 'initial' : 'flex-end'} onClick={handleLabelClick}>
          {isStart && (isDropdownOpen ? <MdKeyboardArrowDown size="1.5rem" /> : <MdKeyboardArrowRight size="1.5rem" />)}
          <Label style={{ cursor: 'pointer' }}>{date && format(date, 'yyyy-MM-dd')}</Label>
          <Label style={{ cursor: 'pointer' }}>{date && format(date, 'HH:mm')}</Label>
          <IconButton
            color={colors.secondary[900]}
            opacity={isStart ? 0 : 1}
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              onRemoveEndDate?.();
            }}
            data-tooltip-id="removeEndDate"
          >
            <MdClose size={24} />
          </IconButton>
        </Flex>
      ) : (
        setIsEndDatePickerVisible && (
          <Label style={labelStyle} onClick={handleAddEndDateClick}>
            <Button variant="plain" ml="0.5rem" color="black" iconLeft={MdAddCircleOutline}>
              {t('End date')}
            </Button>
          </Label>
        )
      )}
      {isDropdownOpen && (
        <>
          {date && isEndDatePickerVisible && (
            <Flex flexDirection="column">
              <StyledSingleDayPicker
                mode="single"
                modifiers={{ disabled: { after: new Date() } }}
                captionLayout="dropdown"
                selected={date}
                defaultMonth={date}
                onDayClick={onDayClick}
                showOutsideDays
              />
              <Flex
                width="100%"
                alignItems="center"
                pb="1rem"
                pt="1rem"
                borderTop={`1px solid ${colors.secondary[700]}`}
              >
                <Label style={{ color: colors.black, fontSize: '14px', marginLeft: '1rem' }}>{t('Select time')}</Label>
                <TimeInput type="time" value={time} onChange={onTimeChange} />
              </Flex>
            </Flex>
          )}
        </>
      )}
      <Tooltip id="removeEndDate" place="left" content={t('Remove end date')} />
    </Flex>
  );
};

export default DatePickerSection;
