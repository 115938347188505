import { FC, useCallback, useMemo } from 'react';
import { View, useQryLightBudgetView } from 'graphql/generated';
import { Project } from 'types/project';
import { LightBudgetForm, LightBudgetViewData } from './LightBudgetForm';
import { useTranslation } from 'react-i18next';
import { LightBudgetView } from 'types/view/lightBudgetView';
import { useEditLightBudgetView } from 'server/mutations/view/useEditLightBudgetView';
import { useSnackbar } from 'components/Snackbar';
import { useHistory } from 'react-router-dom';
import { Severity } from 'state/snackbarStore';
import { ViewType, viewConfigurations } from 'types';

type Props = {
  projectId: Project['id'];
  view: Pick<View, 'id' | 'name' | 'type'>;
  onClose: () => void;
};

export const EditLightBudgetView: FC<Props> = ({ projectId, view, onClose }) => {
  const { t } = useTranslation();
  const [{ fetching: loading }, edit] = useEditLightBudgetView();
  const snackbar = useSnackbar();
  const history = useHistory();

  const context = useMemo(
    () => ({
      additionalTypenames: ['LightBudgetControl'],
    }),
    [],
  );

  const [{ data: lightBudgetView }] = useQryLightBudgetView(
    {
      id: true,
      name: true,
      type: true,
      projectId: true,
      updatedAt: true,
      autoAddNewDevices: true,
      period: {
        id: true,
        start: true,
        end: true,
        updatedAt: true,
        createdAt: true,
      },
      controls: {
        id: true,
        targetLifetime: true,
        roleOfColor: true,
        sensitivity: true,
        uv: true,
        exposureTime: true,
        viewId: true,
        sensor: {
          id: true,
          name: true,
          deviceId: true,
          location: {
            id: true,
            name: true,
            organization: {
              id: true,
              name: true,
              email: true,
              timezone: true,
              createdAt: true,
            },
            updatedAt: true,
            createdAt: true,
          },
          measurement: true,
          sensorId: true,
          unit: true,
          updatedAt: true,
        },
        updatedAt: true,
        createdAt: true,
      },
    },
    { uuid: view.id },
    {
      context,
    },
  );
  const sensors = lightBudgetView?.controls?.map((control) => control.sensor);

  const editLightBudgetView = useCallback(
    async (data: LightBudgetViewData) => {
      const { name, sensorIds, targetLifetime, roleOfColor, sensitivity, exposureTime, uv, period, autoAddNewDevices } =
        data;
      await edit({
        viewId: view.id,
        data: {
          name,
          projectSensorIds: sensorIds,
          period,
          autoAddNewDevices,
          control: {
            targetLifetime,
            roleOfColor,
            sensitivity,
            exposureTime,
            uv,
          },
        },
      });
      snackbar.addAlert(
        t('{{view}} view saved', { view: t(viewConfigurations[view.type as ViewType].displayName) }),
        Severity.SUCCESS,
      );
      window.location.href = history.location.pathname;
      onClose();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [view],
  );

  return (
    <LightBudgetForm
      projectId={projectId}
      onSave={editLightBudgetView}
      onClose={onClose}
      saveLabel={t('Edit view')}
      loading={loading}
      view={{ projectId, sensors, ...lightBudgetView } as LightBudgetView}
    />
  );
};
