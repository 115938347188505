import { ProjectSensor } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const ADD_PROJECT_SENSORS_KEY = 'addProjectSensors';

const ADD_PROJECT_SENSORS = `
  mutation ($projectId: String!, $sensorIds: [String!]!) {
    ${ADD_PROJECT_SENSORS_KEY}(uuid: $projectId, data: { items: $sensorIds }) {
      id
      name
      unit
      location {
        id
        name
      }
      sensorId
      measurement
      updatedAt
    }
  }
`;

type MutVarAddProjectSensors = {
  projectId: string;
  sensorIds: string[];
};

type MutProjectSensor = Pick<
  ProjectSensor,
  'id' | 'name' | 'unit' | 'location' | 'sensorId' | 'measurement' | 'updatedAt'
>;
type MutResAddProjectSensors = MutProjectSensor[];

export const useAddProjectSensors = () => {
  return useMutation<
    DefaultQueryResponse<typeof ADD_PROJECT_SENSORS_KEY, MutResAddProjectSensors>,
    MutVarAddProjectSensors
  >(ADD_PROJECT_SENSORS);
};
