import { Box } from 'components/layout/Box';
import { Flex } from 'components/layout/Flex';
import type { FC } from 'react';
import { ObjectProperty } from './ObjectProperty';
import { StaticProperty } from './StaticProperty';
import { SelectProperty } from './SelectProperty';
import { EditableProperty } from './EditableProperty';
import { Property } from './DefaultProperty';
import { BadgeProperty } from './BadgeProperty';
import { useHover } from 'hooks/useHover';

export type TitleProperty = { title: string } & Property;

type Props = {
  properties: TitleProperty[];
};

export const PropertiesTable: FC<Props> = ({ properties }) => {
  return (
    <Box px={1} fontSize="1.125rem" width="44.5rem">
      {properties.map((property, index) => (
        <Flex pb="0.5rem" pr="2rem" alignItems="center" gap="1rem" key={index}>
          <Box
            width="10rem"
            flex="0 0 auto"
            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {property.title}
          </Box>
          <HoveredFlex>
            {(hovering) => (
              <>
                {property.type === 'badge' && <BadgeProperty {...property} />}
                {property.type === 'editable' && <EditableProperty hovering={hovering} {...property} />}
                {property.type === 'static' && <StaticProperty {...property} />}
                {property.type === 'object' && <ObjectProperty hovering={hovering} {...property} />}
                {property.type === 'select' && <SelectProperty {...property} />}
              </>
            )}
          </HoveredFlex>
        </Flex>
      ))}
    </Box>
  );
};

interface HoveredFlexProps {
  children: (isHovered: boolean) => React.ReactNode;
}

const HoveredFlex: FC<HoveredFlexProps> = ({ children }) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  return (
    <Flex width="100%" ref={hoverRef} height="2.125rem">
      {children(isHovered)}
    </Flex>
  );
};
