import CharpSymbol from 'components/CharpSymbol';
import { Flex } from 'components/layout/Flex';
import { ProjectContext } from 'context/ProjectContext';
import { FC, useCallback, useContext, useEffect, useRef } from 'react';
import {
  MdOutlineOutbox,
  MdOutlineLocationOn,
  MdOutlineChat,
  MdOutlineManageAccounts,
  MdOutlineNotifications,
  MdOutlinePeopleAlt,
  MdOutlineSensors,
  MdOutlineSettings,
  MdStackedBarChart,
} from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'theme/colors';
import { auth } from 'utils/Authentication';

type Props = {
  setScrollPosition?: (scrollPosition: number) => void;
  scrollPosition?: number;
};

const projectLinks = [
  { icon: MdStackedBarChart, route: (projectId: string) => `/projects/${projectId}` },
  { icon: MdOutlineNotifications, route: (projectId: string) => `/projects/${projectId}/notifications` },
  { icon: MdOutlineChat, route: (projectId: string) => `/projects/${projectId}/notes` },
  { icon: MdOutlineSettings, route: (projectId: string) => `/projects/${projectId}/settings` },
];

const organizationLinks = [
  { icon: MdOutlineSensors, route: '/devices' },
  { icon: MdOutlineLocationOn, route: '/locations' },
  { icon: MdOutlinePeopleAlt, route: '/account/members' },
];

const projectsRoute = '/projects';
const accountRoute = '/account';

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  &:hover {
    background-color: rgba(255, 255, 255, 0.25);
    color: white;
  }
  padding: 0.7rem;
  border-radius: 0.25rem;
  background-color: ${(p) => (p.$isActive ? 'rgba(255,255,255,0.15)' : 'transparent')};
`;

const StyledProjectLink = styled(StyledLink)`
  border: ${(p) => (p.$isActive ? `1px solid ${colors.brandBlue[600]}` : '1px solid transparent')};
`;

const StyledContainer = styled(Flex)`
  position: relative;
  width: 3.9rem;
  overflow: auto;
  background: ${colors.brandBlue[700]};
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 1.5rem;
  height: 96vh;
`;

const MainMenuMinimized: FC<Props> = ({ setScrollPosition, scrollPosition }) => {
  const { project } = useContext(ProjectContext);
  const currentLocation = useLocation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isAuthenticated = auth.isAuthenticated();

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      setScrollPosition?.(containerRef.current.scrollTop);
    }
  }, [setScrollPosition]);

  useEffect(() => {
    const container = containerRef.current;
    if (scrollPosition && container) {
      container.scrollTop = scrollPosition;
    }
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
  }, [handleScroll, scrollPosition]);

  return (
    <StyledContainer ref={containerRef}>
      <CharpSymbol margin="0.7rem" size="1.5rem" color="white" />
      {!isAuthenticated ? (
        <>
          <MdOutlineOutbox size="1.5rem" color="white" style={{ marginTop: '8.8rem', marginBottom: '1.2rem' }} />
          <StyledLink to={`/projects/${project?.id}`} $isActive={true}>
            <MdStackedBarChart size="1.5rem" color="white" />
          </StyledLink>
        </>
      ) : (
        <>
          <StyledProjectLink
            style={{ marginTop: '8rem', marginBottom: '0.5rem' }}
            to={projectsRoute}
            $isActive={currentLocation.pathname === projectsRoute}
          >
            <MdOutlineOutbox size="1.5rem" color="white" />
          </StyledProjectLink>
          <Flex flexDirection="column" gap="0.5rem" style={{ marginBottom: '8rem' }}>
            {project?.id &&
              projectLinks.map(({ icon: Icon, route }, index) => (
                <StyledLink
                  key={index}
                  to={route(project.id)}
                  $isActive={currentLocation.pathname === route(project.id)}
                >
                  <Icon size="1.5rem" color="white" />
                </StyledLink>
              ))}
          </Flex>
          <Flex flexDirection="column" gap="0.5rem" style={{ marginBottom: '5.5rem' }}>
            {organizationLinks.map(({ icon: Icon, route }, index) => (
              <StyledLink key={index} to={route} $isActive={currentLocation.pathname === route}>
                <Icon size="1.5rem" color="white" />
              </StyledLink>
            ))}
          </Flex>
          <StyledLink
            to={accountRoute}
            style={{ marginTop: 'auto' }}
            $isActive={currentLocation.pathname === accountRoute}
          >
            <MdOutlineManageAccounts size="1.5rem" color="white" />
          </StyledLink>
        </>
      )}
    </StyledContainer>
  );
};

export { MainMenuMinimized };
