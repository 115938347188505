import { Device } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { ConnectionType, DeviceModel, DeviceType } from 'types/device';
import { useMutation } from 'urql';

const ADD_DEVICES_KEY = 'addDevices';

const ADD_DEVICES = `
  mutation ($organizationId: String!, $data: AddDevicesLocationsInput!) {
    ${ADD_DEVICES_KEY}(organizationId: $organizationId, data: $data) {
      id
      name
      connection
      type
      model
      battery
      devEUI
      updatedAt
      createdAt
    }
  }
`;

type MutVarAddDevices = {
  organizationId: string;
  data: {
    connection: ConnectionType;
    type: DeviceType;
    model?: DeviceModel;
    devEUIs?: string[];
    externalIds?: string[];
  };
};

type MutResAddDevices = DefaultQueryResponse<
  typeof ADD_DEVICES_KEY,
  Pick<Device, 'id' | 'name' | 'connection' | 'type' | 'model' | 'battery' | 'devEUI' | 'updatedAt' | 'createdAt'>
>;

export const useAddDevices = () => {
  return useMutation<MutResAddDevices, MutVarAddDevices>(ADD_DEVICES);
};
