import { Button } from 'components/buttons';
import { Form, Input } from 'components/form';
import { TextArea } from 'components/form/TextArea';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { GuardBox } from 'components/layout/drawer/GuardBox';
import { Flex } from 'components/layout/Flex';
import { DrawerComponentProps } from 'context/DrawerContext';
import { ProjectContext, ProjectType } from 'context/ProjectContext';
import { FC, FormEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAddProject } from 'server/mutations';
import { isNonEmptyString } from 'utils/validation';

function isValidTitle(str: string) {
  return isNonEmptyString(str) && str.length <= 255;
}

type Props = DrawerComponentProps;

export const AddProjectDrawer: FC<Props> = ({
  setGuarded: setGuardActive,
  closePending: isClosePending,
  requestClose,
  acceptClose,
  denyClose,
}) => {
  const { t } = useTranslation();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [{ fetching: loading }, addProject] = useAddProject();

  const isDirty = useMemo(() => name !== '' || description !== '', [name, description]);
  const { setProject, refetchProjects } = useContext(ProjectContext);

  useEffect(() => {
    setGuardActive(isDirty);
  }, [setGuardActive, isDirty]);

  const onSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const newProject = await addProject({
        name,
        description,
      });
      newProject.data?.addProject && setProject(newProject.data.addProject as ProjectType);
      refetchProjects();
      requestClose(true);
    },
    [addProject, description, name, refetchProjects, requestClose, setProject],
  );

  return (
    <DrawerLayout
      title={t('actions.Add project')}
      isClosing={isClosePending}
      onClose={requestClose}
      onCancelClose={denyClose}
      guardChildren={
        <GuardBox>
          <Flex alignItems="center" justifyContent="space-between" gap="1rem">
            <span>{t('confirmation_messages.Are you sure you want to discard this project?')}</span>
            <Flex alignItems="center" gap="0.5rem">
              <Button variant="subtle" onClick={() => acceptClose()}>
                {t('Yes')}
              </Button>
              <Button variant="action" onClick={() => denyClose()}>
                {t('No')}
              </Button>
            </Flex>
          </Flex>
        </GuardBox>
      }
    >
      <Form style={{ display: 'flex', flexDirection: 'column', flex: 1 }} onSubmit={onSubmit}>
        <Body>
          <Input
            validate={isValidTitle}
            label={t('Name')}
            type="text"
            name="projectName"
            value={name}
            marginProps={{ mb: '1.5rem' }}
            placeholder={t('placeholders.Enter the project name')}
            onChange={(e) => setName(e.target.value)}
            required
            width="16.5rem"
          />
          <TextArea
            validate={isNonEmptyString}
            label={t('Description')}
            name="projectDescription"
            value={description}
            placeholder={t('placeholders.Enter the project description')}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Body>
        <DrawerFooter>
          <Flex style={{ gap: '1.5rem', justifyContent: 'flex-end', width: '100%' }}>
            <Button smallPad onClick={() => requestClose()} variant="plain">
              {t('actions.Cancel')}
            </Button>
            <Button smallPad type="submit" disabled={!isNonEmptyString(name)} loading={loading}>
              {t('actions.Add project')}
            </Button>
          </Flex>
        </DrawerFooter>
      </Form>
    </DrawerLayout>
  );
};
