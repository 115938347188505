import { DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useAddRoom } from 'graphql/mutation/useAddRoom';
import { useRouteChangeBlock } from 'hooks/useRouteChangeBlock';
import { FC, useCallback, useContext } from 'react';
import { Severity } from 'state/snackbarStore';
import { dispatchErrors } from 'utils/util';
import { RoomForm } from './RoomForm';
import { useSnackbar } from './Snackbar';
import { useTranslation } from 'react-i18next';
import { useOrganization } from 'context/OrgContext';

export const AddRoomDrawer: FC<DrawerComponentProps> = ({ requestClose }) => {
  const { t } = useTranslation();
  useRouteChangeBlock();
  const authContext = useContext(AuthContext);
  const snackbar = useSnackbar();

  const { organization } = useOrganization();
  const [{ fetching: addingRoom }, addRoom] = useAddRoom();

  const onSubmit = useCallback(
    async ({
      name,
      site,
      level,
      surfaceArea,
      services,
      height,
      description,
      typeId,
    }: {
      name: string;
      site?: string;
      level?: string;
      surfaceArea?: string;
      services?: string;
      height?: string;
      description?: string;
      typeId?: string;
    }) => {
      if (organization) {
        const newRoom = await addRoom({
          data: {
            name,
            typeId,
            site: site === '' ? undefined : site,
            level: level === '' ? undefined : level,
            surfaceArea: surfaceArea === '' ? undefined : surfaceArea,
            services: services === '' ? undefined : services,
            height: height === '' ? undefined : height,
            description: description === '' ? undefined : description,
          },
        });
        if (newRoom.data) {
          snackbar.addAlert(t('Created new room: {{name}}', { name: newRoom.data.addRoom.name }), Severity.SUCCESS);
          requestClose();
        }
        if (newRoom.error) {
          dispatchErrors(snackbar, newRoom.error, authContext, t);
        }
      }
    },
    [addRoom, authContext, organization, requestClose, snackbar, t],
  );

  return (
    <DrawerLayout title={t('actions.Add room')} onClose={requestClose}>
      {organization && (
        <RoomForm
          submitText={t('actions.Add room')}
          organizationId={organization.id}
          onSubmit={onSubmit}
          onClose={requestClose}
          submitting={addingRoom}
        />
      )}
    </DrawerLayout>
  );
};
