import { ChangeEvent, FC, FormEvent, useCallback, useMemo, useState } from 'react';
import { Button } from 'components/buttons';
import { Form } from 'components/form';
import { Input } from 'components/form/InputPure';
import { Label, LabelSmall } from 'components/form/Label';
import { Body, DrawerFooter } from 'components/layout/drawer/DrawerLayout';
import { Flex } from 'components/layout/Flex';
import { PeriodArray, RangeDatePicker } from 'components/form/RangeDatePicker';
import { SelectInput } from 'components/form/SelectInput';
import { MdDeleteOutline, MdKeyboardArrowDown, MdKeyboardArrowRight } from 'react-icons/md';
import { NoSensors } from './NoSensors';
import { Loader } from 'components/loader/loader';
import { ToggleButton } from 'components/form/ToggleButton';
import styled from 'styled-components';
import Selector, { SelectorStyledCustom } from 'components/Selector';
import { GenericSelect } from 'components/form/GenericSelect';
import { colors } from 'theme/colors';
import { Project } from 'types/project';
import { MonitorView, ProjectSensor } from 'graphql/generated';
import { SampleTime } from '@charphq/types';
import { DomainView } from 'types/data';
import { useProjectSensorLocation } from 'hooks/useProjectSensorLocation';
import { Location } from 'types/location';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'hooks/useIsMobile';
import { upperFirst } from 'lodash-es';
import type { FlexGrowProps } from 'styled-system';
import { useFilterStore } from 'state/filterStore';
import shallow from 'zustand/shallow';
import { useProjectSensorLocationState } from 'hooks/useProjectSensorLocationState';
import { sampleTimeValues } from 'types/samplingTime';
import { ViewType } from 'types';
import { StyledExpandedRow } from 'components/ExpandedRow';

type Props = {
  view?: MonitorView;
  projectId: Project['id'];
  loading: boolean;
  onSave: (
    viewName: MonitorView['name'],
    projectSensorIds: ProjectSensor['id'][],
    period?: PeriodArray,
    sampleTime?: SampleTime,
    autoAddNewDevices?: boolean,
    yDomain?: DomainView<number | undefined | null>[],
  ) => void;
  saveLabel?: React.ReactNode;
  onClose: () => void;
  onClickDelete?: () => void;
} & FlexGrowProps;

const FlexContainer = styled(Flex)`
  flex-direction: column;
  border-bottom: 1px solid ${colors.base[400]};
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  margin-left: -1.5rem;
  padding-left: 1.5rem;
  width: calc(100% + 3rem);
`;

export const MonitorViewForm: FC<Props> = ({
  view,
  projectId,
  loading,
  onSave,
  saveLabel = 'Save',
  onClose,
  onClickDelete,
  ...props
}) => {
  const inputWidth = '16.5rem';
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const {
    availableMeasurementTypes,
    yDomain,
    setYDomain,
    measurementTypes,
    setMeasurementTypes,
    name,
    setName,
    period,
    setPeriod,
    sampleTime,
    setSampleTime,
    selectedYDomain,
    autoAddNewDevices,
    selectedLocations,
    setSelectedLocations,
    setAutoAddNewDevices,
    initialYDomain,
    initialMeasurementTypes,
    availableYDomainMeasurements,
    setSelectedYDomain,
    projectSensorsBySelectedLocations,
    dynamicLocationsByProjectSensors,
  } = useProjectSensorLocationState({ view, projectId, viewType: ViewType.MONITOR });

  const {
    loadingProjectSensors,
    noSensorsYet,
    filter,
    handleSearch,
    setOffset,
    onAddLocations,
    onRemoveLocation,
    loadSelectOptions,
    onSelectorMeasurementTypeChange,
  } = useProjectSensorLocation({ projectId });

  const mergeYDomain = (domains: DomainView<number | null | undefined>[]) => {
    const domainMap = new Map<string, DomainView<number | null | undefined>>();
    domains.forEach((domain) => {
      domainMap.set(domain.measurement ?? '', { ...domainMap.get(domain.measurement ?? ''), ...domain });
    });
    return Array.from(domainMap.values());
  };

  const sampleTimeOptions = useMemo(() => {
    return Object.keys(sampleTimeValues).map((key) => {
      const value = sampleTimeValues[key as keyof typeof sampleTimeValues];
      return { value: key, label: value };
    });
  }, []);

  const onYDomainChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, field: 'min' | 'max', measurement: string) => {
      const inputValue = event.target.value;
      setSelectedYDomain((prev) => {
        const domainMap = new Map(prev.map((domain) => [domain.measurement, domain]));
        const updatedDomain = { ...domainMap.get(measurement), [field]: inputValue, measurement };
        domainMap.set(measurement, updatedDomain);
        const newDomain = mergeYDomain(Array.from(domainMap.values()));
        return newDomain;
      });
    },
    [setSelectedYDomain],
  );

  const [clearFilters] = useFilterStore((state) => [state.projectSensorsForm.clear], shallow);

  const save = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      clearFilters();
      e.preventDefault();

      const selectedMeasurementValues = measurementTypes?.map((type) => type.value) ?? [];

      const selectedSensorIds = projectSensorsBySelectedLocations
        ?.filter(
          (sensor) =>
            sensor?.sensor?.device?.locations.some((deviceLocation) =>
              selectedLocations.some((location) => location.id === deviceLocation.location.id),
            ) && selectedMeasurementValues.includes(sensor.sensor?.measurement ?? ''),
        )
        .map((sensor) => sensor.id);

      if (selectedSensorIds && selectedSensorIds.length > 0) {
        onSave(
          name,
          selectedSensorIds,
          period?.[0] ? period : undefined,
          sampleTime,
          autoAddNewDevices,
          selectedYDomain,
        );
      }
    },
    [
      clearFilters,
      onSave,
      name,
      period,
      sampleTime,
      selectedYDomain,
      autoAddNewDevices,
      selectedLocations,
      projectSensorsBySelectedLocations,
      measurementTypes,
    ],
  );

  const onSelectorYDomainChange = (selectedMeasurements: string[]) => {
    setYDomain(selectedMeasurements.map((measurement) => ({ value: measurement, label: measurement })));
    const domainMap = new Map(selectedYDomain.map((domain) => [domain.measurement, domain]));
    const newDomain = selectedMeasurements.map(
      (measurement) => domainMap.get(measurement) || { min: null, max: null, measurement },
    );
    setSelectedYDomain(newDomain);
    return newDomain;
  };

  const loadOptions = useCallback(
    () => loadSelectOptions(dynamicLocationsByProjectSensors),
    [loadSelectOptions, dynamicLocationsByProjectSensors],
  );

  if (view?.id && selectedLocations.length === 0) return <Loader />;

  return (
    <Flex {...props} flexDirection="column" height="100%">
      {!loadingProjectSensors && noSensorsYet ? (
        <NoSensors projectId={projectId} onClick={onClose} />
      ) : (
        <Form onSubmit={save} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {loading || (view && !view.id) ? (
            <Loader />
          ) : (
            <Body>
              <Flex mb="1.5rem" flexDirection="column">
                <Label mb="0.25rem">{t('Name')}*</Label>
                <Input
                  type="text"
                  name="monitorName"
                  value={name}
                  width={isMobile ? '100%' : inputWidth}
                  placeholder={t('placeholders.Enter the monitor name')}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <StyledExpandedRow
                  onClick={(e) => {
                    e.preventDefault();
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  {isCollapsed ? (
                    <MdKeyboardArrowRight size="1.5rem" color={colors.brand} />
                  ) : (
                    <MdKeyboardArrowDown size="1.5rem" color={colors.brand} />
                  )}
                  <Label style={{ cursor: 'pointer' }}>{t('Advanced options')}</Label>
                </StyledExpandedRow>
              </Flex>
              {!isCollapsed && (
                <FlexContainer flexDirection="column">
                  <Flex mb="1.5rem" flexDirection="column">
                    <Label mb="0.25rem">{t('Sampling interval')}</Label>
                    <LabelSmall>{t('Divide data into time intervals, then average each interval')}</LabelSmall>
                    <SelectInput
                      options={sampleTimeOptions}
                      onChange={(value) => setSampleTime(value as SampleTime)}
                      value={
                        sampleTime && {
                          value: sampleTime ?? '',
                          label: sampleTimeOptions.find((option) => option.value === sampleTime)?.label ?? '',
                        }
                      }
                      placeholder={t('actions.Select a sampling interval')}
                      width={isMobile ? '100%' : inputWidth}
                    />
                  </Flex>
                  <Flex mb="1.5rem" flexDirection="column">
                    <Label>{t('Period')}</Label>
                    <RangeDatePicker period={period} onChange={setPeriod} />
                  </Flex>
                  <Flex mb="1.5rem" flexDirection="column">
                    <Label style={{ marginBottom: '0.5rem' }}>{t('Y-axis range')}</Label>
                    <Selector
                      options={
                        availableYDomainMeasurements?.map((sensor) => ({
                          value: sensor,
                          label: sensor,
                        })) ?? []
                      }
                      selectedValue={yDomain}
                      setSelectedValue={setYDomain}
                      style={{ width: '100%', border: 'solid 1px' }}
                      onChange={onSelectorYDomainChange}
                      initialValue={initialYDomain}
                      isMulti
                      customStylesSelect={(provided) => ({
                        ...provided,
                        ...SelectorStyledCustom,
                        gap: '0.5rem',
                      })}
                    />
                    {selectedYDomain.map((domain, index) => (
                      <div key={index}>
                        <Flex justifyContent="space-between" alignItems="center" mt="0.5rem">
                          <Label style={{ fontSize: '1rem' }}>{upperFirst(t(domain.measurement ?? ''))}</Label>
                        </Flex>
                        <Flex flexDirection={`${isMobile ? 'column' : 'row'}`} gap="1.5rem" marginRight="1.5rem">
                          <Flex flexDirection="column" flex={1}>
                            <LabelSmall>{t('Min')}</LabelSmall>
                            <Input
                              type="number"
                              onChange={(e) => onYDomainChange(e, 'min', domain.measurement ?? '')}
                              value={domain.min ?? ''}
                            />
                          </Flex>
                          <Flex flexDirection="column" flex={1}>
                            <LabelSmall>{t('Max')}</LabelSmall>
                            <Input
                              type="number"
                              onChange={(e) => onYDomainChange(e, 'max', domain.measurement ?? '')}
                              value={domain.max ?? ''}
                            />
                          </Flex>
                        </Flex>
                      </div>
                    ))}
                  </Flex>
                  <Flex mb="1.5rem" flexDirection="column">
                    <Label>{t('Auto add new devices')}</Label>
                    <LabelSmall>{t('When you create a device will be automatically added to this view')}</LabelSmall>
                    <ToggleButton value={autoAddNewDevices} onChange={() => setAutoAddNewDevices(!autoAddNewDevices)} />
                  </Flex>
                </FlexContainer>
              )}
              <GenericSelect
                label={`${t('Add measurements by location')}*`}
                selected={selectedLocations}
                loading={loadingProjectSensors}
                onChange={(locations) =>
                  onAddLocations(locations as Location[], setSelectedLocations, selectedLocations)
                }
                loadOptions={loadOptions}
                onRemoveItem={(locations) => onRemoveLocation(locations, setSelectedLocations, selectedLocations)}
                onInputChange={handleSearch}
                onMenuClose={() => setOffset(0)}
                inputValue={filter.name}
              />
              <Flex mb="1.5rem" flexDirection="column">
                <Label style={{ marginBottom: '0.5rem' }}>{t('Measurement types')}</Label>
                <Selector
                  options={
                    availableMeasurementTypes.map((sensor) => ({
                      value: sensor,
                      label: sensor,
                    })) ?? []
                  }
                  selectedValue={measurementTypes}
                  setSelectedValue={setMeasurementTypes}
                  style={{ width: '100%', border: 'solid 1px' }}
                  onChange={(type) => onSelectorMeasurementTypeChange(type, setMeasurementTypes)}
                  initialValue={initialMeasurementTypes}
                  isMulti
                  customStylesSelect={(provided) => ({
                    ...provided,
                    ...SelectorStyledCustom,
                    gap: '0.5rem',
                  })}
                />
              </Flex>
            </Body>
          )}

          <DrawerFooter style={{ justifyContent: onClickDelete ? 'space-between' : 'flex-end', marginTop: '1rem' }}>
            {onClickDelete && (
              <Button
                smallPad
                iconLeft={MdDeleteOutline}
                variant="plain"
                color={colors.error[700]}
                onClick={onClickDelete}
              >
                {t('actions.Remove view')}
              </Button>
            )}
            <Flex gap="1.5rem">
              <Button smallPad onClick={onClose} variant="plain">
                {t('actions.Cancel')}
              </Button>
              <Button smallPad type="submit" loading={loading} disabled={!name || selectedLocations.length === 0}>
                {saveLabel}
              </Button>
            </Flex>
          </DrawerFooter>
        </Form>
      )}
    </Flex>
  );
};
