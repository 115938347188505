import { css } from '@styled-system/css';
import { useLastDefinedValue } from 'hooks/useLastDefinedValue';
import { FC, useCallback, useMemo } from 'react';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import styled from 'styled-components';
import type { SpaceProps } from 'styled-system';
import { SelectInput } from './form/SelectInput';
import { Flex } from './layout/Flex';
import { colors } from 'theme/colors';
import { useTranslation } from 'react-i18next';
import useIsMobile from 'hooks/useIsMobile';

type PageBtnProps = {
  $active?: boolean;
  $loading?: boolean;
  fullyFadeOnDisable?: boolean;
};

const PageBtn = styled.button<PageBtnProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${(p) =>
    css({
      color: p.$active && !p.$loading ? 'white' : 'black',
      backgroundColor: p.$loading ? 'rgba(0,0,0,0.125)' : p.$active ? colors.brand : 'transparent',
      fontFamily: 'main',
    })}
  pointer-events: all;
  &::after {
    ${css({ backgroundColor: 'black' })}
    opacity: 0.15;
  }
  &:hover:enabled {
    ${(p) => css({ backgroundColor: p.$active ? 'brand' : colors.base[400] })}
  }
  &:disabled {
    cursor: default;
    opacity: ${(p) => (p.fullyFadeOnDisable ? 0 : 0.2)};
  }
  transition: all 96ms ease;
  margin-right: 0.25rem;
  font-weight: 700;
  padding: 0.5rem 0.625rem;
`;

const ItemsPerPage = styled.p(
  css({
    fontSize: '1.125rem',
    marginRight: '0.25rem',
  }),
);

export type PaginationProps = {
  /** Total items */
  count: number | undefined;
  /** Amount of items per page */
  limit: number;
  onChangeLimit?: (newLimit: number) => void;
  /** Cursor index of the first item of the current page */
  offset: number;
  onChangeOffset?: (offset: number) => void;
  loading?: boolean;
  perPage?: number[];
  showLabel?: boolean;
  /** count of items aside of currentpage when dots are shown, f.e. 1 ... 6 |7| 8 ...53 when 1 or 1 ... 6 7 |8| 9 10 ...53 when 2 */
  siblingCount?: number;
  smallPagination?: boolean;
} & SpaceProps;

const Pagination: FC<PaginationProps> = ({
  count,
  limit,
  onChangeLimit,
  offset,
  onChangeOffset = () => {},
  loading,
  perPage = [10, 20, 30, 50],
  showLabel = true,
  siblingCount = 1,
  smallPagination,
  ...props
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  //

  const pageCount = useLastDefinedValue(count) ?? 0;
  const totalPageCount = Math.ceil(pageCount / limit);
  const currentPage = Math.floor(offset / limit) + 1;

  const paginationRange = useMemo(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;
    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    // Calculate left and right sibling index and make sure they are within range 1 and totalPageCount

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. 
      Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    // Case 2: No left dots to show, but rights dots to be shown

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 3 + 2 * siblingCount;
      const leftRange = range(1, leftItemCount);

      return [...leftRange, 'RIGHT_DOTS', totalPageCount];
    }

    // Case 3: No right dots to show, but left dots to be shown

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 3 + 2 * siblingCount;
      const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, 'LEFT_DOTS', ...rightRange];
    }

    // Case 4: Both left and right dots to be shown

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, 'LEFT_DOTS', ...middleRange, 'RIGHT_DOTS', lastPageIndex];
    }
    return [];
  }, [totalPageCount, siblingCount, currentPage]);

  const showPreviousBtn = currentPage > 1;
  const showNextBtn = currentPage < totalPageCount;

  const handleChangeLimit = useCallback(
    (key: number) => {
      onChangeLimit?.(key);
      onChangeOffset(0);
    },
    [onChangeLimit, onChangeOffset],
  );

  const handleDots = useCallback(
    (page: string) => {
      if (page === 'LEFT_DOTS') {
        if (currentPage >= totalPageCount - siblingCount * 2 - 1) {
          onChangeOffset((totalPageCount - 5 - 2 * siblingCount) * limit);
        } else onChangeOffset((currentPage - 4) * limit);
      } else if (currentPage <= 2 + siblingCount * 2) onChangeOffset((currentPage + 5) * limit);
      else onChangeOffset((currentPage + 2) * limit);
    },
    [onChangeOffset, currentPage, siblingCount, limit, totalPageCount],
  );

  if (currentPage === 0 || paginationRange.length < 1) {
    return null;
  }

  return (
    <Flex margin="1.5rem 0" mb={isMobile ? '4rem' : 0} width="100%" justifyContent="space-between">
      <Flex flexDirection="column" alignItems="center" {...props}>
        <Flex alignItems="center">
          <PageBtn onClick={() => onChangeOffset((currentPage - 2) * limit)} disabled={!showPreviousBtn} type="button">
            <MdKeyboardArrowLeft size="24" />
          </PageBtn>

          {paginationRange.map((pageNumber: number | string, i: number) => {
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === 'LEFT_DOTS' || pageNumber === 'RIGHT_DOTS') {
              return (
                <PageBtn
                  key={i}
                  onClick={() => handleDots(pageNumber)}
                  type="button"
                  style={{ padding: isMobile || smallPagination ? '0 0.5rem' : '0.5rem 1rem' }}
                >
                  &#8230;
                </PageBtn>
              );
            }
            if (typeof pageNumber === 'number') {
              return (
                <PageBtn
                  key={i}
                  onClick={() => onChangeOffset((pageNumber - 1) * limit)}
                  $active={pageNumber === currentPage}
                  $loading={pageNumber === currentPage && loading}
                  type="button"
                  style={{ padding: isMobile || smallPagination ? '0 0.5rem' : '0.5rem 1rem' }}
                >
                  {pageNumber}
                </PageBtn>
              );
            }
          })}
          <PageBtn onClick={() => onChangeOffset(currentPage * limit)} disabled={!showNextBtn} type="button">
            <MdKeyboardArrowRight size="24" />
          </PageBtn>
        </Flex>
      </Flex>
      {onChangeLimit !== undefined && perPage !== undefined && !isMobile && (
        <Flex alignItems="center">
          {showLabel && <ItemsPerPage>{t('items per page:')}</ItemsPerPage>}
          <SelectInput
            value={{ value: `${limit}`, label: `${limit}` }}
            options={perPage.map((i) => ({ value: `${i}`, label: i.toString() }))}
            onChange={(v) => handleChangeLimit(+v)}
            width="5rem"
            disabled={loading}
          />
        </Flex>
      )}
    </Flex>
  );
};

const range = (start: number, end: number): number[] => {
  const length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

export { Pagination };
