import { useQuery } from 'urql';
import { Order } from 'types/order';
import { Project } from 'types/project';

const GET_PROJECT_SENSOR_PAGES = `
  query project(
    $uuid: String!
    $filter: ProjectSensorFilterArgs
    $order: ProjectSensorOrderArgs
    $page: PaginationArgs
  ) {
    project(uuid: $uuid) {
      id
      name
      sensorPages(filter: $filter, order: $order, page: $page) {
        items {
          id
          name
          unit
          value {
            value
            time
          }
          sensor {
            device {
              id
              name
            }
          }
          battery
          location {
            id
            name
          }
          sensorId
          measurement
          updatedAt
        }
        count
      }
    }
  }
`;

type Props = {
  uuid?: string;
  filter?: {
    name?: string;
    location?: string;
    measurement?: string;
  };
  order?: {
    name?: Order;
    location?: Order;
    measurement?: Order;
    updatedAt?: Order;
  };
  page?: {
    limit: number;
    offset: number;
  };
};

export const useGetProjectSensorPages = (vars: Props) => {
  const skip = vars.uuid === undefined;

  const [result] = useQuery<{ project: Project }>({
    query: GET_PROJECT_SENSOR_PAGES,
    variables: vars,
    pause: skip,
  });

  return result;
};
