import { FC, useMemo } from 'react';
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatStrikethrough,
  MdFormatUnderlined,
  MdImage,
  MdInsertLink,
} from 'react-icons/md';
import { useTranslation } from 'react-i18next';

import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';

import { colors } from 'theme/colors';
import ReactDOMServer from 'react-dom/server';
import { useNoteContext } from 'context/NoteContext';
import { extractFromEnv } from 'utils/environment';

const svgBold = ReactDOMServer.renderToStaticMarkup(<MdFormatBold size="1.5rem" />);
const svgItalic = ReactDOMServer.renderToStaticMarkup(<MdFormatItalic size="1.5rem" />);
const svgUnderlined = ReactDOMServer.renderToStaticMarkup(<MdFormatUnderlined size="1.5rem" />);
const svgStrikethrough = ReactDOMServer.renderToStaticMarkup(<MdFormatStrikethrough size="1.5rem" />);
const svgLink = ReactDOMServer.renderToStaticMarkup(<MdInsertLink size="1.5rem" />);
const svgImage = ReactDOMServer.renderToStaticMarkup(<MdImage size="1.5rem" />);

type Props = {
  editingAllowed: boolean;
};

const setupEditor = (editor: TinyMCEEditor) => {
  editor.ui.registry.addIcon('bold', svgBold);
  editor.ui.registry.addIcon('italic', svgItalic);
  editor.ui.registry.addIcon('underline', svgUnderlined);
  editor.ui.registry.addIcon('strikethrough', svgStrikethrough);
  editor.ui.registry.addIcon('link', svgLink);
  editor.ui.registry.addIcon('imageSvg', svgImage);
  editor.ui.registry.addButton('customImageUpload', {
    icon: 'imageSvg',
    tooltip: 'Insert image',
    onAction: function () {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      input.onchange = async function () {
        const file = input?.files?.[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = function (e) {
            const imgSrc = e.target?.result;
            editor.insertContent(`<img src="${imgSrc}" alt="Image" style="max-width:100%; height:auto;" />`);
          };
          reader.readAsDataURL(file);
        }
      };
      input.click();
    },
  });

  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = `
    .tox-tinymce {
      border: none !important;
      border-radius: 0 !important;
      height: 100% !important;
    }
    .tox .tox-tbtn svg {
      fill: ${colors.secondary[900]} !important;
    }
    .tox .tox-editor-header {
      padding: 0 !important;
      box-shadow: none !important;
      height: 3rem !important;
      background-color: ${colors.secondary[200]} !important;
      border-bottom: 1px solid ${colors.secondary[700]} !important;
    }
    .tox-tbtn {
      margin: 0 !important;
      cursor: pointer !important;
      border-radius: 0 !important;
      height: 3rem !important;
      width: 3.5rem !important;
      background: ${colors.secondary[200]} !important;
      &:hover {
        background: ${colors.secondary[500]} !important;
      }
    }
    .tox-tbtn--enabled {
      background: ${colors.secondary[500]} !important;
    }
    .tox-toolbar__group {
      padding: 0 !important;
    }
    .tox-toolbar__primary {
      background: ${colors.secondary[200]} !important;
      border-bottom: 1px solid !important;
      border-color: ${colors.secondary[700]} !important;
    }
    .tox .tox-editor-header>.tox-toolbar--scrolling {
      background: ${colors.secondary[200]} !important;
    }
  `;
  document.head.appendChild(style);

  editor.on('KeyDown', (e) => {
    const keyCode = e.keyCode || e.which;
    const content = editor.getContent({ format: 'text' }).trim();
    // Check if the Enter key is pressed and if the current content is the first line (title)
    if (keyCode === 13 && content.split('\n').length === 1) {
      setTimeout(() => {
        // Move the cursor to the next line and apply normal text styling
        editor.execCommand('mceInsertContent', false, '<p><br></p>');
        editor.formatter.apply('p');
      }, 0);
    }
  });
};

export const EditorComponent: FC<Props> = ({ editingAllowed }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { editedNote, setEditedNote } = useNoteContext();
  const initialValue = useMemo(() => editedNote?.description ?? '<p></p>', [editedNote]);

  return (
    <Editor
      value={initialValue}
      apiKey={extractFromEnv('VITE_TINY_API_KEY')}
      disabled={!editingAllowed}
      onEditorChange={(v) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = v;
        const firstPText = tempDiv.querySelector('p')?.textContent || '';
        setEditedNote((prevEditedNote) => ({
          ...prevEditedNote,
          description: v,
          title: firstPText.trim(),
        }));
      }}
      init={{
        placeholder: t('Type your note here'),
        menubar: false,
        statusbar: false,
        language,
        height: '100%',
        plugins: 'link image',
        toolbar: 'bold italic underline strikethrough link customImageUpload',
        content_style: `
              @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
              body { font-family: 'Source Sans Pro', sans-serif; font-size:20px; background-color: ${colors.secondary[100]};}
              p { font-size:20px; margin: 16px 0;}
              p:first-of-type { font-size:24px; font-weight:bold; }
              /* Placeholder styles */
              img { max-width: 100%; height: auto; display: block; margin: auto; }
              p[data-placeholder]:empty:before {
                content: attr(data-placeholder);
                display: block;
                color: #ccc;
              }
              p.custom-placeholder { color: #ccc; font-size:20px; }
              p.custom-placeholder:first-child { font-size:24px; font-weight:bold; }
            `,
        setup: setupEditor,
      }}
    />
  );
};
