import { useMutation } from 'urql';
import { DefaultQueryResponse } from 'graphql/query/default';
import { Vendor } from 'types/vendor';

const ADD_XTEL_VENDOR_KEY = 'addXtelVendor';

const ADD_XTEL_VENDOR = `
  mutation ($data: AddXtelVendorInput!) {
    ${ADD_XTEL_VENDOR_KEY}(data: $data) {
      id
      name
      type
      updatedAt
    }
  }
`;

type MutVarAddXtelVendor = {
  data: {
    name: string;
    addresses: string[];
  };
};

type MutResAddXtelVendor = DefaultQueryResponse<
  typeof ADD_XTEL_VENDOR_KEY,
  Pick<Vendor, 'id' | 'name' | 'type' | 'updatedAt'>
>;

export const useAddXtelVendor = () => {
  return useMutation<MutResAddXtelVendor, MutVarAddXtelVendor>(ADD_XTEL_VENDOR);
};
