import { Calibration } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const ADD_CALIBRATION_AND_SENSORS_KEY = 'addCalibrationAndCalibrationSensors';

const ADD_CALIBRATION_AND_SENSORS = `
  mutation ($data: CalibrationAndCalibrationSensorsInput!) {
    ${ADD_CALIBRATION_AND_SENSORS_KEY}(data: $data) {
      id
      name
      formula
      createdAt
      updatedAt
      calibrationSensors {
        id
        calibrationId
        sensorId
        startDate
        endDate
        createdAt
        updatedAt
      }
    }
  }
`;

type CalibrationInput = {
  name: string;
  formula: string;
  organizationId: string;
};

type CalibrationSensorInput = {
  sensorIds: string[];
  startDate?: string;
  endDate?: string;
};

type AddCalibrationAndCalibrationSensorsInput = {
  calibration: CalibrationInput;
  calibrationSensor: CalibrationSensorInput;
};

type MutVarAddCalibrationAndSensors = {
  data: AddCalibrationAndCalibrationSensorsInput;
};

type MutResAddCalibrationAndSensors = DefaultQueryResponse<
  typeof ADD_CALIBRATION_AND_SENSORS_KEY,
  Pick<Calibration, 'id' | 'name' | 'formula' | 'createdAt' | 'updatedAt'> & {
    calibrationSensors: {
      id: string;
      sensorId: string;
      startDate: string;
      endDate: string;
      createdAt: string;
      updatedAt: string;
    }[];
  }
>;

export const useAddCalibrationAndSensors = () => {
  return useMutation<MutResAddCalibrationAndSensors, MutVarAddCalibrationAndSensors>(ADD_CALIBRATION_AND_SENSORS);
};
