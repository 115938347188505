import { MonitorView, ProjectSensor } from 'graphql/generated';
import { DefaultQueryResponse } from 'graphql/query/default';
import { useMutation } from 'urql';

const EDIT_MONITOR_VIEW_SENSORS_KEY = 'editMonitorViewSensors';

const EDIT_MONITOR_VIEW_SENSORS = `
  mutation ($viewId: String!, $projectSensorIds: [String!]!) {
    ${EDIT_MONITOR_VIEW_SENSORS_KEY}(uuid: $viewId, query: { items: $projectSensorIds }) {
      id
      name
      type
      sensors {
        id
        name
        unit
        location {
          id
          name
        }
        sensorId
        measurement
        updatedAt
      }
      updatedAt
    }
  }
`;

type MutVarEditMonitorViewSensors = {
  viewId: string;
  projectSensorIds: string[];
};

type MutProjectSensor = Pick<
  ProjectSensor,
  'id' | 'name' | 'unit' | 'location' | 'sensorId' | 'measurement' | 'updatedAt'
>;

type MutResEditMonitorViewSensors = Pick<MonitorView, 'id' | 'name' | 'type' | 'updatedAt'> & {
  sensors: MutProjectSensor[];
};

export const useEditMonitorViewSensors = () => {
  return useMutation<
    DefaultQueryResponse<typeof EDIT_MONITOR_VIEW_SENSORS_KEY, MutResEditMonitorViewSensors>,
    MutVarEditMonitorViewSensors
  >(EDIT_MONITOR_VIEW_SENSORS);
};
