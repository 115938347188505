import React, { useState, useEffect, useCallback } from 'react';
import { DatePickerGlobalStyles } from 'theme/datePicker';
import { useNoteContext } from 'context/NoteContext';
import { Flex } from 'components/layout/Flex';
import type { DateRange } from 'react-day-picker';
import DatePickerSection from './DatePickerSection';
import { PartialNote } from 'types/note';

const formatTime = (date: Date): string => {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
};

const updatePeriodWithTime = (time: string, date?: Date): string | undefined => {
  if (!date) return undefined;
  const [hours, minutes] = time.split(':').map(Number);
  const updatedDate = new Date(date);
  updatedDate.setHours(hours, minutes);
  return updatedDate.toISOString();
};

const NoteDayPicker: React.FC<{ note?: PartialNote }> = ({ note }) => {
  const [isEndDatePickerVisible, setIsEndDatePickerVisible] = useState(false);
  const [range, setRange] = useState<DateRange>({ from: undefined, to: undefined });
  const [selectedTimeStart, setSelectedTimeStart] = useState<string>('00:00');
  const [selectedTimeEnd, setSelectedTimeEnd] = useState<string>('00:00');
  const { editedNote, setEditedNote } = useNoteContext();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const createdAtDate = note?.createdAt ? new Date(note?.createdAt) : new Date();
  const defaultPeriod = {
    start: createdAtDate,
    end: createdAtDate,
  };
  const notePeriodDate = editedNote?.period ?? defaultPeriod;

  const areDifferentDays = (from: Date, to: Date) =>
    Math.floor(from.getTime() / 1000) !== Math.floor(to.getTime() / 1000);

  useEffect(() => {
    const from = new Date(notePeriodDate.start);
    const to = notePeriodDate.end ? new Date(notePeriodDate.end) : undefined;
    to && setIsEndDatePickerVisible(areDifferentDays(from, to));
    setRange({ from, to });

    if (!editedNote?.period) {
      setEditedNote({
        ...editedNote,
        period: { start: defaultPeriod.start.toISOString(), end: null },
      });
    }

    if (from) {
      setSelectedTimeStart(formatTime(from));
    }
    if (to) {
      setSelectedTimeEnd(formatTime(to));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSelectedTimeUpdate = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, isStartDate?: boolean) => {
      const time = e.target.value;
      if (!time) return;

      const updatedFrom = updatePeriodWithTime(isStartDate ? time : selectedTimeStart, range.from);
      const updatedTo = updatePeriodWithTime(isStartDate ? selectedTimeEnd : time, range.to);

      if (isStartDate && updatedFrom) {
        setRange((prev) => ({ ...prev, from: new Date(updatedFrom) }));
        setEditedNote({
          ...editedNote,
          period: { start: updatedFrom, end: editedNote.period?.end },
        });
      } else if (updatedTo) {
        setRange((prev) => ({ ...prev, to: new Date(updatedTo) }));
        setEditedNote({
          ...editedNote,
          period: { start: editedNote?.period?.start ?? '', end: updatedTo },
        });
      }

      isStartDate ? setSelectedTimeStart(time) : setSelectedTimeEnd(time);
    },
    [editedNote, range.from, range.to, selectedTimeEnd, selectedTimeStart, setEditedNote],
  );

  const handleDayClick = (date: Date, isStart = false) => {
    const startWithTime = updatePeriodWithTime(selectedTimeStart, date);
    const endWithTime = updatePeriodWithTime(selectedTimeEnd, date);
    if (isStart && startWithTime) {
      setRange((prev) => ({ ...prev, from: new Date(startWithTime) }));
      setEditedNote((prev) => ({
        ...prev,
        period: {
          start: startWithTime,
          end: prev.period?.end,
        },
      }));
    } else if (endWithTime) {
      setRange((prev) => ({ ...prev, to: new Date(endWithTime) }));
      setEditedNote((prev) => ({
        ...prev,
        period: {
          start: prev.period?.start ?? '',
          end: endWithTime,
        },
      }));
    }
  };

  const onRemoveEndDate = () => {
    setRange((prev) => ({ ...prev, to: undefined }));
    setEditedNote((prev) => ({
      ...prev,
      period: {
        start: prev.period?.start ?? '',
        end: null,
      },
    }));
    setIsEndDatePickerVisible(false);
  };

  return (
    <>
      <DatePickerGlobalStyles />
      <Flex>
        <DatePickerSection
          date={range.from && new Date(range.from)}
          time={selectedTimeStart}
          onTimeChange={(e) => onSelectedTimeUpdate(e, true)}
          onDayClick={(date) => handleDayClick(date, true)}
          setIsDropdownOpen={setIsDropdownOpen}
          isDropdownOpen={isDropdownOpen}
        />
        <DatePickerSection
          date={range.to && new Date(range.to)}
          time={selectedTimeEnd}
          onTimeChange={onSelectedTimeUpdate}
          onDayClick={(date) => handleDayClick(date)}
          isStart={false}
          isEndDatePickerVisible={isEndDatePickerVisible}
          setIsEndDatePickerVisible={setIsEndDatePickerVisible}
          onRemoveEndDate={onRemoveEndDate}
          setIsDropdownOpen={setIsDropdownOpen}
          isDropdownOpen={isDropdownOpen}
        />
      </Flex>
    </>
  );
};

export default NoteDayPicker;
