import styled, { css } from 'styled-components';
import { compose, layout, space } from 'styled-system';
import { colors } from 'theme/colors';

export const ExpandedRow = styled.button<{ displayTopBorder?: boolean }>`
  display: flex;
  align-items: center;
  border-bottom: 1px solid;
  ${(props) => !props.displayTopBorder && 'border-top: 1px solid;'}
  padding: 1rem;
  height: 3.6rem;
  ${() =>
    css({
      borderColor: colors.base[400],
    })};
  cursor: pointer;
  &:hover {
    cursor: pointer;
  }
  color: ${colors.brand};
  ${compose(layout, space)}
`;

export const StyledExpandedRow = styled.div`
  margin-top: 2rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: ${colors.brand};
`;
