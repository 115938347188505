import { FC } from 'react';
import type { LayoutProps } from 'styled-system';
import { Menu } from './Menu';
import { useHomeMenu } from './useHomeMenu';

type Props = {
  setScrollPosition?: (scrollPosition: number) => void;
  scrollPosition?: number;
} & LayoutProps;

const MainMenu: FC<Props> = ({ setScrollPosition, scrollPosition, ...props }) => {
  const { homeMenu } = useHomeMenu();

  return <Menu {...props} rootMenu={homeMenu} setScrollPosition={setScrollPosition} scrollPosition={scrollPosition} />;
};

export { MainMenu };
