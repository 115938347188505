import { useTranslation } from 'react-i18next';
import { Button } from 'components/buttons';
import { Form, Input } from 'components/form';
import { FC, FormEvent, useState } from 'react';
import { useAddXtelVendor } from 'server';
import { isNonEmptyString } from 'utils/validation';

type Props = {
  onClose: () => void;
};

export const AddXtel: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');
  const [macAddresses, setMacAddresses] = useState<string>('');
  const [{ fetching: loading }, addXtelVendor] = useAddXtelVendor();

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const filteredMacs = macAddresses.split(',').filter((value) => !!value);
    await addXtelVendor({
      data: {
        name,
        addresses: filteredMacs,
      },
    });
    onClose();
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <Input
          validate={isNonEmptyString}
          label={t('Name')}
          type="text"
          name="dataStreamName"
          value={name}
          marginProps={{ mb: 4 }}
          placeholder={t('placeholders.Enter a name for this data stream')}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <Input
          validate={isNonEmptyString}
          label={t('Mac addresses')}
          type="text"
          name="dataStreamUsername"
          value={macAddresses}
          marginProps={{ mb: 4 }}
          placeholder={t('placeholders.Enter multiple mac addresses, separated by comma')}
          onChange={(e) => setMacAddresses(e.target.value)}
          required
        />
        <Button type="submit" disabled={!isNonEmptyString(name)} loading={loading} variant="positive">
          {t('actions.Add data stream')}
        </Button>
      </Form>
    </div>
  );
};
