import { Button } from 'components/buttons';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useLocale } from 'context/LocaleContext';
import { useQrySensor } from 'graphql/generated';
import { querySensorData } from 'graphql/query/useQrySensorData';
import { FC, FormEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Severity } from 'state/snackbarStore';
import { createSensorDataCSV } from 'utils/data';
import { downloadFile } from 'utils/download';
import { dispatchErrors } from 'utils/util';
import { Form } from './form';
import { Checkbox } from './form/Checkbox';
import { Label } from './form/Label';
import { Flex } from './layout/Flex';
import { useSnackbar } from './Snackbar';
import { ErrorMessage } from './typography/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { RangeDatePicker } from './form/RangeDatePicker';
import type { PeriodArray } from './form/RangeDatePicker';
import { Loader } from './loader/loader';
import { useOrganization } from 'context/OrgContext';

type Props = DrawerComponentProps & {
  sensorId: string;
};

export const DownloadSensorDataDrawer: FC<Props> = ({ requestClose, sensorId }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const authContext = useContext(AuthContext);
  const { locale } = useLocale();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [completePeriod, setCompletePeriod] = useState<boolean>(false);
  const [period, setPeriod] = useState<PeriodArray>([undefined, undefined]);
  const [errors, setErrors] = useState<Array<'date'>>([]);

  const { organization: org, loading: loadingOrg } = useOrganization();

  const [{ data: sensor, fetching: loadingSensor }] = useQrySensor(
    {
      id: true,
      measurement: true,
      unit: true,
    },
    {
      uuid: sensorId,
    },
    {},
  );

  const loading = useMemo(() => loadingOrg || loadingSensor, [loadingOrg, loadingSensor]);

  const onCompletePeriod = useCallback((checked: boolean) => {
    if (checked) {
      setPeriod([undefined, undefined]);
    }
    setCompletePeriod(checked);
  }, []);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (sensor && org && locale) {
        if (period[0] === undefined && !completePeriod) {
          setErrors(['date']);
          return;
        } else {
          setErrors([]);
        }
        const startDate: Date = period[0] !== undefined ? period[0] : new Date(0);
        setLoadingData(true);
        const sensorData = await querySensorData({
          query: {
            sensorId,
            start: startDate.toISOString(),
            end: period[1]?.toISOString() ?? new Date().toISOString(),
            transformations: [],
          },
        });
        if (sensorData.data) {
          const dataBlob = createSensorDataCSV(sensor, sensorData.data.querySensorData, locale?.number, t);
          downloadFile(dataBlob, `${sensor.measurement.toLowerCase()}.csv`);
          snackbar.addAlert(t('The data has been downloaded'), Severity.SUCCESS);
          setLoadingData(false);
          requestClose();
        }
        if (sensorData.error) {
          dispatchErrors(snackbar, sensorData.error, authContext, t);
          setLoadingData(false);
        }
      }
    },
    [authContext, completePeriod, locale, org, period, requestClose, sensor, sensorId, snackbar, t],
  );

  return (
    <DrawerLayout title={t('Download measurement data')} onClose={requestClose}>
      {loading ? (
        <Loader />
      ) : (
        <Form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Body>
            <Label>{t('Measurement')}</Label>
            <Flex flexDirection="row" gap="3.2rem" mb="1.5rem">
              {sensor &&
                t('{{measurement}} in {{unit}}', {
                  measurement: t(sensor.measurement, { ns: 'db-values' }).toLowerCase(),
                  unit: t(sensor.unit, { ns: 'db-values' }).toLowerCase(),
                })}
            </Flex>
            <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
              <Label>{t('Define the period')}</Label>
            </Flex>
            <Flex mb="1.5rem" flexDirection="column">
              <RangeDatePicker period={period} onChange={setPeriod} />
              <Flex height={4} mt="0">
                {errors.includes('date') && <ErrorMessage>{t('Set start date or download all')}</ErrorMessage>}
              </Flex>
            </Flex>
            <Checkbox
              label={t('Download all data')}
              checked={completePeriod}
              onChange={(e) => onCompletePeriod(e.target.checked)}
            />
          </Body>
          <DrawerFooter gap="1.5rem" style={{ justifyContent: 'flex-end' }}>
            <Button smallPad type="button" onClick={() => requestClose()} variant="plain">
              {t('actions.Cancel')}
            </Button>
            <Button smallPad type="submit" disabled={loadingData} loading={loadingData}>
              {t('Download data')}
            </Button>
          </DrawerFooter>
        </Form>
      )}
    </DrawerLayout>
  );
};
