import { Organization, useQryOrganization, useQryOrganizations } from 'graphql/generated';
import { createContext, FC, useContext, useEffect, useState, useCallback } from 'react';
import { UserContext } from './UserContext';
import { useTranslation } from 'react-i18next';
import { useLocale } from './LocaleContext';
import { useSnackbar } from 'components/Snackbar';
import { useEditOrganization } from 'graphql/mutation/useEditOrganization';
import { Severity } from 'state/snackbarStore';

type OrgContextType = {
  organization?: Organization;
  loading: boolean;
  updateOrganization: (key: keyof Organization, value: string | null) => Promise<void>;
  refetchOrganization: () => void;
  organizations?: Organization[];
};

export const OrgContext = createContext<OrgContextType | undefined>(undefined);

export const OrgProvider: FC = (props) => {
  const { t, i18n } = useTranslation();
  const { setLocale } = useLocale();
  const snackbar = useSnackbar();
  const sharedFields = {
    id: true,
    name: true,
    language: true,
    email: true,
    timezone: true,
    users: {
      id: true,
      email: true,
      firstName: true,
      lastName: true,
      createdAt: true,
    },
    createdAt: true,
  } as const;
  const [{ data: fetchedOrganization, fetching: loading }, refetchOrganization] = useQryOrganization(sharedFields, {});

  const [{ data: organizations, fetching: loadingOrganizations }] = useQryOrganizations(sharedFields, {});

  const [, editOrganization] = useEditOrganization();

  const [organization, setOrganization] = useState<Organization | undefined>(fetchedOrganization);

  useEffect(() => {
    if (fetchedOrganization) {
      setOrganization(fetchedOrganization);
    }
  }, [fetchedOrganization]);

  const [user] = useContext(UserContext);

  useEffect(() => {
    if (user) {
      const localeStr = user.language || organization?.language || navigator?.languages[0] || 'en';
      i18n.changeLanguage(localeStr);
      setLocale(localeStr);
    }
  }, [user, organization, i18n, setLocale]);

  const updateOrganization = useCallback(
    async (key: keyof Organization, value: string | null) => {
      if (!organization) return;

      await editOrganization({ data: { [key]: value } });

      setOrganization((prev) => (prev ? { ...prev, [key]: value } : prev));

      refetchOrganization();

      snackbar.addAlert(t('Organization updated successfully'), Severity.SUCCESS);
    },
    [editOrganization, organization, refetchOrganization, snackbar, t],
  );

  return (
    <OrgContext.Provider
      value={{
        organization,
        loading: loading || loadingOrganizations,
        refetchOrganization,
        updateOrganization,
        organizations,
      }}
      {...props}
    />
  );
};

export const useOrganization = () => {
  const context = useContext(OrgContext);
  if (!context) {
    throw new Error('useOrganization must be used within an OrgProvider');
  }
  return context;
};
