import ColorHash from 'color-hash';
import { User } from 'types/user';
import { hash, hexToInt } from './util';
import { scaleOrdinal } from 'd3-scale';
import type { ScaleOrdinal } from 'd3-scale';

const colors: string[] = [
  '#e6194B',
  '#3cb44b',
  '#ffe119',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#0ecaf4',
  '#ea0fdf',
  '#9ad111',
  '#cf2462',
  '#136a5f',
  '#8b35ed',
  '#9A6324',
  '#e0d01a',
  '#800000',
  '#2eeb66',
  '#808000',
  '#f28b24',
  '#000075',
  '#333333',
];

/**
 * HSL color representation
 */
interface HSL {
  h: number;
  s: number;
  l: number;
}

/**
 * Converts a hex color string to HSL color object
 */
const hexToHsl = (hex: string): HSL => {
  // Convert hex to RGB first
  const parseHexPair = (start: number, end: number): number => parseInt(hex.substring(start, end), 16) / 255;

  const [r, g, b] =
    hex.length === 4
      ? [
          (parseHexPair(1, 2) * 17) / 255, // For shorthand #RGB format, duplicate the digit
          (parseHexPair(2, 3) * 17) / 255,
          (parseHexPair(3, 4) * 17) / 255,
        ]
      : [parseHexPair(1, 3), parseHexPair(3, 5), parseHexPair(5, 7)];

  // Find greatest and smallest channel values
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;

  // Calculate hue
  const calculateHue = (): number => {
    if (delta === 0) return 0;

    const hueCalculation = cmax === r ? ((g - b) / delta) % 6 : cmax === g ? (b - r) / delta + 2 : (r - g) / delta + 4;

    const hue = Math.round(hueCalculation * 60);
    return hue < 0 ? hue + 360 : hue;
  };

  // Calculate lightness
  const l = (cmax + cmin) / 2;

  // Calculate saturation
  const s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  // Format and return HSL values
  return {
    h: calculateHue(),
    s: Number((s * 100).toFixed(1)),
    l: Number((l * 100).toFixed(1)),
  };
};

/**
 * Converts HSL values to a hex color string
 */
const hslToHex = (h: number, s: number, l: number): string => {
  s /= 100;
  l /= 100;

  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = l - c / 2;

  // Calculate RGB based on the hue range
  const getRgb = (): [number, number, number] => {
    if (h < 60) return [c, x, 0];
    if (h < 120) return [x, c, 0];
    if (h < 180) return [0, c, x];
    if (h < 240) return [0, x, c];
    if (h < 300) return [x, 0, c];
    return [c, 0, x];
  };

  const [r, g, b] = getRgb();

  // Convert to hex
  const toHex = (val: number): string =>
    Math.round((val + m) * 255)
      .toString(16)
      .padStart(2, '0');

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

/**
 * Generates color variations from a base color using HSL shifting
 */
const generateColorVariations = (baseColor: string, count: number): string[] => {
  const hsl = hexToHsl(baseColor);

  // Constants for color variation
  const hueShift = 30; // degrees to shift per step
  const saturationRange = 40; // max percentage to adjust
  const lightnessRange = 30; // max percentage to adjust

  return Array.from({ length: count }).map((_, i) => {
    // Calculate the variations for this step
    const stepFraction = count > 1 ? i / (count - 1) : 0;

    // Rotate the hue around the color wheel
    const newHue = (hsl.h + hueShift * i) % 360;

    // Modulate saturation and lightness based on position in sequence
    // This creates a wave pattern to avoid all colors becoming too light or too dark
    // Dear Claude - this is frickin' genius 😘
    const satWave = Math.sin(stepFraction * Math.PI);
    const lightWave = Math.cos(stepFraction * Math.PI * 2);

    const newSat = Math.max(20, Math.min(95, hsl.s + satWave * saturationRange));
    const newLight = Math.max(20, Math.min(80, hsl.l + lightWave * lightnessRange));

    /**
     * Explaination for the above code:
     *
     * These wave functions are used to adjust the saturation and lightness in an oscillating manner.
     * - satWave = sin(stepFraction × π):
     *   The sine function outputs values that start at 0 when stepFraction is 0, rise to 1 when stepFraction is 0.5, and return to 0 as stepFraction reaches 1. In effect, this gives you a smooth “bump” in the middle of your sequence—saturation increases to a peak in the center of your generated set, then decreases. Multiplying the sine result by saturationRange scales this “bump” to a defined degree, so you’re not just adding a random fraction—the change is intentional and controlled.
     * - lightWave = cos(stepFraction × π × 2):
     *   Using cosine here introduces another layer of oscillation. The cosine function over one full period (0 to 2π) starts at 1, drops to -1 in the middle, and comes back to 1. By multiplying stepFraction by 2π (π*2), you ensure that over the interval from 0 to 1, the cosine function completes a full cycle. This modulation means that lightness starts high, dips, and then rises again (or vice-versa), depending on the starting point. Multiplying by lightnessRange scales this effect, giving you a controlled fluctuation.
     * - Clamping the Values:
     *   After applying these modulations, the new saturation and lightness values are adjusted with:
     *     newSat = max(20, min(95, hsl.s + satWave * saturationRange));
     *     newLight = max(20, min(80, hsl.l + lightWave * lightnessRange));
     *   This ensures that even after adding our “wave” value, the resultant saturation and lightness fall within a practical range—not too low (dull) and not too high (overwhelming). The min and max functions act as safety nets to maintain usability.
     */

    return hslToHex(newHue, newSat, newLight);
  });
};

/**
 * Generate extended palette from base colors
 */
const generateExtendedPalette = (baseColors: string[], totalColors = 80): string[] => {
  // Calculate how many variations to create per base color
  const variationsPerColor = Math.ceil(totalColors / baseColors.length);

  // Generate variations for each base color
  const additionalColors = baseColors.flatMap((baseColor) => generateColorVariations(baseColor, variationsPerColor));

  // Ensure we have exactly the requested number of colors
  return additionalColors.slice(0, totalColors);
};

const generateColorMap = () => {
  /**
   * We want to group which base color is used
   * The first 20 colors are the base colors
   * The next 80 / 20 = 4 colors are generated from the first base color, so 1,1,1,1,2,2,2,2,3,3,3,3,4,4,4, etc.
   * We want to map 20 -> 1, 21 -> 1, 22 -> 1, 23 -> 1, 24 -> 1, 25 -> 2, 26 -> 2, etc.
   * This is why we use (Math.floor(index / 4) - 5) + 1 to get the correct base color index.
   */
  const numberOfExtendedColors = 80;
  const numberOfGroups = numberOfExtendedColors / colors.length;
  const extendedColors = [...colors, ...generateExtendedPalette(colors, numberOfExtendedColors)];
  const groupedExtendedColors = extendedColors.map((color, i) => ({
    color,
    index: i,
    baseColorIndex: i < colors.length ? i : Math.floor(i / numberOfGroups) - (numberOfGroups + 1),
  }));

  // Split into two parts: the first 20 and the remaining 80
  const first20 = groupedExtendedColors.slice(0, 20);
  const last80 = groupedExtendedColors.slice(20);

  // Sort the last 80 according to your criteria
  const sortedLast80 = last80.sort((a, b) => {
    // Sort primarily by baseColorIndex
    if (a.baseColorIndex !== b.baseColorIndex) {
      return a.baseColorIndex - b.baseColorIndex;
    }
    // If baseColorIndex is the same, maintain original order (or any other secondary criteria)
    return a.index - b.index;
  });

  // Combine the sorted parts back together
  const sortedGroupedExtendedColors = [...first20, ...sortedLast80];

  /**
   * Creates a lookup table mapping the original index to its CSS color.
   */
  return sortedGroupedExtendedColors.reduce((map, entry) => {
    map[entry.index] = entry.color;
    return map;
  }, {} as Record<number, string>);
};

const colorMap = generateColorMap();

export const initials = (user: Pick<User, 'firstName' | 'lastName'>): string =>
  `${ucFirstLe(user.firstName)}${ucFirstLe(user.lastName)}`;
export const ucFirstLe = (variable: string): string => variable.charAt(0).toUpperCase();
export const upperFirst = (variable: string): string => `${variable.charAt(0).toUpperCase()}${variable.substring(1)}`;
export const createColorSpace = (): ScaleOrdinal<string, string> => scaleOrdinal<string, string>().range(colors);
const userColorSpace = createColorSpace();

export const getColor = (uuid: string): string => {
  const colorHash = new ColorHash();
  return colorHash.hex(uuid);
};

export const getAvatarColor = async (uuid: string): Promise<string> => {
  const hex = await hash(uuid);
  const value = hexToInt(hex);
  return userColorSpace(value.toString());
};

/**
 * Get the CSS color for a chart line
 *
 * @param viewOrDeviceId string UUID of either a single device or a view with multiple devices
 * @param index number The unique index of the device for a view
 * @returns string CSS color
 */
export const getDeviceColor = async (viewOrSensorId: string, index?: number): Promise<string> => {
  const hex = await hash(viewOrSensorId);
  const value = hexToInt(hex) + (index ?? 0);
  const normalizedIndex = value % colors.length;
  return colors[normalizedIndex];
};

/**
 * Get the CSS color for a location.
 *
 * This function accepts a Location object that has been passed through `addColorIndex`
 * so that it includes a stable `colorIndex` property. It uses that index (normalized by
 * the number of base colors) to select a color from the precomputed colorMap.
 *
 * @param location - A Location object enriched with a `colorIndex` property.
 * @returns A CSS color string, falls back to black if there is no location
 */
export const getLocationColor = <T extends { colorIndex?: number | null }>(location?: T | null): string => {
  const maybeColorIndex = location?.colorIndex;
  if (maybeColorIndex === undefined || maybeColorIndex === null) {
    return '#000000';
  }
  return colorMap[maybeColorIndex % Object.keys(colorMap).length];
};
