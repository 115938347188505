import { Button } from 'components/buttons';
import { Body, DrawerFooter, DrawerLayout } from 'components/layout/drawer/DrawerLayout';
import { Text } from 'components/typography';
import { AuthContext } from 'context/AuthContext';
import { DrawerComponentProps } from 'context/DrawerContext';
import { useLocale } from 'context/LocaleContext';
import { useQryDevice } from 'graphql/generated';
import { useDeleteDeviceData } from 'graphql/mutation/useDeleteDeviceData';
import { FC, FormEvent, useCallback, useContext, useMemo, useState } from 'react';
import { Severity } from 'state/snackbarStore';
import { dispatchErrors } from 'utils/util';
import { Form } from './form';
import { Checkbox } from './form/Checkbox';
import { Input } from './form/InputPure';
import { Label } from './form/Label';
import { Box } from './layout/Box';
import { Flex } from './layout/Flex';
import { useSnackbar } from './Snackbar';
import { Table, Tbody, Td, Th, Thead, Tr } from './table/Table';
import { SubtleMessage } from './typography';
import { ErrorMessage } from './typography/ErrorMessage';
import { Trans, useTranslation } from 'react-i18next';
import { RangeDatePicker } from './form/RangeDatePicker';
import type { PeriodArray } from './form/RangeDatePicker';
import { useOrganization } from 'context/OrgContext';

type Props = DrawerComponentProps & {
  deviceId: string;
};

export const DeleteDeviceDataDrawer: FC<Props> = ({ requestClose, deviceId }) => {
  const { t } = useTranslation();
  const snackbar = useSnackbar();
  const authContext = useContext(AuthContext);
  const localeContext = useLocale();
  const [completePeriod, setCompletePeriod] = useState<boolean>(false);
  const [selectAllSensors, setSelectAllSensors] = useState<boolean>(false);
  const [selectedSensorIds, setSelectedSensorIds] = useState<string[]>([]);
  const [safewordInput, setSafewordInput] = useState<string>('');
  const [period, setPeriod] = useState<PeriodArray>([undefined, undefined]);
  const [errors, setErrors] = useState<Array<'date' | 'sensors'>>([]);
  const safeword = t('actions.Delete').toLowerCase();

  const { organization: org, loading: loadingOrg } = useOrganization();

  const [{ data: device, fetching: loadingDevice }] = useQryDevice(
    {
      id: true,
      name: true,
      type: true,
      sensors: {
        id: true,
        measurement: true,
        unit: true,
      },
      locations: {
        createdAt: true,
        location: {
          name: true,
        },
      },
    },
    {
      uuid: deviceId,
    },
    {},
  );

  const loading = useMemo(() => loadingOrg || loadingDevice, [loadingOrg, loadingDevice]);

  const [{ fetching: deletingData }, deleteDeviceData] = useDeleteDeviceData();

  const disableCancel = useMemo(() => deletingData, [deletingData]);
  const loadingConfirm = useMemo(() => deletingData, [deletingData]);
  const disableConfirm = useMemo(
    () => deletingData || safewordInput !== safeword,
    [deletingData, safeword, safewordInput],
  );

  const onCompletePeriod = useCallback((checked: boolean) => {
    if (checked) {
      setPeriod([undefined, undefined]);
    }
    setCompletePeriod(checked);
  }, []);

  const onSelectSensor = useCallback(
    (selected: boolean, sensorId: string) => {
      if (selected) {
        setSelectedSensorIds((prev) => prev.concat([sensorId]));
      } else {
        if (selectAllSensors) {
          setSelectAllSensors(false);
        }
        setSelectedSensorIds((prev) => prev.filter((id) => id !== sensorId));
      }
    },
    [selectAllSensors],
  );

  const onSelectAllSensors = useCallback(
    (checked) => {
      setSelectAllSensors(checked);
      if (checked) {
        setSelectedSensorIds(device?.sensors?.map((sensor) => sensor.id) ?? []);
      } else {
        setSelectedSensorIds([]);
      }
    },
    [device],
  );

  const onCancel = useCallback(() => {
    requestClose();
  }, [requestClose]);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (device && org && localeContext) {
        if (period[0] === undefined && !completePeriod) {
          setErrors((prev) => prev.concat(['date']));
          return;
        } else {
          setErrors((prev) => prev.filter((err) => err !== 'date'));
        }
        if (selectedSensorIds.length === 0 && !selectAllSensors) {
          setErrors((prev) => prev.concat(['sensors']));
          return;
        } else {
          setErrors((prev) => prev.filter((err) => err !== 'sensors'));
        }
        const startDate: Date = period[0] !== undefined ? period[0] : new Date(0);
        const sensorData = await deleteDeviceData({
          organizationId: org.id,
          data: {
            start: startDate,
            end: period[1],
            sensorIds: selectedSensorIds,
          },
        });
        if (sensorData.data) {
          snackbar.addAlert(t('The data has been deleted'), Severity.SUCCESS);
          requestClose();
        }
        if (sensorData.error) {
          dispatchErrors(snackbar, sensorData.error, authContext, t);
        }
      }
    },
    [
      authContext,
      completePeriod,
      deleteDeviceData,
      device,
      period,
      localeContext,
      org,
      requestClose,
      selectAllSensors,
      selectedSensorIds,
      snackbar,
      t,
    ],
  );

  return (
    <DrawerLayout title={t('actions.Delete data from device')} onClose={requestClose}>
      <Form onSubmit={onSubmit} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <Body>
          <Label>{t('Device')}</Label>
          <Flex flexDirection="row" gap="3.2rem" mb={4}>
            <span>{device?.name}</span>
            <span>{device?.type}</span>
          </Flex>
          <Flex flexDirection="row" justifyContent="space-between" alignItems="center">
            <Label>{t('Define the period')}</Label>
            <Flex flexDirection="row" alignItems="center" gap="0.5rem">
              <Checkbox
                label={t('actions.Delete all data')}
                checked={completePeriod}
                onChange={(e) => onCompletePeriod(e.target.checked)}
              />
            </Flex>
          </Flex>
          <Flex mb="1.5rem" flexDirection="column" gap="0.25rem">
            <RangeDatePicker period={period} onChange={setPeriod} />
          </Flex>
          <Flex height={4} mt={2}>
            {errors.includes('date') && (
              <ErrorMessage>{t('You should set a start date or delete all data')}</ErrorMessage>
            )}
          </Flex>
          <Flex mt={4} mb={4} flexDirection="row" justifyContent="space-between" alignItems="center">
            <Label>{t('Select the measurements')}</Label>
            <Flex flexDirection="row" alignItems="center" gap="0.5rem">
              <Checkbox
                label={t('actions.Select all measurements')}
                checked={selectAllSensors}
                onChange={(e) => onSelectAllSensors(e.target.checked)}
              />
            </Flex>
          </Flex>
          <Table>
            <Thead>
              <Tr>
                <Th col={1} />
                <Th>{t('Type')}</Th>
                <Th>{t('Unit')}</Th>
              </Tr>
            </Thead>
            <Tbody $loading={loading}>
              {device && device.sensors?.length > 0 ? (
                device.sensors.map((sensor) => {
                  const isSelected = selectedSensorIds.includes(sensor.id);
                  return (
                    <Tr
                      key={sensor.id}
                      bg={isSelected ? 'lightBlue.200' : undefined}
                      hoverBg={isSelected ? 'lightBlue.200' : undefined}
                      onClick={() => onSelectSensor(!isSelected, sensor.id)}
                    >
                      <Td>
                        <Checkbox
                          checked={isSelected}
                          onChange={(e) => onSelectSensor(e.target.checked, sensor.id)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </Td>
                      <Td>{t(sensor.measurement, { ns: 'db-values' })}</Td>
                      <Td>{t(sensor.unit, { ns: 'db-values' })}</Td>
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td />
                  <Td>
                    <SubtleMessage>{t('No measurements found')}</SubtleMessage>
                  </Td>
                  <Td />
                </Tr>
              )}
            </Tbody>
          </Table>
          <Flex height={4} mt={2}>
            {errors.includes('sensors') && (
              <ErrorMessage>{t('You should select at least one measurement')}</ErrorMessage>
            )}
          </Flex>
          <Box>
            <Text mt={4}>
              <Trans
                values={{ safeword }}
                i18nKey="The data will be removed permanently and is not recoverable, type <1>{{safeword}}</1> to confirm"
                components={{ 1: <b /> }}
              />
            </Text>
          </Box>
          <Input
            width="16.5rem"
            name="safewordInput"
            placeholder={t('delete')}
            value={safewordInput}
            onChange={(e) => setSafewordInput(e.target.value)}
          />
        </Body>
        <DrawerFooter gap="1.5rem" justifyContent="flex-end">
          <Button smallPad type="button" disabled={disableCancel} onClick={onCancel} variant="plain">
            {t('actions.Cancel')}
          </Button>
          <Button smallPad type="submit" variant="danger" disabled={disableConfirm} loading={loadingConfirm}>
            {t('actions.Confirm')}
          </Button>
        </DrawerFooter>
      </Form>
    </DrawerLayout>
  );
};
