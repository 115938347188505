import { Button } from 'components/buttons';
import { GuardBox } from 'components/layout/drawer/GuardBox';
import { Flex } from 'components/layout/Flex';
import { WithPushIn } from 'components/layout/WithPushIn';
import { DrawerComponent, DrawerComponentProps, DrawerContext } from 'context/DrawerContext';
import { ProjectContext } from 'context/ProjectContext';
import { File, FileItem, Note, NoteReference, Organization, Project, useQryNote } from 'graphql/generated';
import { useAddFileToItem } from 'graphql/mutation/useAddFileToItem';
import { useAddNoteToProject } from 'graphql/mutation/useAddNoteToProject';
import { useRemoveFileFromItem } from 'graphql/mutation/useRemoveFileFromItem';
import { useUpdateNote } from 'graphql/mutation/useUpdateNote';
import { differenceBy, isEqual, omit } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isNoteUserInput } from 'types/note';
import { NoteDetail } from './NoteDetail';
import { useTranslation } from 'react-i18next';
import { colors } from 'theme/colors';
import { Loader } from 'components/loader/loader';
import { useAddRefToNote } from 'graphql/mutation/useAddRefToNote';
import { format } from 'date-fns';
import { MinimizableDrawerContent } from 'components/MinimizableDrawerContent';
import useIsMobile from 'hooks/useIsMobile';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import { useProjectSensorLocationState } from 'hooks/useProjectSensorLocationState';
import { Location } from 'types/location';
import { useRemoveRefToNote } from 'graphql/mutation/useRemoveRefToNote';
import { useNoteContext } from 'context/NoteContext';

type NoteListDrawerArgs = {
  organizationId?: Organization['id'];
  projectId?: Project['id'];
  period?: { end: string; start: string };
  locations?: Location[];
};
import { useOrganization } from 'context/OrgContext';
import { useSnackbar } from 'components/Snackbar';
import { Severity } from 'state/snackbarStore';

export type NoteDrawerArgs = {
  noteId?: Note['id'];
  listArgs?: NoteListDrawerArgs;
  onClose?: () => void;
  afterSave?: () => void;
};

type UpdatedNote = Pick<Note, 'id' | 'title' | 'period' | 'description' | 'updatedAt'>;

const NoteDrawer: DrawerComponent<NoteDrawerArgs> = ({
  noteId,
  listArgs,
  requestClose,
  setGuarded,
  closePending,
  acceptClose,
  denyClose,
  onClose,
  afterSave,
}: NoteDrawerArgs & DrawerComponentProps) => {
  const { t } = useTranslation();
  const { requestDrawer } = useContext(DrawerContext);

  // Having no selectNoteId means creating a new note
  const isNewNote = noteId === undefined;
  const { organization: org, loading: loadOrg } = useOrganization();

  const { project, loading: loadProject } = useContext(ProjectContext);
  const [{ fetching: addingToProject }, addNoteToProject] = useAddNoteToProject();
  const [{ fetching: addingRefToNote }, addRefToNote] = useAddRefToNote();
  const [{ fetching: removingRefToNote }, removeRefFromNote] = useRemoveRefToNote();
  const [{ fetching: updating }, updateNote] = useUpdateNote();
  const [{ fetching: addingFile }, addFile] = useAddFileToItem();
  const [{ fetching: removingFile }, removeFile] = useRemoveFileFromItem();
  const { selectedNoteLocations, editedNote, initialNote, resetNoteState } = useNoteContext();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (listArgs && listArgs.locations) {
      resetNoteState(listArgs, listArgs.locations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listArgs]);

  // Since files are not stored on the note directly, it requires a special flow to keep the same editing UX.
  // This UX being: files can be uploaded+added/removed before the note is saved,
  // but these changes are only persisted when the user clicks the save button.
  // So we _have_ to keep a list of files to add and remove on save.
  const [filesToAdd, setFilesToAdd] = useState<File[]>([]);
  const [filesToRemove, setFilesToRemove] = useState<File[]>([]);

  const [{ data: note, fetching: loadingNote }, refetchNotes] = useQryNote(
    {
      id: true,
      title: true,
      description: true,
      owner: {
        id: true,
        firstName: true,
        lastName: true,
        email: true,
        createdAt: true,
      },
      period: {
        start: true,
        end: true,
      },
      createdAt: true,
    },
    { noteId: noteId ?? '' },
    {
      pause: noteId === undefined || org?.id === undefined,
    },
  );

  const { selectedLocations, setSelectedLocations } = useProjectSensorLocationState({
    projectId: project?.id ?? '',
  });

  useEffect(() => {
    if (selectedLocations.length === 0) {
      listArgs?.locations
        ? setSelectedLocations(listArgs?.locations)
        : selectedNoteLocations && setSelectedLocations(selectedNoteLocations);
    }
  });

  const saveNote = useCallback(async () => {
    if (isNoteUserInput(editedNote) && org !== undefined) {
      let savedNote: UpdatedNote | undefined = undefined;
      if ((editedNote?.period?.start ?? 0) > (editedNote?.period?.end ?? 0)) {
        snackbar.addAlert(t('The start date must be before the end date'), Severity.ERROR);
        return;
      }
      if (isNewNote) {
        if (!project) return;
        const newNoteResponse = await addNoteToProject({
          projectId: project.id,
          title: editedNote?.title,
          period: editedNote?.period,
          description: editedNote?.description,
        });
        selectedLocations.map((location) => {
          addRefToNote({
            data: {
              noteId: newNoteResponse.data?.addNoteToProject?.id,
              refId: location.id,
              ref: NoteReference.Location,
            },
          });
        });
        savedNote = newNoteResponse.data?.addNoteToProject;
      } else if (note !== undefined) {
        const updatedNoteResponse = await updateNote({
          uuid: note.id,
          title: editedNote?.title,
          period: editedNote?.period ? omit(editedNote?.period, '__typename') : undefined,
          description: editedNote?.description ?? undefined,
        });
        const locationsToRemove = differenceBy(listArgs?.locations, selectedLocations, 'id');
        const locationsToAdd = listArgs?.locations && differenceBy(selectedLocations, listArgs.locations, 'id');
        locationsToAdd?.map((location) => {
          addRefToNote({
            data: {
              noteId: updatedNoteResponse.data?.updateNote?.id,
              refId: location.id,
              ref: NoteReference.Location,
            },
          });
        });
        locationsToRemove?.map((location) => {
          removeRefFromNote({
            data: {
              noteId: updatedNoteResponse.data?.updateNote?.id,
              refId: location.id,
              ref: NoteReference.Location,
            },
          });
        });
        savedNote = updatedNoteResponse.data?.updateNote;
      }
      if (savedNote !== undefined) {
        const itemId = savedNote.id;
        const fileAddRequests = filesToAdd.map((file) =>
          addFile({
            fileId: file.id,
            item: FileItem.Note,
            itemId,
          }),
        );
        const fileRemoveRequests = filesToRemove.map((file) =>
          removeFile({
            fileId: file.id,
            item: FileItem.Note,
            itemId,
            organizationId: org.id,
          }),
        );
        await Promise.all([...fileAddRequests, ...fileRemoveRequests]);
        setFilesToAdd([]);
        setFilesToRemove([]);
        refetchNotes();
        afterSave?.();
        resetNoteState(editedNote, selectedLocations);
        if (isNewNote) {
          setGuarded(false);
          onClose?.();
          requestClose(false);
        } else {
          setGuarded(true);
          acceptClose();
          requestDrawer('note', { noteId: savedNote.id, ...editedNote }, true, true);
        }
      }
    }
  }, [
    editedNote,
    org,
    isNewNote,
    note,
    snackbar,
    t,
    project,
    addNoteToProject,
    selectedLocations,
    addRefToNote,
    updateNote,
    listArgs?.locations,
    removeRefFromNote,
    filesToAdd,
    filesToRemove,
    refetchNotes,
    afterSave,
    resetNoteState,
    addFile,
    removeFile,
    setGuarded,
    onClose,
    requestClose,
    acceptClose,
    requestDrawer,
  ]);

  const formatDate = useCallback(
    (date?: string | null) => (date ? format(new Date(date), 'yyyy-MM-dd HH:mm') : null),
    [],
  );

  const hasChanges = useMemo(
    () => ({
      title: (initialNote?.title ?? '') !== editedNote?.title,
      description: (initialNote?.description ?? '') !== editedNote?.description,
      start: formatDate(initialNote?.period?.start) !== formatDate(editedNote?.period?.start),
      end: formatDate(initialNote?.period?.end) !== formatDate(editedNote?.period?.end),
      files: filesToAdd.length > 0 || filesToRemove.length > 0,
      locations:
        !isNewNote &&
        selectedLocations.length > 0 &&
        !isEqual(
          selectedLocations.map((location) => location.id),
          (listArgs?.locations ?? selectedNoteLocations)?.map((location) => location.id),
        ),
    }),
    [
      initialNote,
      editedNote,
      formatDate,
      filesToAdd.length,
      filesToRemove.length,
      isNewNote,
      selectedLocations,
      listArgs?.locations,
      selectedNoteLocations,
    ],
  );

  const isDirty = isNewNote || Object.values(hasChanges).some(Boolean);
  const loading = loadingNote || loadOrg || loadProject;
  const saving = addingToProject || addingRefToNote || removingRefToNote || updating || addingFile || removingFile;

  useEffect(() => {
    setFilesToAdd([]);
    setFilesToRemove([]);
  }, [note]);

  useEffect(() => {
    setGuarded(isDirty);
  }, [isDirty, setGuarded]);

  const isMobile = useIsMobile();

  const handleOnClose = useCallback(
    (useGuard = false) => {
      if (!editedNote?.description) {
        setGuarded(false);
        onClose?.();
        requestClose(false);
        return;
      }

      onClose?.();
      requestClose(useGuard ?? !isDirty);
    },
    [editedNote?.description, isDirty, onClose, requestClose, setGuarded],
  );

  const handleOnDenyChanges = useCallback(() => {
    const initialDate = initialNote?.period?.start;
    initialDate &&
      updateNote({
        uuid: editedNote?.id ?? '',
        title: editedNote?.title,
        period: { start: initialDate, end: initialNote?.period?.end },
        description: editedNote?.description ?? undefined,
      });

    handleOnClose();
    acceptClose();
  }, [acceptClose, editedNote, handleOnClose, initialNote, updateNote]);

  const Main = (
    <WithPushIn
      width="drawer.note"
      pushInChildren={
        <GuardBox bg={colors.secondary[200]}>
          <Flex alignItems="center" justifyContent="space-between" gap="1rem">
            <span>{t('Discard changes?')}</span>
            <Flex alignItems="center" gap="0.5rem">
              <Button onClick={() => handleOnDenyChanges()} bg="note.200" color="note.600">
                {t('Yes')}
              </Button>
              <Button onClick={() => denyClose()} bg="note.600" color="black">
                {t('No')}
              </Button>
            </Flex>
          </Flex>
        </GuardBox>
      }
      showPushInChildren={closePending}
    >
      {loading ? (
        <Loader />
      ) : (
        <NoteDetail
          note={note}
          hasChanges={hasChanges}
          saving={saving}
          loading={loading}
          onSubmit={saveNote}
          onClickClose={handleOnClose}
          isNewNote={isNewNote}
          setSelectedLocations={setSelectedLocations}
          selectedLocations={selectedLocations}
        />
      )}
    </WithPushIn>
  );

  return isMobile ? (
    Main
  ) : (
    <MinimizableDrawerContent initiallyPinned={true} backgroundColor="note.100" borderColor="note.600">
      {{
        main: Main,
        minimized: (
          <Flex pt="3.875rem" justifyContent="center" color="note.600" minWidth="48px">
            <MdOutlineStickyNote2 size="32px" />
          </Flex>
        ),
      }}
    </MinimizableDrawerContent>
  );
};

NoteDrawer.isShareable = true;
NoteDrawer.shareableArgs = ['noteId'];

export { NoteDrawer };
