import { useMutation } from 'urql';
import { TemperatureHumidityView } from 'types/view/temperatureHumidityView';
import { DefaultQueryResponse } from 'graphql/query/default';
import { ViewType } from 'types';
import { SampleTime } from '@charphq/types';

const ADD_TEMPERATURE_HUMIDITY_VIEW_KEY = 'addTemperatureHumidityView';

const ADD_TEMPERATURE_HUMIDITY_VIEW = `
  mutation ($projectId: String!, $data: AddTemperatureHumidityViewInput!) {
    ${ADD_TEMPERATURE_HUMIDITY_VIEW_KEY}(uuid: $projectId, data: $data) {
      id
      name
      type
      updatedAt
    }
  }
`;

type MutVarAddTemperatureHumidityView = {
  projectId: string;
  data: {
    name: string;
    projectSensorIds: string[];
    autoAddNewDevices?: boolean;
    viewType: ViewType.DEW_POINT | ViewType.ABSOLUTE_HUMIDITY;
    sampleTime: SampleTime;
  };
};

type MutResAddTemperatureHumidityView = DefaultQueryResponse<
  typeof ADD_TEMPERATURE_HUMIDITY_VIEW_KEY,
  Pick<TemperatureHumidityView, 'id' | 'name' | 'type' | 'updatedAt' | 'projectId'>
>;

export const useAddTemperatureHumidityView = () => {
  return useMutation<MutResAddTemperatureHumidityView, MutVarAddTemperatureHumidityView>(ADD_TEMPERATURE_HUMIDITY_VIEW);
};
