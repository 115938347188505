import { useMemo } from 'react';
import { A, F } from 'ts-toolbelt';
import { useQuery, UseQueryArgs, UseQueryResponse } from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type QueryFields<QueryType, T extends GenerateQueryFields<QueryType>> = F.Exact<
  T,
  GenerateQueryFields<QueryType>
>;
export type QueryResponse<QueryType, T extends GenerateQueryFields<QueryType>> = A.Compute<
  GenerateQueryResponse<QueryType, T>
>;

type GenerateQueryFieldsTypeCheck<QueryTypeUnknown> = QueryTypeUnknown extends object
  ? GenerateQueryFieldsObject<QueryTypeUnknown>
  : QueryTypeUnknown extends string | number | boolean | null
  ? true
  : never;

type GenerateQueryFieldsObject<QueryObject> = {
  [key in keyof QueryObject]?: NonNullable<QueryObject[key]> extends Maybe<Array<infer Item>> | Array<infer Item>
    ? GenerateQueryFields<Item>
    : NonNullable<QueryObject[key]> extends object
    ? GenerateQueryFields<NonNullable<QueryObject[key]>>
    : true;
};

export type GenerateQueryFields<QueryType> = QueryType extends Array<infer QueryObject>
  ? GenerateQueryFieldsTypeCheck<QueryObject>
  : GenerateQueryFieldsTypeCheck<QueryType>;

export type QueryVarTypes<Tvar> = {
  [Tkey in keyof Required<Tvar>]: string;
};

type QueryVar<Tvar> = {
  values: Tvar;
  types: QueryVarTypes<Tvar>;
};

type GenerateQueryResponse<QueryType, QueryTypeProps> = QueryType extends Array<infer QueryObject>
  ? GenerateQueryResponseCheck<QueryObject, QueryTypeProps>[]
  : GenerateQueryResponseCheck<QueryType, QueryTypeProps>;

type GenerateQueryResponseCheck<QueryType, QueryTypeProps> = QueryType extends object
  ? GenerateQueryResponseObject<QueryType, QueryTypeProps>
  : QueryType;

type GenerateQueryResponseObject<QueryType, QueryTypeProps> = {
  [keyProps in keyof QueryTypeProps]-?: keyProps extends keyof QueryType
    ? NonNullable<QueryType[keyProps]> extends Array<infer TarrayType>
      ? GenerateQueryResponse<TarrayType, NonNullable<QueryTypeProps[keyProps]>>[]
      : NonNullable<QueryType[keyProps]> extends object
      ? GenerateQueryResponse<QueryType[keyProps], QueryTypeProps[keyProps]>
      : QueryType[keyProps]
    : never;
};

type RawQueryResponse<QueryKey extends string, Data = any> = {
  [key in QueryKey]: Data;
};

export const generateQuery = <TProps, TVars extends object>(
  key: string,
  props: GenerateQueryFieldsObject<TProps>,
  vars?: QueryVar<TVars>,
): string => {
  const queryProps = generateQueryProps(props);
  return `
    query ${vars ? `(${generateQueryVars(vars)})` : ''}{
      ${key} ${vars ? `(${generateKeyVars(vars.values)})` : ''}${queryProps ? '{' : ''}
        ${queryProps}
      ${queryProps ? '}' : ''} 
    }
  `;
};

const generateQueryVars = <TVars extends object>(vars: QueryVar<TVars>) => {
  let varsString = '';
  const lastKey = Object.keys(vars.values)[Object.keys(vars.values).length - 1];
  for (const key in vars.values) {
    const keyType = vars.types[key];
    varsString += `$${key}: ${keyType}${key !== lastKey ? ',' : ''}`;
  }
  return varsString;
};

const generateKeyVars = <TVars extends object>(values: TVars) => {
  let varsString = '';
  const lastKey = Object.keys(values)[Object.keys(values).length - 1];
  for (const key in values) {
    varsString += `${key}: $${key}${key !== lastKey ? ',' : ''}`;
  }
  return varsString;
};

const generateQueryProps = <T>(props: GenerateQueryFieldsObject<T>) => {
  let queryPropString = '';
  for (const key in props) {
    const currentProp = props[key];
    if (typeof currentProp === 'boolean') {
      if (currentProp) {
        queryPropString += `${key}, \n`;
      }
    } else if (currentProp !== undefined) {
      queryPropString += `${key} {\n${generateQueryProps(currentProp)}}, \n`;
    }
  }
  return queryPropString;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTimeISO: string;
  ISOdate: string;
};

export type AddAddonInput = {
  data: AddonDataInput;
  name: Scalars['String'];
  type: Scalars['String'];
};

export type AddAshrae2019ViewInput = {
  autoAddNewDevices?: InputMaybe<Scalars['Boolean']>;
  average?: InputMaybe<Ashrae2019AverageInput>;
  control: Ashrae2019TypeOfControl;
  name: Scalars['String'];
  projectSensorIds: Array<Scalars['String']>;
  sampleTime?: InputMaybe<Scalars['String']>;
};

export type AddDeviceInput = {
  connection: Scalars['String'];
  devEUI?: InputMaybe<Scalars['String']>;
  isGateway?: InputMaybe<Scalars['Boolean']>;
  model?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type AddDevicesLocationsInput = {
  connection: Scalars['String'];
  devEUIs?: InputMaybe<Array<Scalars['String']>>;
  externalIds?: InputMaybe<Array<Scalars['String']>>;
  model?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type AddExternalTagInput = {
  devEUI: Scalars['String'];
  externalId: Scalars['String'];
  provider: Scalars['String'];
  type: Scalars['String'];
};

export type AddFileInput = {
  contentType: Scalars['String'];
  name: Scalars['String'];
};

export type AddFileParserSettingsInput = {
  dateColumn?: InputMaybe<Scalars['Float']>;
  dateDelimiter?: InputMaybe<Scalars['String']>;
  dateFormat?: InputMaybe<Scalars['String']>;
  endDataRow?: InputMaybe<Scalars['Float']>;
  fileDelimiter: Scalars['String'];
  fileEncoding: Scalars['String'];
  name: Scalars['String'];
  sampleTime?: InputMaybe<Scalars['Float']>;
  startDataRow: Scalars['Float'];
  startDateOfSampleTime?: InputMaybe<Scalars['DateTimeISO']>;
  timeColumn?: InputMaybe<Scalars['Float']>;
  timeFormat?: InputMaybe<Scalars['String']>;
  utcOffset?: InputMaybe<Scalars['String']>;
};

export type AddFixturesInput = {
  shouldDrop: Scalars['Boolean'];
};

export type AddLightBudgetViewInput = {
  autoAddNewDevices?: InputMaybe<Scalars['Boolean']>;
  control: LightBudgetControlInput;
  name: Scalars['String'];
  period: PeriodInput;
  projectSensorIds: Array<Scalars['String']>;
  sampleTime?: InputMaybe<Scalars['String']>;
};

export type AddLocationInput = {
  colorIndex?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  roomId?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['String']>;
};

export type AddManualUploadInput = {
  deviceId: Scalars['String'];
  end?: InputMaybe<Scalars['DateTimeISO']>;
  fileId: Scalars['String'];
  fileParserSettingsId: Scalars['String'];
  sensors: Array<ManualUploadSensorInput>;
  start?: InputMaybe<Scalars['DateTimeISO']>;
};

export type AddMonitorViewInput = {
  autoAddNewDevices?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  period?: InputMaybe<PeriodInput>;
  projectSensorIds: Array<Scalars['String']>;
  sampleTime?: InputMaybe<Scalars['String']>;
  yDomain?: InputMaybe<Array<YDomainInput>>;
};

export type AddPlanInput = {
  name: Scalars['String'];
  order: Scalars['Int'];
  organizationId: Scalars['String'];
};

export type AddPointInput = {
  entityId: Scalars['String'];
  entityType: PointType;
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type AddProjectAddonInput = {
  addonId: Scalars['String'];
  data: AddonConfigurationDataInput;
};

export type AddProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type AddProjectUserInput = {
  id: Scalars['String'];
  role: Scalars['String'];
};

export type AddProjectUsersInput = {
  values: Array<AddProjectUserInput>;
};

export type AddRoomInput = {
  description?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  services?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  surfaceArea?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['String']>;
};

export type AddRuleInput = {
  comments?: InputMaybe<Scalars['String']>;
  downsample?: InputMaybe<Scalars['String']>;
  every: Scalars['String'];
  name: Scalars['String'];
  operator: Scalars['String'];
  optStatistic?: InputMaybe<Scalars['String']>;
  optThreshold?: InputMaybe<Scalars['Float']>;
  projectSensorId: Scalars['String'];
  statistic: Scalars['String'];
  threshold: Scalars['Float'];
  timeWindow: Scalars['String'];
};

export type AddSensorInput = {
  measurement: Scalars['String'];
  name: Scalars['String'];
  unit: Scalars['String'];
};

export type AddTemperatureHumidityViewInput = {
  autoAddNewDevices?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  projectSensorIds: Array<Scalars['String']>;
  sampleTime?: InputMaybe<Scalars['String']>;
  viewType: Scalars['String'];
};

export type AddVersaSenseVendorInput = {
  metadata: VersaSenseDataInput;
  name: Scalars['String'];
};

export type AddViewWithSensorsInput = {
  autoAddNewDevices?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  projectSensorIds: Array<Scalars['String']>;
  sampleTime?: InputMaybe<Scalars['String']>;
};

export type Addon = {
  __typename?: 'Addon';
  createdAt: Scalars['DateTimeISO'];
  data: AddonData;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type AddonConfigurationData = {
  __typename?: 'AddonConfigurationData';
  responseTime?: Maybe<Scalars['String']>;
};

export type AddonConfigurationDataInput = {
  responseTime?: InputMaybe<Scalars['String']>;
};

export type AddonData = {
  __typename?: 'AddonData';
  customerId: Scalars['String'];
};

export type AddonDataInput = {
  customerId: Scalars['String'];
};

/** A list of aggregate functions that can be used to query influx data. */
export enum Aggregate {
  Mean = 'MEAN',
}

export type Ashrae2019Average = {
  __typename?: 'Ashrae2019Average';
  hum?: Maybe<Scalars['Float']>;
  temp?: Maybe<Scalars['Float']>;
};

export type Ashrae2019AverageInput = {
  hum?: InputMaybe<Scalars['Float']>;
  temp?: InputMaybe<Scalars['Float']>;
};

/** The type of control for Ashrae 2019. */
export enum Ashrae2019TypeOfControl {
  A1 = 'A1',
  A2 = 'A2',
  Aa = 'AA',
  B = 'B',
  C = 'C',
  D = 'D',
}

export type Ashrae2019View = {
  __typename?: 'Ashrae2019View';
  autoAddNewDevices?: Maybe<Scalars['Boolean']>;
  average?: Maybe<Ashrae2019Average>;
  control: Ashrae2019TypeOfControl;
  id: Scalars['ID'];
  name: Scalars['String'];
  sensors: Array<ProjectSensor>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  version: AshraeVersion;
};

/** The version of Ashrae. */
export enum AshraeVersion {
  V2019 = 'V2019',
}

export type Auth0User = {
  __typename?: 'Auth0User';
  email: Scalars['String'];
  userId: Scalars['String'];
  userMfa: Scalars['Boolean'];
};

export type BooleanInput = {
  endDate?: InputMaybe<Scalars['DateTimeISO']>;
  item: Scalars['Boolean'];
};

export type Calibration = {
  __typename?: 'Calibration';
  calibrationSensors?: Maybe<Array<CalibrationSensor>>;
  createdAt: Scalars['DateTimeISO'];
  formula: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type CalibrationAndCalibrationSensorsInput = {
  calibration: CalibrationInput;
  calibrationSensor: CalibrationSensorWithoutCalibrationIdInput;
};

export type CalibrationFilterProps = {
  name?: InputMaybe<Scalars['String']>;
};

export type CalibrationInput = {
  formula: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
};

export type CalibrationSensor = {
  __typename?: 'CalibrationSensor';
  calibrationId: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  endDate?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['String'];
  sensorId: Scalars['String'];
  sensors?: Maybe<Array<Sensor>>;
  startDate?: Maybe<Scalars['DateTimeISO']>;
  updatedAt: Scalars['DateTimeISO'];
};

export type CalibrationSensorWithoutCalibrationIdInput = {
  endDate?: InputMaybe<Scalars['DateTimeISO']>;
  sensorIds: Array<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTimeISO']>;
};

export type Calibrations = {
  __typename?: 'Calibrations';
  count: Scalars['Int'];
  items: Array<Calibration>;
};

export type CascadeTimeInput = {
  item: Scalars['String'];
};

export type CreateSingleUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  organizationName: Scalars['String'];
  password: Scalars['String'];
};

export type Data = {
  __typename?: 'Data';
  time: Scalars['DateTimeISO'];
  value?: Maybe<Scalars['Float']>;
};

export type Device = {
  __typename?: 'Device';
  archivedAt?: Maybe<Scalars['DateTimeISO']>;
  battery?: Maybe<Scalars['Float']>;
  batteryRaw?: Maybe<Scalars['Float']>;
  connection: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  currentLocation?: Maybe<LocationReference>;
  devEUI?: Maybe<Scalars['String']>;
  externalTag?: Maybe<ExternalTag>;
  files?: Maybe<Array<File>>;
  id: Scalars['ID'];
  isGateway?: Maybe<Scalars['Boolean']>;
  lastActive?: Maybe<Scalars['DateTimeISO']>;
  locations?: Maybe<Array<LocationReference>>;
  model?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  sensors?: Maybe<Array<Sensor>>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type DeviceAndSensor = {
  __typename?: 'DeviceAndSensor';
  device: Device;
  sensor: Sensor;
};

export type DeviceData = {
  __typename?: 'DeviceData';
  sensorData?: Maybe<Array<SensorData>>;
};

export type DeviceDataInput = {
  end?: InputMaybe<Scalars['DateTimeISO']>;
  sensorIds: Array<Scalars['String']>;
  start: Scalars['DateTimeISO'];
};

export type DeviceFilterArgs = {
  and?: InputMaybe<DeviceFilterProps>;
  or?: InputMaybe<DeviceFilterProps>;
};

export type DeviceFilterProps = {
  archivedAt?: InputMaybe<Array<Scalars['String']>>;
  location?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export type DeviceOrderArgs = {
  createdAt?: InputMaybe<Order>;
  devEUI?: InputMaybe<Order>;
  location?: InputMaybe<Order>;
  name?: InputMaybe<Order>;
  type?: InputMaybe<Order>;
  updatedAt?: InputMaybe<Order>;
};

export type Devices = {
  __typename?: 'Devices';
  count: Scalars['Int'];
  items: Array<Device>;
};

export type EditExternalTagInput = {
  externalId: Scalars['String'];
  id: Scalars['String'];
};

export type EditFileStatusInput = {
  status: FileStatus;
};

export type EditLocationInput = {
  colorIndex?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  roomId?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['String']>;
};

export type EditMonitorViewInput = {
  autoAddNewDevices?: InputMaybe<Scalars['Boolean']>;
  sampleTime?: InputMaybe<Scalars['String']>;
};

export type EditOrganizationInput = {
  language?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type EditPlanInput = {
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['String']>;
};

export type EditPointInput = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<PointType>;
  x?: InputMaybe<Scalars['Float']>;
  y?: InputMaybe<Scalars['Float']>;
};

export type EditProjectUserRoleInput = {
  role: Scalars['String'];
};

export type EditRoomInput = {
  description?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  surfaceArea?: InputMaybe<Scalars['String']>;
  typeId?: InputMaybe<Scalars['String']>;
};

export type EditRuleInput = {
  comments?: InputMaybe<Scalars['String']>;
  downsample?: InputMaybe<Scalars['String']>;
  every?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  operator?: InputMaybe<Scalars['String']>;
  optStatistic?: InputMaybe<Scalars['String']>;
  optThreshold?: InputMaybe<Scalars['Float']>;
  projectSensorId?: InputMaybe<Scalars['String']>;
  statistic?: InputMaybe<Scalars['String']>;
  threshold?: InputMaybe<Scalars['Float']>;
  timeWindow?: InputMaybe<Scalars['String']>;
};

export type EditUserRoleInput = {
  role: Scalars['String'];
};

export type EditViewWithSensorsInput = {
  autoAddNewDevices?: InputMaybe<Scalars['Boolean']>;
  projectSensorIds: Array<Scalars['String']>;
};

export type ExternalTag = {
  __typename?: 'ExternalTag';
  createdAt: Scalars['DateTimeISO'];
  devEUI: Scalars['String'];
  externalId: Scalars['String'];
  id: Scalars['String'];
  provider: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type File = {
  __typename?: 'File';
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['ID'];
  mediaType: Scalars['String'];
  name: Scalars['String'];
  owner: User;
  status: FileStatus;
};

/** The item to which you want to add a file. */
export enum FileItem {
  Device = 'DEVICE',
  Location = 'LOCATION',
  Note = 'NOTE',
  Plan = 'PLAN',
}

export type FileItemInput = {
  fileId: Scalars['String'];
  item: FileItem;
  itemId: Scalars['String'];
};

export type FileParserSettings = {
  __typename?: 'FileParserSettings';
  createdAt: Scalars['DateTimeISO'];
  dateColumn?: Maybe<Scalars['Float']>;
  dateDelimiter?: Maybe<Scalars['String']>;
  dateFormat?: Maybe<Scalars['String']>;
  endDataRow?: Maybe<Scalars['Float']>;
  fileDelimiter: Scalars['String'];
  fileEncoding: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  sampleTime?: Maybe<Scalars['Float']>;
  startDataRow: Scalars['Float'];
  startDateOfSampleTime?: Maybe<Scalars['DateTimeISO']>;
  timeColumn?: Maybe<Scalars['Float']>;
  timeFormat?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeISO'];
  userId: Scalars['String'];
  utcOffset?: Maybe<Scalars['String']>;
};

/** The status of the file indicating if it is uploading or ready to be downloaded. */
export enum FileStatus {
  Created = 'CREATED',
  Error = 'ERROR',
  Ready = 'READY',
  Uploading = 'UPLOADING',
}

export type FileUpload = {
  __typename?: 'FileUpload';
  file: File;
  uploadLink: Scalars['String'];
};

export type GetProjectSensorByRuleInput = {
  analysisId?: InputMaybe<Scalars['String']>;
  ruleId: Scalars['String'];
};

export type InviteUserInput = {
  email: Scalars['String'];
  role: Scalars['String'];
};

export type LightBudgetControl = {
  __typename?: 'LightBudgetControl';
  createdAt: Scalars['DateTimeISO'];
  exposureTime: Scalars['Float'];
  id: Scalars['ID'];
  roleOfColor: Scalars['Float'];
  sensitivity: Sensitivity;
  sensor: ProjectSensor;
  targetLifetime: Scalars['Float'];
  updatedAt: Scalars['DateTimeISO'];
  uv: Scalars['Boolean'];
  viewId: Scalars['String'];
};

export type LightBudgetControlInput = {
  exposureTime: Scalars['Float'];
  roleOfColor: Scalars['Float'];
  sensitivity: Sensitivity;
  targetLifetime: Scalars['Float'];
  uv: Scalars['Boolean'];
};

export type LightBudgetView = {
  __typename?: 'LightBudgetView';
  autoAddNewDevices?: Maybe<Scalars['Boolean']>;
  controls: Array<LightBudgetControl>;
  description?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Device>>;
  endTime?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  period: Period;
  projectId: Scalars['String'];
  rules?: Maybe<Array<Rule>>;
  sensors?: Maybe<Array<ProjectSensor>>;
  startTime?: Maybe<Scalars['DateTimeISO']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type ListOfUuidArgs = {
  items: Array<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  colorIndex?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTimeISO'];
  description?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Device>>;
  files?: Maybe<Array<File>>;
  height?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  room?: Maybe<Room>;
  type?: Maybe<LocationType>;
  updatedAt: Scalars['DateTimeISO'];
};

export type LocationFilterArgs = {
  description?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type LocationOrderArgs = {
  description?: InputMaybe<Order>;
  height?: InputMaybe<Order>;
  name?: InputMaybe<Order>;
  room?: InputMaybe<Order>;
  type?: InputMaybe<Order>;
};

export type LocationReference = {
  __typename?: 'LocationReference';
  createdAt: Scalars['DateTimeISO'];
  device: Device;
  endDate?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['ID'];
  location: Location;
  locationId: Scalars['String'];
  refId: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type LocationType = {
  __typename?: 'LocationType';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  updatedAt: Scalars['DateTimeISO'];
};

export type LocationTypeInput = {
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Locations = {
  __typename?: 'Locations';
  count: Scalars['Int'];
  items: Array<Location>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ManualUpload = {
  __typename?: 'ManualUpload';
  createdAt: Scalars['DateTimeISO'];
  dataRow?: Maybe<Scalars['Float']>;
  dateColumn?: Maybe<Scalars['Float']>;
  dateDelimiter?: Maybe<Scalars['String']>;
  dateFormat?: Maybe<Scalars['String']>;
  device?: Maybe<Device>;
  deviceId?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTimeISO']>;
  file?: Maybe<File>;
  fileDelimiter?: Maybe<Scalars['String']>;
  fileEncoding?: Maybe<Scalars['String']>;
  fileId: Scalars['String'];
  fileParserSettings?: Maybe<FileParserSettings>;
  fileParserSettingsId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  sampleTime?: Maybe<Scalars['Float']>;
  sensors?: Maybe<Array<ManualUploadSensor>>;
  start?: Maybe<Scalars['DateTimeISO']>;
  startDate?: Maybe<Scalars['DateTimeISO']>;
  status: Scalars['String'];
  timeColumn?: Maybe<Scalars['Float']>;
  timeFormat?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTimeISO'];
  utcOffset?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type ManualUploadSensor = {
  __typename?: 'ManualUploadSensor';
  column: Scalars['Float'];
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['ID'];
  manualUploadId: Scalars['String'];
  sensor: Sensor;
  sensorId: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type ManualUploadSensorInput = {
  column: Scalars['Float'];
  sensorId: Scalars['String'];
};

export type MonitorView = {
  __typename?: 'MonitorView';
  autoAddNewDevices?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Device>>;
  endTime?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  period?: Maybe<Period>;
  projectId: Scalars['String'];
  rules?: Maybe<Array<Rule>>;
  sampleTime?: Maybe<Scalars['String']>;
  sensors: Array<ProjectSensor>;
  startTime?: Maybe<Scalars['DateTimeISO']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  yDomain?: Maybe<Array<YDomain>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptUserInvitation: Scalars['Boolean'];
  addAddon: Addon;
  addAlertView: View;
  addAshrae2019View: View;
  addCalibrationAndCalibrationSensors: Calibration;
  addCumulativeSumLightHoursView: View;
  addDevice: Device;
  addDevices: Array<Device>;
  addExternalTag: ExternalTag;
  addFile: FileUpload;
  addFileParserSettings: FileParserSettings;
  addFileToItem: File;
  addFixtures: Scalars['Boolean'];
  addLightBudgetControl: LightBudgetControl;
  addLightBudgetView: LightBudgetView;
  addLocation: Location;
  addLocationType: LocationType;
  addManualUpload: ManualUpload;
  addMonitorView: MonitorView;
  addNote: Note;
  addNoteToProject: Note;
  addPeriod: Period;
  addPlan: Plan;
  addPoint: Point;
  addProject: Project;
  addProjectAddon: ProjectAddon;
  addProjectSensors: Array<ProjectSensor>;
  addProjectUsers: Array<ProjectUser>;
  addRefToNote: Note;
  addRoom: Room;
  addRoomType: RoomType;
  addRule: Rule;
  addSensorToDevice: DeviceAndSensor;
  addSingleUserOrganization: TokenResponse;
  addTemperatureHumidityView: View;
  addUserInvitation: Scalars['Boolean'];
  addVersaSenseVendor: Vendor;
  archiveDeviceAndSensors: Scalars['Boolean'];
  confirmAlertNotification: Scalars['String'];
  deleteAlertView: Scalars['Boolean'];
  deleteAshraeView: Scalars['String'];
  deleteCalibration: Scalars['String'];
  deleteCalibrationSensorBySensorAndCalibration: Scalars['String'];
  deleteCumulativeSumLightHoursView: Scalars['String'];
  deleteDevices: Scalars['Boolean'];
  deleteFile: Scalars['String'];
  deleteGroupedRules: Scalars['String'];
  deleteInvitation: UserInvitation;
  deleteLightBudgetControl: Scalars['String'];
  deleteLightBudgetView: Scalars['String'];
  deleteLocation: Scalars['String'];
  deleteLocationType: Scalars['String'];
  deleteLocations: Array<Scalars['String']>;
  deleteMonitorView: Scalars['Boolean'];
  deleteNote: Scalars['String'];
  deleteOrganizationUser: Scalars['Boolean'];
  deletePeriod: Scalars['String'];
  deletePlan: Scalars['String'];
  deletePoint: Scalars['String'];
  deleteProject: Scalars['String'];
  deleteProjectAddon: Scalars['String'];
  deleteProjectSensor: Scalars['String'];
  deleteProjectUser: Scalars['Boolean'];
  deleteRoom: Scalars['String'];
  deleteRoomType: Scalars['String'];
  deleteRule: Scalars['String'];
  deleteRules: Array<Scalars['String']>;
  deleteTemperatureHumidityView: Scalars['String'];
  downloadDeviceData: DeviceData;
  editAddonCustomerId: Addon;
  editAshrae2019View: View;
  editCalibration: Calibration;
  editCumulativeSumLightHoursSensors: View;
  editDeviceLocation: Device;
  editDeviceName: Device;
  editExternalTag: ExternalTag;
  editFileStatus: File;
  editLightBudgetControl: LightBudgetControl;
  editLightBudgetView: LightBudgetView;
  editLocation: Location;
  editLocationType: LocationType;
  editMonitorView: MonitorView;
  editMonitorViewSensors: MonitorView;
  editMonitorViewYDomain: MonitorView;
  editOrganization: Organization;
  editPeriod: Period;
  editPlan: Plan;
  editPoint: Point;
  editProjectAddonActive: ProjectAddon;
  editProjectAddonResponseTime: ProjectAddon;
  editProjectAlert: Project;
  editProjectAlertCascadeOrder: Project;
  editProjectAlertCascadeTime: Project;
  editProjectDescription: Project;
  editProjectIsShareable: Project;
  editProjectMaintenance: Project;
  editProjectName: Project;
  editProjectNotificationConfigurationValue: ProjectNotificationConfiguration;
  editProjectUserCommunicationChannels: ProjectUser;
  editProjectUserRole: ProjectUser;
  editRoom: Room;
  editRoomType: RoomType;
  editRule: Rule;
  editSensorName: Sensor;
  editTemperatureHumidityView: View;
  editUser: User;
  editUserRole: User;
  editViewName: View;
  inviteUser: UserInvitation;
  login: TokenResponse;
  publicAddNewUserInvitation: TokenResponse;
  publicRejectInvitation: Scalars['Boolean'];
  rejectInvitation: Scalars['Boolean'];
  removeFileFromItem: File;
  removeRefFromNote: Note;
  selectOrganization: User;
  swapDevices: Scalars['Boolean'];
  toggleMFAForUser: UpdateUserResponse;
  updateNote: Note;
  verifyMFA: TokenResponse;
  verifyOobCode: Scalars['String'];
};

export type MutationAcceptUserInvitationArgs = {
  token: Scalars['String'];
};

export type MutationAddAddonArgs = {
  data: AddAddonInput;
};

export type MutationAddAlertViewArgs = {
  data: TextInput;
  uuid: Scalars['String'];
};

export type MutationAddAshrae2019ViewArgs = {
  data: AddAshrae2019ViewInput;
  uuid: Scalars['String'];
};

export type MutationAddCalibrationAndCalibrationSensorsArgs = {
  data: CalibrationAndCalibrationSensorsInput;
};

export type MutationAddCumulativeSumLightHoursViewArgs = {
  data: AddViewWithSensorsInput;
  uuid: Scalars['String'];
};

export type MutationAddDeviceArgs = {
  data: AddDeviceInput;
  organizationId: Scalars['String'];
};

export type MutationAddDevicesArgs = {
  data: AddDevicesLocationsInput;
  organizationId: Scalars['String'];
};

export type MutationAddExternalTagArgs = {
  data: AddExternalTagInput;
};

export type MutationAddFileArgs = {
  data: AddFileInput;
  organizationId: Scalars['String'];
};

export type MutationAddFileParserSettingsArgs = {
  data: AddFileParserSettingsInput;
  organizationId: Scalars['String'];
};

export type MutationAddFileToItemArgs = {
  data: FileItemInput;
};

export type MutationAddFixturesArgs = {
  data: AddFixturesInput;
};

export type MutationAddLightBudgetControlArgs = {
  data: LightBudgetControlInput;
  projectSensorId: Scalars['String'];
  viewId: Scalars['String'];
};

export type MutationAddLightBudgetViewArgs = {
  data: AddLightBudgetViewInput;
  uuid: Scalars['String'];
};

export type MutationAddLocationArgs = {
  data: AddLocationInput;
  organizationId: Scalars['String'];
};

export type MutationAddLocationTypeArgs = {
  data: LocationTypeInput;
};

export type MutationAddManualUploadArgs = {
  data: AddManualUploadInput;
  organizationId: Scalars['String'];
};

export type MutationAddMonitorViewArgs = {
  data: AddMonitorViewInput;
  uuid: Scalars['String'];
};

export type MutationAddNoteArgs = {
  data: AddNoteInput;
  uuid: Scalars['String'];
};

export type MutationAddNoteToProjectArgs = {
  data: AddNoteInput;
  uuid: Scalars['String'];
};

export type MutationAddPeriodArgs = {
  data: PeriodInput;
  organizationId: Scalars['String'];
  viewId: Scalars['String'];
};

export type MutationAddPlanArgs = {
  data: AddPlanInput;
};

export type MutationAddPointArgs = {
  data: AddPointInput;
  planId: Scalars['String'];
};

export type MutationAddProjectArgs = {
  data: AddProjectInput;
};

export type MutationAddProjectAddonArgs = {
  data: AddProjectAddonInput;
  uuid: Scalars['String'];
};

export type MutationAddProjectSensorsArgs = {
  data: ListOfUuidArgs;
  uuid: Scalars['String'];
};

export type MutationAddProjectUsersArgs = {
  data: AddProjectUsersInput;
  uuid: Scalars['String'];
};

export type MutationAddRefToNoteArgs = {
  data: NoteRefInput;
};

export type MutationAddRoomArgs = {
  data: AddRoomInput;
};

export type MutationAddRoomTypeArgs = {
  data: RoomTypeInput;
  organizationId: Scalars['String'];
};

export type MutationAddRuleArgs = {
  data: AddRuleInput;
  uuid: Scalars['String'];
};

export type MutationAddSensorToDeviceArgs = {
  data: AddSensorInput;
  deviceId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type MutationAddSingleUserOrganizationArgs = {
  data: CreateSingleUserInput;
};

export type MutationAddTemperatureHumidityViewArgs = {
  data: AddTemperatureHumidityViewInput;
  uuid: Scalars['String'];
};

export type MutationAddUserInvitationArgs = {
  token: Scalars['String'];
};

export type MutationAddVersaSenseVendorArgs = {
  data: AddVersaSenseVendorInput;
};

export type MutationArchiveDeviceAndSensorsArgs = {
  deviceId: Scalars['String'];
};

export type MutationConfirmAlertNotificationArgs = {
  alertId: Scalars['String'];
  uuid: Scalars['String'];
};

export type MutationDeleteAlertViewArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteAshraeViewArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteCalibrationArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteCalibrationSensorBySensorAndCalibrationArgs = {
  calibrationId: Scalars['String'];
  sensorId: Scalars['String'];
};

export type MutationDeleteCumulativeSumLightHoursViewArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteDevicesArgs = {
  deviceIds: Array<Scalars['String']>;
};

export type MutationDeleteFileArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteGroupedRulesArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteInvitationArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteLightBudgetControlArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteLightBudgetViewArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteLocationArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteLocationTypeArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteLocationsArgs = {
  locationIds: Array<Scalars['String']>;
};

export type MutationDeleteMonitorViewArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteNoteArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteOrganizationUserArgs = {
  organizationId: Scalars['String'];
  userId: Scalars['String'];
};

export type MutationDeletePeriodArgs = {
  uuid: Scalars['String'];
};

export type MutationDeletePlanArgs = {
  planId: Scalars['String'];
};

export type MutationDeletePointArgs = {
  pointId: Scalars['String'];
};

export type MutationDeleteProjectArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteProjectAddonArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteProjectSensorArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteProjectUserArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteRoomArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteRoomTypeArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteRuleArgs = {
  uuid: Scalars['String'];
};

export type MutationDeleteRulesArgs = {
  query: ListOfUuidArgs;
};

export type MutationDeleteTemperatureHumidityViewArgs = {
  uuid: Scalars['String'];
};

export type MutationDownloadDeviceDataArgs = {
  data: DeviceDataInput;
};

export type MutationEditAddonCustomerIdArgs = {
  data: StringInput;
  uuid: Scalars['String'];
};

export type MutationEditAshrae2019ViewArgs = {
  data: AddAshrae2019ViewInput;
  uuid: Scalars['String'];
};

export type MutationEditCalibrationArgs = {
  data: CalibrationAndCalibrationSensorsInput;
  uuid: Scalars['String'];
};

export type MutationEditCumulativeSumLightHoursSensorsArgs = {
  data: EditViewWithSensorsInput;
  uuid: Scalars['String'];
};

export type MutationEditDeviceLocationArgs = {
  deviceId: Scalars['String'];
  locationId?: InputMaybe<Scalars['String']>;
};

export type MutationEditDeviceNameArgs = {
  data: StringInput;
  uuid: Scalars['String'];
};

export type MutationEditExternalTagArgs = {
  data: EditExternalTagInput;
};

export type MutationEditFileStatusArgs = {
  data: EditFileStatusInput;
  uuid: Scalars['String'];
};

export type MutationEditLightBudgetControlArgs = {
  controlId: Scalars['String'];
  data: LightBudgetControlInput;
};

export type MutationEditLightBudgetViewArgs = {
  data: AddLightBudgetViewInput;
  uuid: Scalars['String'];
};

export type MutationEditLocationArgs = {
  data: EditLocationInput;
  uuid: Scalars['String'];
};

export type MutationEditLocationTypeArgs = {
  data: LocationTypeInput;
  uuid: Scalars['String'];
};

export type MutationEditMonitorViewArgs = {
  data: EditMonitorViewInput;
  uuid: Scalars['String'];
};

export type MutationEditMonitorViewSensorsArgs = {
  query: ListOfUuidArgs;
  uuid: Scalars['String'];
};

export type MutationEditMonitorViewYDomainArgs = {
  uuid: Scalars['String'];
  yDomain?: InputMaybe<Array<YDomainInput>>;
};

export type MutationEditOrganizationArgs = {
  data: EditOrganizationInput;
};

export type MutationEditPeriodArgs = {
  data: PeriodInput;
  periodId: Scalars['String'];
};

export type MutationEditPlanArgs = {
  data: EditPlanInput;
  planId: Scalars['String'];
};

export type MutationEditPointArgs = {
  data: EditPointInput;
  pointId: Scalars['String'];
};

export type MutationEditProjectAddonActiveArgs = {
  data: BooleanInput;
  uuid: Scalars['String'];
};

export type MutationEditProjectAddonResponseTimeArgs = {
  data: ProjectAddonResponseTimeInput;
  uuid: Scalars['String'];
};

export type MutationEditProjectAlertArgs = {
  data: BooleanInput;
  uuid: Scalars['String'];
};

export type MutationEditProjectAlertCascadeOrderArgs = {
  data: ListOfUuidArgs;
  uuid: Scalars['String'];
};

export type MutationEditProjectAlertCascadeTimeArgs = {
  data: CascadeTimeInput;
  uuid: Scalars['String'];
};

export type MutationEditProjectDescriptionArgs = {
  data: TextInput;
  uuid: Scalars['String'];
};

export type MutationEditProjectIsShareableArgs = {
  isShareable: Scalars['Boolean'];
  uuid: Scalars['String'];
};

export type MutationEditProjectMaintenanceArgs = {
  data: BooleanInput;
  uuid: Scalars['String'];
};

export type MutationEditProjectNameArgs = {
  data: StringInput;
  uuid: Scalars['String'];
};

export type MutationEditProjectNotificationConfigurationValueArgs = {
  data: UpdateProjectNotificationConfigurationInput;
  uuid: Scalars['String'];
};

export type MutationEditProjectUserCommunicationChannelsArgs = {
  channels: Array<Scalars['String']>;
  uuid: Scalars['String'];
};

export type MutationEditProjectUserRoleArgs = {
  data: EditProjectUserRoleInput;
  uuid: Scalars['String'];
};

export type MutationEditRoomArgs = {
  data: EditRoomInput;
  uuid: Scalars['String'];
};

export type MutationEditRoomTypeArgs = {
  data: RoomTypeInput;
  uuid: Scalars['String'];
};

export type MutationEditRuleArgs = {
  data: EditRuleInput;
  uuid: Scalars['String'];
};

export type MutationEditSensorNameArgs = {
  data: StringInput;
  uuid: Scalars['String'];
};

export type MutationEditTemperatureHumidityViewArgs = {
  data: AddTemperatureHumidityViewInput;
  uuid: Scalars['String'];
};

export type MutationEditUserArgs = {
  data: UpdateUserInput;
};

export type MutationEditUserRoleArgs = {
  data: EditUserRoleInput;
  uuid: Scalars['String'];
};

export type MutationEditViewNameArgs = {
  data: StringInput;
  uuid: Scalars['String'];
};

export type MutationInviteUserArgs = {
  data: InviteUserInput;
};

export type MutationLoginArgs = {
  data: LoginInput;
};

export type MutationPublicAddNewUserInvitationArgs = {
  data: NewUserInvitationInput;
};

export type MutationPublicRejectInvitationArgs = {
  uuid: Scalars['String'];
};

export type MutationRejectInvitationArgs = {
  uuid: Scalars['String'];
};

export type MutationRemoveFileFromItemArgs = {
  data: FileItemInput;
};

export type MutationRemoveRefFromNoteArgs = {
  data: NoteRefInput;
};

export type MutationSelectOrganizationArgs = {
  organizationId: Scalars['String'];
};

export type MutationSwapDevicesArgs = {
  newDeviceId: Scalars['String'];
  oldDeviceId: Scalars['String'];
};

export type MutationToggleMfaForUserArgs = {
  accessToken: Scalars['String'];
  enableMFA: Scalars['Boolean'];
  userId: Scalars['String'];
};

export type MutationUpdateNoteArgs = {
  data: UpdateNoteInput;
  uuid: Scalars['String'];
};

export type MutationVerifyMfaArgs = {
  mfaToken: Scalars['String'];
  oobCode: Scalars['String'];
  otp: Scalars['String'];
};

export type MutationVerifyOobCodeArgs = {
  email: Scalars['String'];
  mfaToken: Scalars['String'];
};

export type NewUserInvitationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type Note = {
  __typename?: 'Note';
  createdAt: Scalars['DateTimeISO'];
  description?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Device>>;
  files?: Maybe<Array<File>>;
  id: Scalars['ID'];
  locations?: Maybe<Array<Location>>;
  organization: Organization;
  owner: User;
  period?: Maybe<SimplePeriod>;
  project?: Maybe<Project>;
  projects?: Maybe<Array<Project>>;
  sensors?: Maybe<Array<Sensor>>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  views?: Maybe<Array<View>>;
};

export type NoteRefInput = {
  noteId: Scalars['String'];
  ref: NoteReference;
  refId: Scalars['String'];
};

/** The different references which can be added to a note. */
export enum NoteReference {
  Device = 'DEVICE',
  Location = 'LOCATION',
  Project = 'PROJECT',
  Sensor = 'SENSOR',
  View = 'VIEW',
}

export type NoteReferencesInput = {
  devices?: InputMaybe<Array<Scalars['String']>>;
  projects?: InputMaybe<Array<Scalars['String']>>;
  sensors?: InputMaybe<Array<Scalars['String']>>;
  views?: InputMaybe<Array<Scalars['String']>>;
};

export type Notification = {
  __typename?: 'Notification';
  cause?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  id: Scalars['ID'];
  message: Scalars['String'];
  reference?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
  user?: Maybe<User>;
};

/** The order to sort properties of objects */
export enum Order {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}

export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['DateTimeISO'];
  email: Scalars['String'];
  id: Scalars['ID'];
  language: Scalars['String'];
  name: Scalars['String'];
  subscription?: Maybe<Array<Scalars['String']>>;
  timezone?: Maybe<Scalars['String']>;
  users?: Maybe<Array<User>>;
};

export type PaginationArgs = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type Period = {
  __typename?: 'Period';
  createdAt: Scalars['DateTimeISO'];
  end?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['ID'];
  organization: Organization;
  organizationId: Scalars['String'];
  start: Scalars['DateTimeISO'];
  updatedAt: Scalars['DateTimeISO'];
  view: View;
  viewId: Scalars['String'];
};

export type PeriodInput = {
  end?: InputMaybe<Scalars['ISOdate']>;
  start: Scalars['ISOdate'];
};

export type Plan = {
  __typename?: 'Plan';
  createdAt: Scalars['DateTimeISO'];
  files?: Maybe<Array<File>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  organization: Organization;
  updatedAt: Scalars['DateTimeISO'];
};

export type Point = {
  __typename?: 'Point';
  createdAt: Scalars['DateTimeISO'];
  entityId: Scalars['String'];
  entityType: PointType;
  id: Scalars['ID'];
  planId: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  x: Scalars['Float'];
  y: Scalars['Float'];
};

/** The device (or future gateway) to which the point is linked. */
export enum PointType {
  Device = 'DEVICE',
}

export type PointsFilterArgs = {
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<PointType>;
};

export type Project = {
  __typename?: 'Project';
  addons?: Maybe<Array<ProjectAddon>>;
  alert: Scalars['Boolean'];
  alertCascadeOrder?: Maybe<Array<Scalars['String']>>;
  alertCascadeTime?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  description?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isShareable: Scalars['Boolean'];
  maintenance: Scalars['Boolean'];
  maintenanceEndDate?: Maybe<Scalars['DateTimeISO']>;
  name: Scalars['String'];
  notes?: Maybe<Array<Note>>;
  notificationConfigurations?: Maybe<Array<ProjectNotificationConfiguration>>;
  notifications?: Maybe<Array<Notification>>;
  organizationId: Scalars['String'];
  sensorPages?: Maybe<ProjectSensors>;
  sensors?: Maybe<Array<ProjectSensor>>;
  updatedAt: Scalars['DateTimeISO'];
  users?: Maybe<Array<ProjectUser>>;
  views?: Maybe<Array<View>>;
};

export type ProjectNotificationsArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type ProjectSensorPagesArgs = {
  filter?: InputMaybe<ProjectSensorFilterArgs>;
  order?: InputMaybe<ProjectSensorOrderArgs>;
  page?: InputMaybe<PaginationArgs>;
};

export type ProjectSensorsArgs = {
  filter?: InputMaybe<ProjectSensorFilterArgs>;
  order?: InputMaybe<ProjectSensorOrderArgs>;
  page?: InputMaybe<PaginationArgs>;
};

export type ProjectAddon = {
  __typename?: 'ProjectAddon';
  active: Scalars['Boolean'];
  addon: Addon;
  addonId: Scalars['String'];
  createdAt: Scalars['DateTimeISO'];
  data: AddonConfigurationData;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTimeISO'];
};

export type ProjectAddonResponseTimeInput = {
  responseTime?: InputMaybe<Scalars['String']>;
};

export type ProjectNotificationConfiguration = {
  __typename?: 'ProjectNotificationConfiguration';
  id: Scalars['ID'];
  type: ProjectNotificationType;
  value: ProjectNotificationTime;
};

/** The possible notification intervals */
export enum ProjectNotificationTime {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Never = 'NEVER',
  TwiceADay = 'TWICE_A_DAY',
  Week = 'WEEK',
}

/** The possible project notification types */
export enum ProjectNotificationType {
  Alert = 'ALERT',
}

export type ProjectSensor = {
  __typename?: 'ProjectSensor';
  battery?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTimeISO'];
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  location?: Maybe<Location>;
  locationReference?: Maybe<LocationReference>;
  measurement: Scalars['String'];
  name: Scalars['String'];
  sensor?: Maybe<Sensor>;
  sensorId: Scalars['String'];
  unit: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  value?: Maybe<Data>;
};

export type ProjectSensorFilterArgs = {
  location?: InputMaybe<Scalars['String']>;
  measurement?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProjectSensorOrderArgs = {
  location?: InputMaybe<Order>;
  measurement?: InputMaybe<Order>;
  name?: InputMaybe<Order>;
  updatedAt?: InputMaybe<Order>;
};

export type ProjectSensorWithRule = {
  __typename?: 'ProjectSensorWithRule';
  battery?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTimeISO'];
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  location?: Maybe<Location>;
  locationReference?: Maybe<LocationReference>;
  measurement: Scalars['String'];
  name: Scalars['String'];
  ruleId: Scalars['ID'];
  sensor?: Maybe<Sensor>;
  sensorId: Scalars['String'];
  unit: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  value?: Maybe<Data>;
};

export type ProjectSensors = {
  __typename?: 'ProjectSensors';
  count: Scalars['Int'];
  items: Array<ProjectSensor>;
};

export type ProjectUser = {
  __typename?: 'ProjectUser';
  communicationChannel: Array<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  userId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  addon: Addon;
  addons: Array<Addon>;
  addonsOfType: Array<Addon>;
  ashrae2019: Ashrae2019View;
  auth0UsersByOrganization: Array<Auth0User>;
  calibration: Calibration;
  calibrationPages: Calibrations;
  calibrationSensors: Array<CalibrationSensor>;
  calibrations: Array<Calibration>;
  device: Device;
  devicePages: Devices;
  devices: Array<Device>;
  devicesWithoutLocation: Array<Device>;
  externalTagByDevEui: ExternalTag;
  file: File;
  fileDownloadLink: Scalars['String'];
  fileUploadLink: File;
  files: Array<File>;
  getAlertNotifications: Array<Notification>;
  getAvailableLocations: Array<Location>;
  getData: Array<Data>;
  getLightBudgetControlsBy: Array<LightBudgetControl>;
  getLocationReferencesByLocationId: Array<LocationReference>;
  getLocationsByProjectSensors: Array<Location>;
  getManualUploadsByDevice: Array<ManualUpload>;
  getManualUploadsByOrganization: Array<ManualUpload>;
  getNotesBy: Array<Note>;
  getNotesByOrganization: Array<Note>;
  getNotesByRefIds: Array<Note>;
  getPeriodsBy: Array<Period>;
  getPlan: Plan;
  getPlansByOrganization: Array<Plan>;
  getPoints: Array<Point>;
  getProjectSensorsByLocations: Array<ProjectSensor>;
  getProjectSensorsByProjectId: Array<ProjectSensor>;
  getProjectUserByProjectAndUser: ProjectUser;
  getSensorData: Array<Data>;
  getTemperatureHumidityView: TemperatureHumidityView;
  getUserMfa: Scalars['Boolean'];
  invitation: UserInvitation;
  invitations: Array<UserInvitation>;
  lightBudgetControl: LightBudgetControl;
  lightBudgetView: LightBudgetView;
  location: Location;
  locationPage: Locations;
  locationType: LocationType;
  locationTypes: Array<LocationType>;
  locations: Array<Location>;
  manualUpload: ManualUpload;
  me: User;
  monitorView: MonitorView;
  note: Note;
  notifications: Array<Notification>;
  organization: Organization;
  organizations: Array<Organization>;
  period: Period;
  project: Project;
  projectAddonsByProjectAndType: Array<ProjectAddon>;
  projectSensorsByRule: Array<ProjectSensorWithRule>;
  projectUserRole: Scalars['String'];
  projects: Array<Project>;
  publicInvitation: UserInvitation;
  querySensorData: Array<Data>;
  room: Room;
  roomPage: Rooms;
  roomType: RoomType;
  roomTypes: Array<RoomType>;
  rooms: Array<Room>;
  rule: Rule;
  ruleTemplates: Array<Rule>;
  sensor: Sensor;
  sensorPages: Sensors;
  sensorUnassignedPages: Sensors;
  sensors: Array<Sensor>;
  userRole: Scalars['String'];
  users: Array<User>;
  vendor: Vendor;
  vendors: Array<Vendor>;
  view: View;
};

export type QueryAddonArgs = {
  uuid: Scalars['String'];
};

export type QueryAddonsOfTypeArgs = {
  type: Scalars['String'];
};

export type QueryAshrae2019Args = {
  uuid: Scalars['String'];
};

export type QueryCalibrationArgs = {
  uuid: Scalars['String'];
};

export type QueryCalibrationPagesArgs = {
  filter?: InputMaybe<CalibrationFilterProps>;
  page?: InputMaybe<PaginationArgs>;
};

export type QueryCalibrationSensorsArgs = {
  calibrationId: Scalars['String'];
};

export type QueryDeviceArgs = {
  uuid: Scalars['String'];
};

export type QueryDevicePagesArgs = {
  filter?: InputMaybe<DeviceFilterArgs>;
  isGateway?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<DeviceOrderArgs>;
  page?: InputMaybe<PaginationArgs>;
};

export type QueryExternalTagByDevEuiArgs = {
  devEUI: Scalars['String'];
};

export type QueryFileArgs = {
  uuid: Scalars['String'];
};

export type QueryFileDownloadLinkArgs = {
  uuid: Scalars['String'];
};

export type QueryFileUploadLinkArgs = {
  uuid: Scalars['String'];
};

export type QueryFilesArgs = {
  noteId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
};

export type QueryGetAlertNotificationsArgs = {
  alertId: Scalars['String'];
  uuid: Scalars['String'];
};

export type QueryGetAvailableLocationsArgs = {
  organizationId: Scalars['String'];
};

export type QueryGetDataArgs = {
  sensorId: Scalars['String'];
};

export type QueryGetLightBudgetControlsByArgs = {
  projectSensorId?: InputMaybe<Scalars['String']>;
  viewId?: InputMaybe<Scalars['String']>;
};

export type QueryGetLocationReferencesByLocationIdArgs = {
  locationId: Scalars['String'];
};

export type QueryGetLocationsByProjectSensorsArgs = {
  filter?: InputMaybe<Scalars['String']>;
  projectId: Scalars['String'];
  projectSensorIds: Array<Scalars['String']>;
  viewType: Scalars['String'];
};

export type QueryGetManualUploadsByDeviceArgs = {
  deviceId: Scalars['String'];
};

export type QueryGetManualUploadsByOrganizationArgs = {
  organizationId: Scalars['String'];
};

export type QueryGetNotesByArgs = {
  deviceId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
  sensorId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
  viewId?: InputMaybe<Scalars['String']>;
};

export type QueryGetNotesByOrganizationArgs = {
  organizationId: Scalars['String'];
};

export type QueryGetNotesByRefIdsArgs = {
  refIds: Array<Scalars['String']>;
};

export type QueryGetPeriodsByArgs = {
  organizationId?: InputMaybe<Scalars['String']>;
  viewId?: InputMaybe<Scalars['String']>;
};

export type QueryGetPlanArgs = {
  planId: Scalars['String'];
};

export type QueryGetPlansByOrganizationArgs = {
  organizationId: Scalars['String'];
};

export type QueryGetPointsArgs = {
  filter?: InputMaybe<PointsFilterArgs>;
  planId: Scalars['String'];
};

export type QueryGetProjectSensorsByLocationsArgs = {
  locationIds: Array<Scalars['String']>;
  projectId: Scalars['String'];
};

export type QueryGetProjectSensorsByProjectIdArgs = {
  projectId: Scalars['String'];
};

export type QueryGetProjectUserByProjectAndUserArgs = {
  userId: Scalars['String'];
  uuid: Scalars['String'];
};

export type QueryGetSensorDataArgs = {
  uuid: Scalars['String'];
};

export type QueryGetTemperatureHumidityViewArgs = {
  uuid: Scalars['String'];
};

export type QueryGetUserMfaArgs = {
  accessToken: Scalars['String'];
  userId: Scalars['String'];
};

export type QueryInvitationArgs = {
  token: Scalars['String'];
};

export type QueryLightBudgetControlArgs = {
  controlId: Scalars['String'];
};

export type QueryLightBudgetViewArgs = {
  uuid: Scalars['String'];
};

export type QueryLocationArgs = {
  locationId: Scalars['String'];
};

export type QueryLocationPageArgs = {
  filter?: InputMaybe<LocationFilterArgs>;
  order?: InputMaybe<LocationOrderArgs>;
  organizationId: Scalars['String'];
  page?: InputMaybe<PaginationArgs>;
};

export type QueryLocationTypeArgs = {
  locationId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type QueryManualUploadArgs = {
  organizationId: Scalars['String'];
  uploadId: Scalars['String'];
};

export type QueryMonitorViewArgs = {
  uuid: Scalars['String'];
};

export type QueryNoteArgs = {
  noteId: Scalars['String'];
};

export type QueryNotificationsArgs = {
  cursor?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type QueryPeriodArgs = {
  organizationId: Scalars['String'];
  periodId: Scalars['String'];
};

export type QueryProjectArgs = {
  uuid: Scalars['String'];
};

export type QueryProjectAddonsByProjectAndTypeArgs = {
  type: Scalars['String'];
  uuid: Scalars['String'];
};

export type QueryProjectSensorsByRuleArgs = {
  data: GetProjectSensorByRuleInput;
};

export type QueryProjectUserRoleArgs = {
  projectId: Scalars['String'];
  uuid: Scalars['String'];
};

export type QueryPublicInvitationArgs = {
  token: Scalars['String'];
};

export type QueryQuerySensorDataArgs = {
  query: QuerySensorDataInput;
};

export type QueryRoomArgs = {
  roomId: Scalars['String'];
};

export type QueryRoomPageArgs = {
  filter?: InputMaybe<RoomFilterArgs>;
  order?: InputMaybe<RoomOrderArgs>;
  organizationId: Scalars['String'];
  page?: InputMaybe<PaginationArgs>;
};

export type QueryRoomTypeArgs = {
  locationId: Scalars['String'];
  organizationId: Scalars['String'];
};

export type QueryRoomTypesArgs = {
  organizationId: Scalars['String'];
};

export type QueryRuleArgs = {
  uuid: Scalars['String'];
};

export type QuerySensorArgs = {
  uuid: Scalars['String'];
};

export type QuerySensorPagesArgs = {
  filter?: InputMaybe<SensorsFilterArgs>;
  order?: InputMaybe<SensorsOrderArgs>;
  page?: InputMaybe<PaginationArgs>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type QuerySensorUnassignedPagesArgs = {
  filter?: InputMaybe<SensorsFilterArgs>;
  order?: InputMaybe<SensorsOrderArgs>;
  page?: InputMaybe<PaginationArgs>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type QuerySensorsArgs = {
  filter?: InputMaybe<SensorsFilterArgs>;
  order?: InputMaybe<SensorsOrderArgs>;
  page?: InputMaybe<PaginationArgs>;
  projectId?: InputMaybe<Scalars['String']>;
};

export type QueryUserRoleArgs = {
  orgId: Scalars['String'];
  uuid: Scalars['String'];
};

export type QueryVendorArgs = {
  uuid: Scalars['String'];
};

export type QueryViewArgs = {
  uuid: Scalars['String'];
};

export type QuerySensorDataInput = {
  end?: InputMaybe<Scalars['DateTimeISO']>;
  sensorId: Scalars['String'];
  start: Scalars['DateTimeISO'];
  transformations: Array<TransformationInput>;
};

export type Room = {
  __typename?: 'Room';
  createdAt: Scalars['DateTimeISO'];
  description?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  level?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organization: Organization;
  services?: Maybe<Scalars['String']>;
  site?: Maybe<Scalars['String']>;
  surfaceArea?: Maybe<Scalars['String']>;
  type?: Maybe<RoomType>;
  updatedAt: Scalars['DateTimeISO'];
};

export type RoomFilterArgs = {
  description?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Scalars['String']>;
  site?: InputMaybe<Scalars['String']>;
  surfaceArea?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type RoomOrderArgs = {
  description?: InputMaybe<Order>;
  height?: InputMaybe<Order>;
  level?: InputMaybe<Order>;
  name?: InputMaybe<Order>;
  services?: InputMaybe<Order>;
  site?: InputMaybe<Order>;
  surfaceArea?: InputMaybe<Order>;
  type?: InputMaybe<Order>;
};

export type RoomType = {
  __typename?: 'RoomType';
  color?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organization: Organization;
  updatedAt: Scalars['DateTimeISO'];
};

export type RoomTypeInput = {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Rooms = {
  __typename?: 'Rooms';
  count: Scalars['Int'];
  items: Array<Room>;
};

export type Rule = {
  __typename?: 'Rule';
  comments?: Maybe<Scalars['String']>;
  downsample?: Maybe<Scalars['String']>;
  every: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  operator: Scalars['String'];
  optStatistic?: Maybe<Scalars['String']>;
  optThreshold?: Maybe<Scalars['Float']>;
  sensor?: Maybe<ProjectSensor>;
  statistic: Scalars['String'];
  threshold?: Maybe<Scalars['Float']>;
  timeWindow: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

/** The ISO blue wool standard. */
export enum Sensitivity {
  Iso1 = 'ISO1',
  Iso2 = 'ISO2',
  Iso3 = 'ISO3',
  Iso4 = 'ISO4',
  Iso5 = 'ISO5',
  Iso6 = 'ISO6',
  Iso7 = 'ISO7',
  Iso8 = 'ISO8',
}

export type Sensor = {
  __typename?: 'Sensor';
  battery?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTimeISO'];
  device?: Maybe<Device>;
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  measurement: Scalars['String'];
  name: Scalars['String'];
  unit: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  value?: Maybe<Data>;
};

export type SensorData = {
  __typename?: 'SensorData';
  data: Array<Data>;
  sensorMetric: Scalars['String'];
};

export type Sensors = {
  __typename?: 'Sensors';
  count: Scalars['Int'];
  items: Array<Sensor>;
};

export type SensorsFilterArgs = {
  measurement?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type SensorsOrderArgs = {
  measurement?: InputMaybe<Order>;
  name?: InputMaybe<Order>;
  updatedAt?: InputMaybe<Order>;
};

export type SimplePeriod = {
  __typename?: 'SimplePeriod';
  end?: Maybe<Scalars['ISOdate']>;
  start: Scalars['ISOdate'];
};

export type StringInput = {
  item: Scalars['String'];
};

export type TemperatureHumidityView = {
  __typename?: 'TemperatureHumidityView';
  autoAddNewDevices?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Device>>;
  endTime?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  rules?: Maybe<Array<Rule>>;
  sampleTime?: Maybe<Scalars['String']>;
  sensors?: Maybe<Array<ProjectSensor>>;
  startTime?: Maybe<Scalars['DateTimeISO']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type TextInput = {
  item: Scalars['String'];
};

export type TokenResponse = {
  __typename?: 'TokenResponse';
  accessToken?: Maybe<Scalars['String']>;
  expires?: Maybe<Scalars['Int']>;
  mfaRequired: Scalars['Boolean'];
  mfaToken?: Maybe<Scalars['String']>;
};

export type TransformationInput = {
  aggregate?: InputMaybe<Aggregate>;
  type: TransformationType;
  window?: InputMaybe<Scalars['String']>;
};

/** The type of transformation for an influx query */
export enum TransformationType {
  AggregateWindow = 'AGGREGATE_WINDOW',
  CumulativeSum = 'CUMULATIVE_SUM',
}

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateUserResponse = {
  __typename?: 'UpdateUserResponse';
  userId: Scalars['String'];
  userMetadata?: Maybe<UserMetadata>;
};

export type User = {
  __typename?: 'User';
  auth0Id?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeISO'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  language?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  email: Scalars['String'];
  expiration: Scalars['DateTimeISO'];
  id: Scalars['ID'];
  organization: Organization;
  role: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
  user: User;
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  useMfa?: Maybe<Scalars['Boolean']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  data: VendorData;
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type VendorData = {
  __typename?: 'VendorData';
  apiToken?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Scalars['String']>>;
  macAddresses?: Maybe<Array<Scalars['String']>>;
  password?: Maybe<Scalars['String']>;
  sensorTokens?: Maybe<Array<Scalars['String']>>;
  username?: Maybe<Scalars['String']>;
};

export type VersaSenseDataInput = {
  password: Scalars['String'];
  topic: Scalars['String'];
  username: Scalars['String'];
};

export type View = {
  __typename?: 'View';
  autoAddNewDevices?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  devices?: Maybe<Array<Device>>;
  endTime?: Maybe<Scalars['DateTimeISO']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId: Scalars['String'];
  rules?: Maybe<Array<Rule>>;
  sensors?: Maybe<Array<ProjectSensor>>;
  startTime?: Maybe<Scalars['DateTimeISO']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTimeISO'];
};

export type YDomain = {
  __typename?: 'YDomain';
  max?: Maybe<Scalars['Float']>;
  measurement?: Maybe<Scalars['String']>;
  min?: Maybe<Scalars['Float']>;
};

export type YDomainInput = {
  max?: InputMaybe<Scalars['Float']>;
  measurement?: InputMaybe<Scalars['String']>;
  min?: InputMaybe<Scalars['Float']>;
};

export type AddNoteInput = {
  description?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<PeriodInput>;
  references?: InputMaybe<NoteReferencesInput>;
  title: Scalars['String'];
};

export type UpdateNoteInput = {
  description?: InputMaybe<Scalars['String']>;
  period?: InputMaybe<PeriodInput>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateProjectNotificationConfigurationInput = {
  value: ProjectNotificationTime;
};

const queryAddonArgs = {
  uuid: 'String!',
};

export function useQryAddon<T extends GenerateQueryFields<Addon>>(
  fields: QueryFields<Addon, T>,
  args: QueryAddonArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Addon, T>, QueryAddonArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'addon', QueryResponse<Addon, T>>, QueryAddonArgs>({
    variables: args,
    query: generateQuery('addon', fields, { values: args, types: queryAddonArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['addon'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryAddons<T extends GenerateQueryFields<Array<Addon>>>(
  fields: QueryFields<Array<Addon>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Addon>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'addons', QueryResponse<Array<Addon>, T>>>({
    query: generateQuery('addons', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['addons'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryAddonsOfTypeArgs = {
  type: 'String!',
};

export function useQryAddonsOfType<T extends GenerateQueryFields<Array<Addon>>>(
  fields: QueryFields<Array<Addon>, T>,
  args: QueryAddonsOfTypeArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Addon>, T>, QueryAddonsOfTypeArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'addonsOfType', QueryResponse<Array<Addon>, T>>,
    QueryAddonsOfTypeArgs
  >({
    variables: args,
    query: generateQuery('addonsOfType', fields, { values: args, types: queryAddonsOfTypeArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['addonsOfType'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryAshrae2019Args = {
  uuid: 'String!',
};

export function useQryAshrae2019<T extends GenerateQueryFields<Ashrae2019View>>(
  fields: QueryFields<Ashrae2019View, T>,
  args: QueryAshrae2019Args,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Ashrae2019View, T>, QueryAshrae2019Args> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'ashrae2019', QueryResponse<Ashrae2019View, T>>,
    QueryAshrae2019Args
  >({
    variables: args,
    query: generateQuery('ashrae2019', fields, { values: args, types: queryAshrae2019Args }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['ashrae2019'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryAuth0UsersByOrganization<T extends GenerateQueryFields<Array<Auth0User>>>(
  fields: QueryFields<Array<Auth0User>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Auth0User>, T>> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'auth0UsersByOrganization', QueryResponse<Array<Auth0User>, T>>
  >({
    query: generateQuery('auth0UsersByOrganization', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['auth0UsersByOrganization'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryCalibrationArgs = {
  uuid: 'String!',
};

export function useQryCalibration<T extends GenerateQueryFields<Calibration>>(
  fields: QueryFields<Calibration, T>,
  args: QueryCalibrationArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Calibration, T>, QueryCalibrationArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'calibration', QueryResponse<Calibration, T>>,
    QueryCalibrationArgs
  >({
    variables: args,
    query: generateQuery('calibration', fields, { values: args, types: queryCalibrationArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['calibration'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryCalibrationPagesArgs = {
  filter: 'CalibrationFilterProps',
  page: 'PaginationArgs',
};

export function useQryCalibrationPages<T extends GenerateQueryFields<Calibrations>>(
  fields: QueryFields<Calibrations, T>,
  args: QueryCalibrationPagesArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Calibrations, T>, QueryCalibrationPagesArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'calibrationPages', QueryResponse<Calibrations, T>>,
    QueryCalibrationPagesArgs
  >({
    variables: args,
    query: generateQuery('calibrationPages', fields, { values: args, types: queryCalibrationPagesArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['calibrationPages'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryCalibrationSensorsArgs = {
  calibrationId: 'String!',
};

export function useQryCalibrationSensors<T extends GenerateQueryFields<Array<CalibrationSensor>>>(
  fields: QueryFields<Array<CalibrationSensor>, T>,
  args: QueryCalibrationSensorsArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<CalibrationSensor>, T>, QueryCalibrationSensorsArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'calibrationSensors', QueryResponse<Array<CalibrationSensor>, T>>,
    QueryCalibrationSensorsArgs
  >({
    variables: args,
    query: generateQuery('calibrationSensors', fields, { values: args, types: queryCalibrationSensorsArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['calibrationSensors'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryCalibrations<T extends GenerateQueryFields<Array<Calibration>>>(
  fields: QueryFields<Array<Calibration>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Calibration>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'calibrations', QueryResponse<Array<Calibration>, T>>>({
    query: generateQuery('calibrations', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['calibrations'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryDeviceArgs = {
  uuid: 'String!',
};

export function useQryDevice<T extends GenerateQueryFields<Device>>(
  fields: QueryFields<Device, T>,
  args: QueryDeviceArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Device, T>, QueryDeviceArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'device', QueryResponse<Device, T>>, QueryDeviceArgs>({
    variables: args,
    query: generateQuery('device', fields, { values: args, types: queryDeviceArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['device'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryDevicePagesArgs = {
  filter: 'DeviceFilterArgs',
  isGateway: 'Boolean',
  locationId: 'String',
  order: 'DeviceOrderArgs',
  page: 'PaginationArgs',
};

export function useQryDevicePages<T extends GenerateQueryFields<Devices>>(
  fields: QueryFields<Devices, T>,
  args: QueryDevicePagesArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Devices, T>, QueryDevicePagesArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'devicePages', QueryResponse<Devices, T>>,
    QueryDevicePagesArgs
  >({
    variables: args,
    query: generateQuery('devicePages', fields, { values: args, types: queryDevicePagesArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['devicePages'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryDevices<T extends GenerateQueryFields<Array<Device>>>(
  fields: QueryFields<Array<Device>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Device>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'devices', QueryResponse<Array<Device>, T>>>({
    query: generateQuery('devices', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['devices'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryDevicesWithoutLocation<T extends GenerateQueryFields<Array<Device>>>(
  fields: QueryFields<Array<Device>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Device>, T>> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'devicesWithoutLocation', QueryResponse<Array<Device>, T>>
  >({
    query: generateQuery('devicesWithoutLocation', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['devicesWithoutLocation'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryExternalTagByDevEuiArgs = {
  devEUI: 'String!',
};

export function useQryExternalTagByDevEui<T extends GenerateQueryFields<ExternalTag>>(
  fields: QueryFields<ExternalTag, T>,
  args: QueryExternalTagByDevEuiArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<ExternalTag, T>, QueryExternalTagByDevEuiArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'externalTagByDevEui', QueryResponse<ExternalTag, T>>,
    QueryExternalTagByDevEuiArgs
  >({
    variables: args,
    query: generateQuery('externalTagByDevEui', fields, { values: args, types: queryExternalTagByDevEuiArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['externalTagByDevEui'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryFileArgs = {
  uuid: 'String!',
};

export function useQryFile<T extends GenerateQueryFields<File>>(
  fields: QueryFields<File, T>,
  args: QueryFileArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<File, T>, QueryFileArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'file', QueryResponse<File, T>>, QueryFileArgs>({
    variables: args,
    query: generateQuery('file', fields, { values: args, types: queryFileArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['file'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryFileDownloadLinkArgs = {
  uuid: 'String!',
};

export function useQryFileDownloadLink<T extends GenerateQueryFields<Scalars['String']>>(
  fields: QueryFields<Scalars['String'], T>,
  args: QueryFileDownloadLinkArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Scalars['String'], T>, QueryFileDownloadLinkArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'fileDownloadLink', QueryResponse<Scalars['String'], T>>,
    QueryFileDownloadLinkArgs
  >({
    variables: args,
    query: generateQuery('fileDownloadLink', fields, { values: args, types: queryFileDownloadLinkArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['fileDownloadLink'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryFileUploadLinkArgs = {
  uuid: 'String!',
};

export function useQryFileUploadLink<T extends GenerateQueryFields<File>>(
  fields: QueryFields<File, T>,
  args: QueryFileUploadLinkArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<File, T>, QueryFileUploadLinkArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'fileUploadLink', QueryResponse<File, T>>,
    QueryFileUploadLinkArgs
  >({
    variables: args,
    query: generateQuery('fileUploadLink', fields, { values: args, types: queryFileUploadLinkArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['fileUploadLink'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryFilesArgs = {
  noteId: 'String',
  organizationId: 'String!',
};

export function useQryFiles<T extends GenerateQueryFields<Array<File>>>(
  fields: QueryFields<Array<File>, T>,
  args: QueryFilesArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<File>, T>, QueryFilesArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'files', QueryResponse<Array<File>, T>>, QueryFilesArgs>(
    {
      variables: args,
      query: generateQuery('files', fields, { values: args, types: queryFilesArgs }),
      ...options,
    },
  );
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['files'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetAlertNotificationsArgs = {
  alertId: 'String!',
  uuid: 'String!',
};

export function useQryGetAlertNotifications<T extends GenerateQueryFields<Array<Notification>>>(
  fields: QueryFields<Array<Notification>, T>,
  args: QueryGetAlertNotificationsArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Notification>, T>, QueryGetAlertNotificationsArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getAlertNotifications', QueryResponse<Array<Notification>, T>>,
    QueryGetAlertNotificationsArgs
  >({
    variables: args,
    query: generateQuery('getAlertNotifications', fields, { values: args, types: queryGetAlertNotificationsArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getAlertNotifications'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetAvailableLocationsArgs = {
  organizationId: 'String!',
};

export function useQryGetAvailableLocations<T extends GenerateQueryFields<Array<Location>>>(
  fields: QueryFields<Array<Location>, T>,
  args: QueryGetAvailableLocationsArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Location>, T>, QueryGetAvailableLocationsArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getAvailableLocations', QueryResponse<Array<Location>, T>>,
    QueryGetAvailableLocationsArgs
  >({
    variables: args,
    query: generateQuery('getAvailableLocations', fields, { values: args, types: queryGetAvailableLocationsArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getAvailableLocations'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetDataArgs = {
  sensorId: 'String!',
};

export function useQryGetData<T extends GenerateQueryFields<Array<Data>>>(
  fields: QueryFields<Array<Data>, T>,
  args: QueryGetDataArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Data>, T>, QueryGetDataArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getData', QueryResponse<Array<Data>, T>>,
    QueryGetDataArgs
  >({
    variables: args,
    query: generateQuery('getData', fields, { values: args, types: queryGetDataArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getData'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetLightBudgetControlsByArgs = {
  projectSensorId: 'String',
  viewId: 'String',
};

export function useQryGetLightBudgetControlsBy<T extends GenerateQueryFields<Array<LightBudgetControl>>>(
  fields: QueryFields<Array<LightBudgetControl>, T>,
  args: QueryGetLightBudgetControlsByArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<LightBudgetControl>, T>, QueryGetLightBudgetControlsByArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getLightBudgetControlsBy', QueryResponse<Array<LightBudgetControl>, T>>,
    QueryGetLightBudgetControlsByArgs
  >({
    variables: args,
    query: generateQuery('getLightBudgetControlsBy', fields, {
      values: args,
      types: queryGetLightBudgetControlsByArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getLightBudgetControlsBy'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetLocationReferencesByLocationIdArgs = {
  locationId: 'String!',
};

export function useQryGetLocationReferencesByLocationId<T extends GenerateQueryFields<Array<LocationReference>>>(
  fields: QueryFields<Array<LocationReference>, T>,
  args: QueryGetLocationReferencesByLocationIdArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<LocationReference>, T>, QueryGetLocationReferencesByLocationIdArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getLocationReferencesByLocationId', QueryResponse<Array<LocationReference>, T>>,
    QueryGetLocationReferencesByLocationIdArgs
  >({
    variables: args,
    query: generateQuery('getLocationReferencesByLocationId', fields, {
      values: args,
      types: queryGetLocationReferencesByLocationIdArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getLocationReferencesByLocationId'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetLocationsByProjectSensorsArgs = {
  filter: 'String',
  projectId: 'String!',
  projectSensorIds: '[String!]!',
  viewType: 'String!',
};

export function useQryGetLocationsByProjectSensors<T extends GenerateQueryFields<Array<Location>>>(
  fields: QueryFields<Array<Location>, T>,
  args: QueryGetLocationsByProjectSensorsArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Location>, T>, QueryGetLocationsByProjectSensorsArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getLocationsByProjectSensors', QueryResponse<Array<Location>, T>>,
    QueryGetLocationsByProjectSensorsArgs
  >({
    variables: args,
    query: generateQuery('getLocationsByProjectSensors', fields, {
      values: args,
      types: queryGetLocationsByProjectSensorsArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getLocationsByProjectSensors'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetManualUploadsByDeviceArgs = {
  deviceId: 'String!',
};

export function useQryGetManualUploadsByDevice<T extends GenerateQueryFields<Array<ManualUpload>>>(
  fields: QueryFields<Array<ManualUpload>, T>,
  args: QueryGetManualUploadsByDeviceArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<ManualUpload>, T>, QueryGetManualUploadsByDeviceArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getManualUploadsByDevice', QueryResponse<Array<ManualUpload>, T>>,
    QueryGetManualUploadsByDeviceArgs
  >({
    variables: args,
    query: generateQuery('getManualUploadsByDevice', fields, {
      values: args,
      types: queryGetManualUploadsByDeviceArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getManualUploadsByDevice'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetManualUploadsByOrganizationArgs = {
  organizationId: 'String!',
};

export function useQryGetManualUploadsByOrganization<T extends GenerateQueryFields<Array<ManualUpload>>>(
  fields: QueryFields<Array<ManualUpload>, T>,
  args: QueryGetManualUploadsByOrganizationArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<ManualUpload>, T>, QueryGetManualUploadsByOrganizationArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getManualUploadsByOrganization', QueryResponse<Array<ManualUpload>, T>>,
    QueryGetManualUploadsByOrganizationArgs
  >({
    variables: args,
    query: generateQuery('getManualUploadsByOrganization', fields, {
      values: args,
      types: queryGetManualUploadsByOrganizationArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getManualUploadsByOrganization'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetNotesByArgs = {
  deviceId: 'String',
  organizationId: 'String',
  projectId: 'String',
  sensorId: 'String',
  userId: 'String',
  viewId: 'String',
};

export function useQryGetNotesBy<T extends GenerateQueryFields<Array<Note>>>(
  fields: QueryFields<Array<Note>, T>,
  args: QueryGetNotesByArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Note>, T>, QueryGetNotesByArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getNotesBy', QueryResponse<Array<Note>, T>>,
    QueryGetNotesByArgs
  >({
    variables: args,
    query: generateQuery('getNotesBy', fields, { values: args, types: queryGetNotesByArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getNotesBy'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetNotesByOrganizationArgs = {
  organizationId: 'String!',
};

export function useQryGetNotesByOrganization<T extends GenerateQueryFields<Array<Note>>>(
  fields: QueryFields<Array<Note>, T>,
  args: QueryGetNotesByOrganizationArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Note>, T>, QueryGetNotesByOrganizationArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getNotesByOrganization', QueryResponse<Array<Note>, T>>,
    QueryGetNotesByOrganizationArgs
  >({
    variables: args,
    query: generateQuery('getNotesByOrganization', fields, { values: args, types: queryGetNotesByOrganizationArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getNotesByOrganization'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetNotesByRefIdsArgs = {
  refIds: '[String!]!',
};

export function useQryGetNotesByRefIds<T extends GenerateQueryFields<Array<Note>>>(
  fields: QueryFields<Array<Note>, T>,
  args: QueryGetNotesByRefIdsArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Note>, T>, QueryGetNotesByRefIdsArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getNotesByRefIds', QueryResponse<Array<Note>, T>>,
    QueryGetNotesByRefIdsArgs
  >({
    variables: args,
    query: generateQuery('getNotesByRefIds', fields, { values: args, types: queryGetNotesByRefIdsArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getNotesByRefIds'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetPeriodsByArgs = {
  organizationId: 'String',
  viewId: 'String',
};

export function useQryGetPeriodsBy<T extends GenerateQueryFields<Array<Period>>>(
  fields: QueryFields<Array<Period>, T>,
  args: QueryGetPeriodsByArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Period>, T>, QueryGetPeriodsByArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getPeriodsBy', QueryResponse<Array<Period>, T>>,
    QueryGetPeriodsByArgs
  >({
    variables: args,
    query: generateQuery('getPeriodsBy', fields, { values: args, types: queryGetPeriodsByArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getPeriodsBy'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetPlanArgs = {
  planId: 'String!',
};

export function useQryGetPlan<T extends GenerateQueryFields<Plan>>(
  fields: QueryFields<Plan, T>,
  args: QueryGetPlanArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Plan, T>, QueryGetPlanArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'getPlan', QueryResponse<Plan, T>>, QueryGetPlanArgs>({
    variables: args,
    query: generateQuery('getPlan', fields, { values: args, types: queryGetPlanArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getPlan'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetPlansByOrganizationArgs = {
  organizationId: 'String!',
};

export function useQryGetPlansByOrganization<T extends GenerateQueryFields<Array<Plan>>>(
  fields: QueryFields<Array<Plan>, T>,
  args: QueryGetPlansByOrganizationArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Plan>, T>, QueryGetPlansByOrganizationArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getPlansByOrganization', QueryResponse<Array<Plan>, T>>,
    QueryGetPlansByOrganizationArgs
  >({
    variables: args,
    query: generateQuery('getPlansByOrganization', fields, { values: args, types: queryGetPlansByOrganizationArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getPlansByOrganization'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetPointsArgs = {
  filter: 'PointsFilterArgs',
  planId: 'String!',
};

export function useQryGetPoints<T extends GenerateQueryFields<Array<Point>>>(
  fields: QueryFields<Array<Point>, T>,
  args: QueryGetPointsArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Point>, T>, QueryGetPointsArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getPoints', QueryResponse<Array<Point>, T>>,
    QueryGetPointsArgs
  >({
    variables: args,
    query: generateQuery('getPoints', fields, { values: args, types: queryGetPointsArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getPoints'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetProjectSensorsByLocationsArgs = {
  locationIds: '[String!]!',
  projectId: 'String!',
};

export function useQryGetProjectSensorsByLocations<T extends GenerateQueryFields<Array<ProjectSensor>>>(
  fields: QueryFields<Array<ProjectSensor>, T>,
  args: QueryGetProjectSensorsByLocationsArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<ProjectSensor>, T>, QueryGetProjectSensorsByLocationsArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getProjectSensorsByLocations', QueryResponse<Array<ProjectSensor>, T>>,
    QueryGetProjectSensorsByLocationsArgs
  >({
    variables: args,
    query: generateQuery('getProjectSensorsByLocations', fields, {
      values: args,
      types: queryGetProjectSensorsByLocationsArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getProjectSensorsByLocations'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetProjectSensorsByProjectIdArgs = {
  projectId: 'String!',
};

export function useQryGetProjectSensorsByProjectId<T extends GenerateQueryFields<Array<ProjectSensor>>>(
  fields: QueryFields<Array<ProjectSensor>, T>,
  args: QueryGetProjectSensorsByProjectIdArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<ProjectSensor>, T>, QueryGetProjectSensorsByProjectIdArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getProjectSensorsByProjectId', QueryResponse<Array<ProjectSensor>, T>>,
    QueryGetProjectSensorsByProjectIdArgs
  >({
    variables: args,
    query: generateQuery('getProjectSensorsByProjectId', fields, {
      values: args,
      types: queryGetProjectSensorsByProjectIdArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getProjectSensorsByProjectId'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetProjectUserByProjectAndUserArgs = {
  userId: 'String!',
  uuid: 'String!',
};

export function useQryGetProjectUserByProjectAndUser<T extends GenerateQueryFields<ProjectUser>>(
  fields: QueryFields<ProjectUser, T>,
  args: QueryGetProjectUserByProjectAndUserArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<ProjectUser, T>, QueryGetProjectUserByProjectAndUserArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getProjectUserByProjectAndUser', QueryResponse<ProjectUser, T>>,
    QueryGetProjectUserByProjectAndUserArgs
  >({
    variables: args,
    query: generateQuery('getProjectUserByProjectAndUser', fields, {
      values: args,
      types: queryGetProjectUserByProjectAndUserArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getProjectUserByProjectAndUser'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetSensorDataArgs = {
  uuid: 'String!',
};

export function useQryGetSensorData<T extends GenerateQueryFields<Array<Data>>>(
  fields: QueryFields<Array<Data>, T>,
  args: QueryGetSensorDataArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Data>, T>, QueryGetSensorDataArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getSensorData', QueryResponse<Array<Data>, T>>,
    QueryGetSensorDataArgs
  >({
    variables: args,
    query: generateQuery('getSensorData', fields, { values: args, types: queryGetSensorDataArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getSensorData'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetTemperatureHumidityViewArgs = {
  uuid: 'String!',
};

export function useQryGetTemperatureHumidityView<T extends GenerateQueryFields<TemperatureHumidityView>>(
  fields: QueryFields<TemperatureHumidityView, T>,
  args: QueryGetTemperatureHumidityViewArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<TemperatureHumidityView, T>, QueryGetTemperatureHumidityViewArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getTemperatureHumidityView', QueryResponse<TemperatureHumidityView, T>>,
    QueryGetTemperatureHumidityViewArgs
  >({
    variables: args,
    query: generateQuery('getTemperatureHumidityView', fields, {
      values: args,
      types: queryGetTemperatureHumidityViewArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getTemperatureHumidityView'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryGetUserMfaArgs = {
  accessToken: 'String!',
  userId: 'String!',
};

export function useQryGetUserMfa<T extends GenerateQueryFields<Scalars['Boolean']>>(
  fields: QueryFields<Scalars['Boolean'], T>,
  args: QueryGetUserMfaArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Scalars['Boolean'], T>, QueryGetUserMfaArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'getUserMfa', QueryResponse<Scalars['Boolean'], T>>,
    QueryGetUserMfaArgs
  >({
    variables: args,
    query: generateQuery('getUserMfa', fields, { values: args, types: queryGetUserMfaArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['getUserMfa'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryInvitationArgs = {
  token: 'String!',
};

export function useQryInvitation<T extends GenerateQueryFields<UserInvitation>>(
  fields: QueryFields<UserInvitation, T>,
  args: QueryInvitationArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<UserInvitation, T>, QueryInvitationArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'invitation', QueryResponse<UserInvitation, T>>,
    QueryInvitationArgs
  >({
    variables: args,
    query: generateQuery('invitation', fields, { values: args, types: queryInvitationArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['invitation'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryInvitations<T extends GenerateQueryFields<Array<UserInvitation>>>(
  fields: QueryFields<Array<UserInvitation>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<UserInvitation>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'invitations', QueryResponse<Array<UserInvitation>, T>>>(
    {
      query: generateQuery('invitations', fields),
      ...options,
    },
  );
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['invitations'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryLightBudgetControlArgs = {
  controlId: 'String!',
};

export function useQryLightBudgetControl<T extends GenerateQueryFields<LightBudgetControl>>(
  fields: QueryFields<LightBudgetControl, T>,
  args: QueryLightBudgetControlArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<LightBudgetControl, T>, QueryLightBudgetControlArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'lightBudgetControl', QueryResponse<LightBudgetControl, T>>,
    QueryLightBudgetControlArgs
  >({
    variables: args,
    query: generateQuery('lightBudgetControl', fields, { values: args, types: queryLightBudgetControlArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['lightBudgetControl'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryLightBudgetViewArgs = {
  uuid: 'String!',
};

export function useQryLightBudgetView<T extends GenerateQueryFields<LightBudgetView>>(
  fields: QueryFields<LightBudgetView, T>,
  args: QueryLightBudgetViewArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<LightBudgetView, T>, QueryLightBudgetViewArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'lightBudgetView', QueryResponse<LightBudgetView, T>>,
    QueryLightBudgetViewArgs
  >({
    variables: args,
    query: generateQuery('lightBudgetView', fields, { values: args, types: queryLightBudgetViewArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['lightBudgetView'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryLocationArgs = {
  locationId: 'String!',
};

export function useQryLocation<T extends GenerateQueryFields<Location>>(
  fields: QueryFields<Location, T>,
  args: QueryLocationArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Location, T>, QueryLocationArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'location', QueryResponse<Location, T>>,
    QueryLocationArgs
  >({
    variables: args,
    query: generateQuery('location', fields, { values: args, types: queryLocationArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['location'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryLocationPageArgs = {
  filter: 'LocationFilterArgs',
  order: 'LocationOrderArgs',
  organizationId: 'String!',
  page: 'PaginationArgs',
};

export function useQryLocationPage<T extends GenerateQueryFields<Locations>>(
  fields: QueryFields<Locations, T>,
  args: QueryLocationPageArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Locations, T>, QueryLocationPageArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'locationPage', QueryResponse<Locations, T>>,
    QueryLocationPageArgs
  >({
    variables: args,
    query: generateQuery('locationPage', fields, { values: args, types: queryLocationPageArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['locationPage'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryLocationTypeArgs = {
  locationId: 'String!',
  organizationId: 'String!',
};

export function useQryLocationType<T extends GenerateQueryFields<LocationType>>(
  fields: QueryFields<LocationType, T>,
  args: QueryLocationTypeArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<LocationType, T>, QueryLocationTypeArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'locationType', QueryResponse<LocationType, T>>,
    QueryLocationTypeArgs
  >({
    variables: args,
    query: generateQuery('locationType', fields, { values: args, types: queryLocationTypeArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['locationType'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryLocationTypes<T extends GenerateQueryFields<Array<LocationType>>>(
  fields: QueryFields<Array<LocationType>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<LocationType>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'locationTypes', QueryResponse<Array<LocationType>, T>>>(
    {
      query: generateQuery('locationTypes', fields),
      ...options,
    },
  );
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['locationTypes'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryLocations<T extends GenerateQueryFields<Array<Location>>>(
  fields: QueryFields<Array<Location>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Location>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'locations', QueryResponse<Array<Location>, T>>>({
    query: generateQuery('locations', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['locations'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryManualUploadArgs = {
  organizationId: 'String!',
  uploadId: 'String!',
};

export function useQryManualUpload<T extends GenerateQueryFields<ManualUpload>>(
  fields: QueryFields<ManualUpload, T>,
  args: QueryManualUploadArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<ManualUpload, T>, QueryManualUploadArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'manualUpload', QueryResponse<ManualUpload, T>>,
    QueryManualUploadArgs
  >({
    variables: args,
    query: generateQuery('manualUpload', fields, { values: args, types: queryManualUploadArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['manualUpload'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryMe<T extends GenerateQueryFields<User>>(
  fields: QueryFields<User, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<User, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'me', QueryResponse<User, T>>>({
    query: generateQuery('me', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['me'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryMonitorViewArgs = {
  uuid: 'String!',
};

export function useQryMonitorView<T extends GenerateQueryFields<MonitorView>>(
  fields: QueryFields<MonitorView, T>,
  args: QueryMonitorViewArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<MonitorView, T>, QueryMonitorViewArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'monitorView', QueryResponse<MonitorView, T>>,
    QueryMonitorViewArgs
  >({
    variables: args,
    query: generateQuery('monitorView', fields, { values: args, types: queryMonitorViewArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['monitorView'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryNoteArgs = {
  noteId: 'String!',
};

export function useQryNote<T extends GenerateQueryFields<Note>>(
  fields: QueryFields<Note, T>,
  args: QueryNoteArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Note, T>, QueryNoteArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'note', QueryResponse<Note, T>>, QueryNoteArgs>({
    variables: args,
    query: generateQuery('note', fields, { values: args, types: queryNoteArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['note'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryNotificationsArgs = {
  cursor: 'Int',
  first: 'Int',
};

export function useQryNotifications<T extends GenerateQueryFields<Array<Notification>>>(
  fields: QueryFields<Array<Notification>, T>,
  args: QueryNotificationsArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Notification>, T>, QueryNotificationsArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'notifications', QueryResponse<Array<Notification>, T>>,
    QueryNotificationsArgs
  >({
    variables: args,
    query: generateQuery('notifications', fields, { values: args, types: queryNotificationsArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['notifications'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryOrganization<T extends GenerateQueryFields<Organization>>(
  fields: QueryFields<Organization, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Organization, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'organization', QueryResponse<Organization, T>>>({
    query: generateQuery('organization', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['organization'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryOrganizations<T extends GenerateQueryFields<Array<Organization>>>(
  fields: QueryFields<Array<Organization>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Organization>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'organizations', QueryResponse<Array<Organization>, T>>>(
    {
      query: generateQuery('organizations', fields),
      ...options,
    },
  );
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['organizations'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryPeriodArgs = {
  organizationId: 'String!',
  periodId: 'String!',
};

export function useQryPeriod<T extends GenerateQueryFields<Period>>(
  fields: QueryFields<Period, T>,
  args: QueryPeriodArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Period, T>, QueryPeriodArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'period', QueryResponse<Period, T>>, QueryPeriodArgs>({
    variables: args,
    query: generateQuery('period', fields, { values: args, types: queryPeriodArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['period'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryProjectArgs = {
  uuid: 'String!',
};

export function useQryProject<T extends GenerateQueryFields<Project>>(
  fields: QueryFields<Project, T>,
  args: QueryProjectArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Project, T>, QueryProjectArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'project', QueryResponse<Project, T>>, QueryProjectArgs>(
    {
      variables: args,
      query: generateQuery('project', fields, { values: args, types: queryProjectArgs }),
      ...options,
    },
  );
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['project'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryProjectAddonsByProjectAndTypeArgs = {
  type: 'String!',
  uuid: 'String!',
};

export function useQryProjectAddonsByProjectAndType<T extends GenerateQueryFields<Array<ProjectAddon>>>(
  fields: QueryFields<Array<ProjectAddon>, T>,
  args: QueryProjectAddonsByProjectAndTypeArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<ProjectAddon>, T>, QueryProjectAddonsByProjectAndTypeArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'projectAddonsByProjectAndType', QueryResponse<Array<ProjectAddon>, T>>,
    QueryProjectAddonsByProjectAndTypeArgs
  >({
    variables: args,
    query: generateQuery('projectAddonsByProjectAndType', fields, {
      values: args,
      types: queryProjectAddonsByProjectAndTypeArgs,
    }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['projectAddonsByProjectAndType'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryProjectSensorsByRuleArgs = {
  data: 'GetProjectSensorByRuleInput!',
};

export function useQryProjectSensorsByRule<T extends GenerateQueryFields<Array<ProjectSensorWithRule>>>(
  fields: QueryFields<Array<ProjectSensorWithRule>, T>,
  args: QueryProjectSensorsByRuleArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<ProjectSensorWithRule>, T>, QueryProjectSensorsByRuleArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'projectSensorsByRule', QueryResponse<Array<ProjectSensorWithRule>, T>>,
    QueryProjectSensorsByRuleArgs
  >({
    variables: args,
    query: generateQuery('projectSensorsByRule', fields, { values: args, types: queryProjectSensorsByRuleArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['projectSensorsByRule'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryProjectUserRoleArgs = {
  projectId: 'String!',
  uuid: 'String!',
};

export function useQryProjectUserRole<T extends GenerateQueryFields<Scalars['String']>>(
  fields: QueryFields<Scalars['String'], T>,
  args: QueryProjectUserRoleArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Scalars['String'], T>, QueryProjectUserRoleArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'projectUserRole', QueryResponse<Scalars['String'], T>>,
    QueryProjectUserRoleArgs
  >({
    variables: args,
    query: generateQuery('projectUserRole', fields, { values: args, types: queryProjectUserRoleArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['projectUserRole'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryProjects<T extends GenerateQueryFields<Array<Project>>>(
  fields: QueryFields<Array<Project>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Project>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'projects', QueryResponse<Array<Project>, T>>>({
    query: generateQuery('projects', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['projects'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryPublicInvitationArgs = {
  token: 'String!',
};

export function useQryPublicInvitation<T extends GenerateQueryFields<UserInvitation>>(
  fields: QueryFields<UserInvitation, T>,
  args: QueryPublicInvitationArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<UserInvitation, T>, QueryPublicInvitationArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'publicInvitation', QueryResponse<UserInvitation, T>>,
    QueryPublicInvitationArgs
  >({
    variables: args,
    query: generateQuery('publicInvitation', fields, { values: args, types: queryPublicInvitationArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['publicInvitation'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryQuerySensorDataArgs = {
  query: 'QuerySensorDataInput!',
};

export function useQryQuerySensorData<T extends GenerateQueryFields<Array<Data>>>(
  fields: QueryFields<Array<Data>, T>,
  args: QueryQuerySensorDataArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Data>, T>, QueryQuerySensorDataArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'querySensorData', QueryResponse<Array<Data>, T>>,
    QueryQuerySensorDataArgs
  >({
    variables: args,
    query: generateQuery('querySensorData', fields, { values: args, types: queryQuerySensorDataArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['querySensorData'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryRoomArgs = {
  roomId: 'String!',
};

export function useQryRoom<T extends GenerateQueryFields<Room>>(
  fields: QueryFields<Room, T>,
  args: QueryRoomArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Room, T>, QueryRoomArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'room', QueryResponse<Room, T>>, QueryRoomArgs>({
    variables: args,
    query: generateQuery('room', fields, { values: args, types: queryRoomArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['room'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryRoomPageArgs = {
  filter: 'RoomFilterArgs',
  order: 'RoomOrderArgs',
  organizationId: 'String!',
  page: 'PaginationArgs',
};

export function useQryRoomPage<T extends GenerateQueryFields<Rooms>>(
  fields: QueryFields<Rooms, T>,
  args: QueryRoomPageArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Rooms, T>, QueryRoomPageArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'roomPage', QueryResponse<Rooms, T>>, QueryRoomPageArgs>(
    {
      variables: args,
      query: generateQuery('roomPage', fields, { values: args, types: queryRoomPageArgs }),
      ...options,
    },
  );
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['roomPage'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryRoomTypeArgs = {
  locationId: 'String!',
  organizationId: 'String!',
};

export function useQryRoomType<T extends GenerateQueryFields<RoomType>>(
  fields: QueryFields<RoomType, T>,
  args: QueryRoomTypeArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<RoomType, T>, QueryRoomTypeArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'roomType', QueryResponse<RoomType, T>>,
    QueryRoomTypeArgs
  >({
    variables: args,
    query: generateQuery('roomType', fields, { values: args, types: queryRoomTypeArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['roomType'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryRoomTypesArgs = {
  organizationId: 'String!',
};

export function useQryRoomTypes<T extends GenerateQueryFields<Array<RoomType>>>(
  fields: QueryFields<Array<RoomType>, T>,
  args: QueryRoomTypesArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<RoomType>, T>, QueryRoomTypesArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'roomTypes', QueryResponse<Array<RoomType>, T>>,
    QueryRoomTypesArgs
  >({
    variables: args,
    query: generateQuery('roomTypes', fields, { values: args, types: queryRoomTypesArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['roomTypes'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryRooms<T extends GenerateQueryFields<Array<Room>>>(
  fields: QueryFields<Array<Room>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Room>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'rooms', QueryResponse<Array<Room>, T>>>({
    query: generateQuery('rooms', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['rooms'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryRuleArgs = {
  uuid: 'String!',
};

export function useQryRule<T extends GenerateQueryFields<Rule>>(
  fields: QueryFields<Rule, T>,
  args: QueryRuleArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Rule, T>, QueryRuleArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'rule', QueryResponse<Rule, T>>, QueryRuleArgs>({
    variables: args,
    query: generateQuery('rule', fields, { values: args, types: queryRuleArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['rule'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryRuleTemplates<T extends GenerateQueryFields<Array<Rule>>>(
  fields: QueryFields<Array<Rule>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Rule>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'ruleTemplates', QueryResponse<Array<Rule>, T>>>({
    query: generateQuery('ruleTemplates', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['ruleTemplates'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const querySensorArgs = {
  uuid: 'String!',
};

export function useQrySensor<T extends GenerateQueryFields<Sensor>>(
  fields: QueryFields<Sensor, T>,
  args: QuerySensorArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Sensor, T>, QuerySensorArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'sensor', QueryResponse<Sensor, T>>, QuerySensorArgs>({
    variables: args,
    query: generateQuery('sensor', fields, { values: args, types: querySensorArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['sensor'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const querySensorPagesArgs = {
  filter: 'SensorsFilterArgs',
  order: 'SensorsOrderArgs',
  page: 'PaginationArgs',
  projectId: 'String',
};

export function useQrySensorPages<T extends GenerateQueryFields<Sensors>>(
  fields: QueryFields<Sensors, T>,
  args: QuerySensorPagesArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Sensors, T>, QuerySensorPagesArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'sensorPages', QueryResponse<Sensors, T>>,
    QuerySensorPagesArgs
  >({
    variables: args,
    query: generateQuery('sensorPages', fields, { values: args, types: querySensorPagesArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['sensorPages'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const querySensorUnassignedPagesArgs = {
  filter: 'SensorsFilterArgs',
  order: 'SensorsOrderArgs',
  page: 'PaginationArgs',
  projectId: 'String',
};

export function useQrySensorUnassignedPages<T extends GenerateQueryFields<Sensors>>(
  fields: QueryFields<Sensors, T>,
  args: QuerySensorUnassignedPagesArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Sensors, T>, QuerySensorUnassignedPagesArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'sensorUnassignedPages', QueryResponse<Sensors, T>>,
    QuerySensorUnassignedPagesArgs
  >({
    variables: args,
    query: generateQuery('sensorUnassignedPages', fields, { values: args, types: querySensorUnassignedPagesArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['sensorUnassignedPages'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const querySensorsArgs = {
  filter: 'SensorsFilterArgs',
  order: 'SensorsOrderArgs',
  page: 'PaginationArgs',
  projectId: 'String',
};

export function useQrySensors<T extends GenerateQueryFields<Array<Sensor>>>(
  fields: QueryFields<Array<Sensor>, T>,
  args: QuerySensorsArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Sensor>, T>, QuerySensorsArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'sensors', QueryResponse<Array<Sensor>, T>>,
    QuerySensorsArgs
  >({
    variables: args,
    query: generateQuery('sensors', fields, { values: args, types: querySensorsArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['sensors'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryUserRoleArgs = {
  orgId: 'String!',
  uuid: 'String!',
};

export function useQryUserRole<T extends GenerateQueryFields<Scalars['String']>>(
  fields: QueryFields<Scalars['String'], T>,
  args: QueryUserRoleArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Scalars['String'], T>, QueryUserRoleArgs> {
  const [queryState, executeQuery] = useQuery<
    RawQueryResponse<'userRole', QueryResponse<Scalars['String'], T>>,
    QueryUserRoleArgs
  >({
    variables: args,
    query: generateQuery('userRole', fields, { values: args, types: queryUserRoleArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['userRole'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryUsers<T extends GenerateQueryFields<Array<User>>>(
  fields: QueryFields<Array<User>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<User>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'users', QueryResponse<Array<User>, T>>>({
    query: generateQuery('users', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['users'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryVendorArgs = {
  uuid: 'String!',
};

export function useQryVendor<T extends GenerateQueryFields<Vendor>>(
  fields: QueryFields<Vendor, T>,
  args: QueryVendorArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Vendor, T>, QueryVendorArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'vendor', QueryResponse<Vendor, T>>, QueryVendorArgs>({
    variables: args,
    query: generateQuery('vendor', fields, { values: args, types: queryVendorArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['vendor'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

export function useQryVendors<T extends GenerateQueryFields<Array<Vendor>>>(
  fields: QueryFields<Array<Vendor>, T>,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<Array<Vendor>, T>> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'vendors', QueryResponse<Array<Vendor>, T>>>({
    query: generateQuery('vendors', fields),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['vendors'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}

const queryViewArgs = {
  uuid: 'String!',
};

export function useQryView<T extends GenerateQueryFields<View>>(
  fields: QueryFields<View, T>,
  args: QueryViewArgs,
  options: Omit<UseQueryArgs, 'query' | 'variables'>,
): UseQueryResponse<QueryResponse<View, T>, QueryViewArgs> {
  const [queryState, executeQuery] = useQuery<RawQueryResponse<'view', QueryResponse<View, T>>, QueryViewArgs>({
    variables: args,
    query: generateQuery('view', fields, { values: args, types: queryViewArgs }),
    ...options,
  });
  return useMemo(
    () => [
      {
        ...queryState,
        data: queryState.data?.['view'],
      },
      executeQuery,
    ],
    [executeQuery, queryState],
  );
}
