import { CSSReset } from '@chakra-ui/css-reset';
import { ChakraProvider } from '@chakra-ui/provider';
import defaultChakraTheme from '@chakra-ui/theme';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Charp } from 'App';
import { SnackbarContainer } from 'components/Snackbar';
import { AuthProvider } from 'context/AuthContext';
import { LocaleProvider } from 'context/LocaleContext';
import { UserContext } from 'context/UserContext';
import 'focus-visible';
import { createBrowserHistory } from 'history';
import { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { urqlClient } from 'graphql/urql';
import { ThemeProvider } from 'styled-components';
import { theme } from 'theme';
import FontFaceDefinitions from 'theme/FontFaceDefinition';
import GlobalStyle from 'theme/GlobalStyle';
import { Provider } from 'urql';
import environment, { extractFromEnv } from 'utils/environment';
import i18n from 'i18next';
import initI18n from 'i18init';
import { useTranslation } from 'react-i18next';
import { Auth0Provider } from '@auth0/auth0-react';
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';

const history = createBrowserHistory();

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      // createBrowserHistory type covers all properties of RouterHistory, so this type assertion is fine!
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // 1 means 100% of all traces are sent to Sentry. Sentry recommends a lower value, but our userbase isn't that big yet.
  tracesSampleRate: 1,
});

const App = () => {
  const [user] = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (user !== undefined) {
      Sentry.setUser({
        id: user.id,
        email: user.email,
      });
    } else {
      Sentry.configureScope((scope) => scope.setUser(null));
    }
  }, [user]);

  return (
    <Sentry.ErrorBoundary fallback={<div>{t('An error has occurred')}</div>}>
      <ThemeProvider theme={theme}>
        <FontFaceDefinitions />
        <CSSReset />
        <GlobalStyle />
        <LocaleProvider>
          <SnackbarContainer>
            <ChakraProvider theme={defaultChakraTheme}>
              <Charp history={history} />
            </ChakraProvider>
          </SnackbarContainer>
        </LocaleProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
};

const isStaging = () => {
  return ['staging.charp.be'].includes(window.location.hostname);
};

const startApp = async () => {
  if (isStaging()) {
    await tryLoadAndStartRecorder({
      recordingToken: extractFromEnv('VITE_METICULOUS_TOKEN'),
      isProduction: false,
    });
  }

  if (!i18n.isInitialized) {
    await initI18n();
  }

  ReactDOM.render(
    <Provider value={urqlClient}>
      <Auth0Provider
        domain={extractFromEnv('VITE_AUTH0_DOMAIN')}
        clientId={extractFromEnv('VITE_AUTH0_CLIENT_ID')}
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}
      >
        <AuthProvider>
          <App />
        </AuthProvider>
      </Auth0Provider>
    </Provider>,
    document.getElementById('root'),
  );
};

startApp().catch(console.error);
