import { DefaultQueryResponse } from 'graphql/query/default';
import { LinkedPeriod } from 'types/period';
import { useMutation } from 'urql';

const EDIT_PERIOD_KEY = 'editPeriod';

const EDIT_PERIOD = `
mutation ($periodId: String!, $data: PeriodInput!) {
  ${EDIT_PERIOD_KEY}(periodId: $periodId, data: $data) {
    id
    start
    end
    updatedAt
  }
}
`;

type MutVarEditPeriod = {
  periodId: string;
  data: {
    start: string;
    end?: string | null;
  };
};

type MutResEditPeriod = DefaultQueryResponse<
  typeof EDIT_PERIOD_KEY,
  Pick<LinkedPeriod, 'id' | 'start' | 'end' | 'updatedAt'>
>;

export const useEditPeriod = () => {
  return useMutation<MutResEditPeriod, MutVarEditPeriod>(EDIT_PERIOD);
};
